import { useCallback, useEffect, useMemo, useState, type Dispatch, type SetStateAction } from 'react';
import type { DefaultValue } from './utils';
import { ClientInfoFE } from ':frontend/types/Client';
import type { Id } from ':utils/id';
import { compareStringsLocalized } from ':frontend/utils/common';
import { useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';
import { SortOrder } from ':utils/common';
import { getI18nLocale } from ':utils/i18n';

type NonDefaultOptions = {
    invoicingProfileId?: Id;
    nameOrder?: SortOrder;
};

// @ts-expect-error React api overload
export function useClients(options: { default: DefaultValue<ClientInfoFE[]> } & NonDefaultOptions): {
    clients: ClientInfoFE[];
    setClients: Dispatch<SetStateAction<ClientInfoFE[]>>;
    addClients: (clients: ClientInfoFE | ClientInfoFE[]) => void;
}

export function useClients(options?: NonDefaultOptions): {
    clients: ClientInfoFE[] | undefined;
    setClients: Dispatch<SetStateAction<ClientInfoFE[] | undefined>>;
    addClients: (clients: ClientInfoFE | ClientInfoFE[]) => void;
};

/**
 * It's discouraged to use pagination for clients since 2.1.2024 because it don't work correctly with filters.
 * However, it should be fixed in the future when there might be users with too many clients.
 * @see https://flowlancenew.slack.com/archives/C03MNGLLWQ2/p1704226232258679
 */
export function useClients(options?: { default?: DefaultValue<ClientInfoFE[]> } & NonDefaultOptions) {
    const { i18n } = useTranslation();

    const clientsQuery = trpc.$client.getClientInfos.useQuery({
        invoicingProfileId: options?.invoicingProfileId,
    });

    const [ clients, setClients ] = useState(options?.default);

    useEffect(() => {
        if (clientsQuery.data)
            setClients(clientsQuery.data.map(ClientInfoFE.fromServer));
    }, [ clientsQuery.data ]);

    const locale = getI18nLocale(i18n);
    const sortedClients = useMemo(() => {
        if (!clients)
            return clients;

        const result = clients.toSorted((a, b) => compareStringsLocalized(a.name, b.name, locale));
        // The default ordering (undefined) is ascending (a -> z).
        if (options?.nameOrder === SortOrder.Descending)
            result.reverse();

        return result;
    }, [ clients, options?.nameOrder, locale ]);

    const addClients = useCallback((newClients: ClientInfoFE | ClientInfoFE[]): void => {
        const clientsArray = Array.isArray(newClients) ? newClients : [ newClients ];
        setClients((oldClients) => [ ...(oldClients ?? []), ...clientsArray ]);
    }, []);

    return {
        clients: sortedClients,
        setClients,
        addClients,
    };
}
