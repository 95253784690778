import { useEffect, useState } from 'react';
import { api } from ':frontend/utils/api';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from ':utils/common';

type NewVersionNotificationProps = Readonly<{
    isShow: boolean;
}>;

export default function NewVersionNotification({ isShow }: NewVersionNotificationProps) {
    if (!isShow)
        return null;

    return (
        <NotificationInner />
    );
}

function NotificationInner() {
    const { t } = useTranslation('common', { keyPrefix: 'newVersionNotification' });

    function forceReload() {
        location.reload();
    }

    return (
        <div className='fl-new-version-notification'>
            <div className='wrapper'>
                <div className='content mx-auto d-flex align-items-center justify-content-between'>
                    <span className='fs-2 fw-medium pe-4'>
                        {t('message')}
                    </span>
                    <Button className='px-4' variant='primary' onClick={forceReload}>
                        {t('refresh-button')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export type VersionOutput = {
    // There is an id but in a raw uuid format. We use the `number` for comparison instead.
    number: number;
    dateOnline: string;
};

class VersionFE {
    constructor(
        readonly number: number,
        readonly dateOnline: DateTime,
    ) {}

    static fromServer(data: VersionOutput): VersionFE {
        return new VersionFE(
            data.number,
            DateTime.fromISO(data.dateOnline),
        );
    }
}

export function useNewVersionNotification(): boolean {
    const [ isShow, setIsShow ] = useState(false);

    useEffect(() => {
        const manager = new VersionManager(() => setIsShow(true));
        manager.start();

        return () => manager.stop();
    }, []);


    return isShow;
}

// This needs to be shorter than the deploy time on the backend, because we are creating versions as a part of the deploy job.
/** 5 minutes */
const DEFAULT_CHECK_PERIOD = 5 * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;

class VersionManager {
    private frontend: VersionFE | undefined;
    private currentTimeout?: ReturnType<typeof setTimeout>;
    private currentAbort?: () => void;

    constructor(
        private readonly onRefresh: () => void,
    ) {}

    public start() {
        this.scheduleCheck(0);
    }

    public stop() {
        if (this.currentTimeout)
            clearTimeout(this.currentTimeout);

        this.currentAbort?.();
    }

    private scheduleCheck(inTime: number) {
        this.currentTimeout = setTimeout(async () => {
            const nextTime = await this.check();
            this.scheduleCheck(nextTime ?? DEFAULT_CHECK_PERIOD);
        }, inTime);
    }

    /** Returns time in milliseconds until the next check. */
    private async check(): Promise<number | undefined> {
        const [ signal, abort ] = api.prepareAbort();
        this.currentAbort = abort;

        const response = await api.backend.getVersion(signal);
        if (!response.status || !response.data)
            return;

        const backend = VersionFE.fromServer(response.data);

        if (!this.frontend) {
            // We don't have a version yet so we can't determine whether we should reload (probably because we have just started). There's nothing more to do except to save it and return.
            this.frontend = backend;
            return;
        }

        if (this.frontend.number === backend.number) {
            // The version is the same, we don't have to do anything.
            return;
        }

        // The versions aren't the same - let's assume the new version is later than the current one.
        const now = DateTime.now();
        const diff = +backend.dateOnline - +now;
        if (diff <= 0) {
            // The backend version is different and it's online. We should reload the page.
            this.onRefresh();
            return;
        }

        // At this point, we know there is a newer version comming, but it's not online yet. So we schedule an update on that time.
        return diff;
    }
}
