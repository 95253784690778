import { z } from 'zod';
import { v4 } from 'uuid';

export type Id = string;
export type CurrencyId = string;
export type TaxRateId = string;

export const zId = z.string().transform(value => value as Id);

export function generateId(): Id {
    return v4();
}

export type Entity = {
    id: Id;
};

export function createExampleId(domain: string): Id {
    return `example-${domain}-${Math.random().toString(36).substring(7)}`;
}

export type OmitId<T> = Omit<T, 'id'>;

declare const secretType: unique symbol;
export type UniqueType<T, S extends string> = Omit<T, typeof secretType> & { [secretType]: S };
