import { useMemo } from 'react';
import { MoneyDisplay, Table } from '../common';
import { useTranslation } from 'react-i18next';
import { addVat, timesBy, type Money, addVatAsNumber, type CurrencyFE } from ':frontend/modules/money';
import type { OrderFormState, UseOrderDispatch, UseOrderState } from './useOrder';
import { Form } from 'react-bootstrap';
import { toNumber, transformToPrice, transformToPriceNegative } from ':frontend/utils/math';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { AddButton, DeleteButton } from '../forms/buttons';
import { VatSelect } from '../forms/VatSelect';
import { type EditableBasicItem } from ':frontend/types/orders/CustomOrderItem';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import type { OrderItemFE } from ':frontend/types/orders/OrderItem';
import { type FormPath } from ':frontend/utils/updator';

type BasicItemsTableProps = Readonly<{
    items: OrderItemFE[];
}>;

export default function BasicItemsTable({ items }: BasicItemsTableProps) {
    const { t } = useTranslation('components', { keyPrefix: 'basicItemsTable' });
    const totalPrice = useMemo(() => computeTotalPriceSum(items), [ items ]);
    const showVat = useMemo(() => items.some(item => !item.vat.isZero), [ items ]);

    return (
        <Table>
            <Table.Header>
                <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-end'>{t('quantity-label')}</Table.HeaderCol>
                <Table.HeaderCol className='text-end'>{t('unit-price-label')}</Table.HeaderCol>
                {showVat && (
                    <Table.HeaderCol className='text-end'>{t('vat-label')}</Table.HeaderCol>
                )}
                <Table.HeaderCol className='text-end'>{t('total-price-label')}</Table.HeaderCol>
            </Table.Header>
            <Table.Body>
                {items.map(item => (
                    <BasicItemRow key={item.id} item={item} showVat={showVat} />
                ))}
                <Table.Row>
                    <Table.Col colSpan={5} className='text-end'>
                        <span className='fw-medium me-4'>{t('total-price-sum-label')}</span>
                        <MoneyDisplay money={totalPrice} />
                    </Table.Col>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

type BasicItemRowProps = Readonly<{
    item: OrderItemFE;
    showVat: boolean;
}>;

function BasicItemRow({ item, showVat }: BasicItemRowProps) {
    return (
        <Table.Row>
            <Table.Col className='pre-line'>{item.title}</Table.Col>
            <Table.Col className='text-end align-top'>{item.quantity}</Table.Col>
            <Table.Col className='text-end align-top'><MoneyDisplay money={item.unitPrice} /></Table.Col>
            {showVat && (
                <Table.Col className='text-end align-top'>{item.vat.label}</Table.Col>
            )}
            <Table.Col className='text-end align-top'><MoneyDisplay money={computeItemTotalPrice(item)} /></Table.Col>
        </Table.Row>
    );
}

function computeTotalPriceSum(items: OrderItemFE[]): Money {
    const amount = items.reduce((ans, item) => ans + computeItemTotalPrice(item).amount, 0);
    const currency = items[0].unitPrice.currency;

    return { amount, currency };
}

function computeItemTotalPrice(item: OrderItemFE): Money {
    return timesBy(addVat(item.unitPrice, item.vat).withVat, item.quantity);
}

type BasicItemsFormProps = Readonly<{
    state: UseOrderState;
    form: OrderFormState;
    dispatch: UseOrderDispatch;
}>;

export function BasicItemsForm({ state, form, dispatch }: BasicItemsFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'basicItemsTable' });
    const common = useMemo(() => ({
        showVat: state.order.items.some(item => !item.vat.isZero),
        prices: computeFormPrices(form.basicItems, state.order.price.currency),
        isRemovingAllowed: form.basicItems.filter(item => !item.isDeleted).length > 1,
    }), [ form.basicItems, state.order.items, state.order.price ]);

    return (
        <div className='fl-basic-items-form d-flex flex-column gap-2 flex-grow-1'>
            {form.basicItems.map((item, index) => (
                <BasicItemRowForm
                    key={item.id}
                    state={state}
                    dispatch={dispatch}
                    item={item}
                    index={index}
                    common={common}
                />
            ))}
            <div className='d-flex justify-content-end py-2' style={{ paddingRight: '48px' }}>
                <span className='fw-medium me-4'>{t('total-price-sum-label')}</span>
                <MoneyDisplay money={common.prices.total} />
            </div>
        </div>
    );
}

type CommonData = {
    showVat: boolean;
    prices: { items: Money[], total: Money };
    isRemovingAllowed: boolean;
}

type BasicItemRowFormProps = Readonly<{
    state: UseOrderState;
    dispatch: UseOrderDispatch;
    item: EditableBasicItem;
    index: number;
    common: CommonData;
}>;

function BasicItemRowForm({ state, dispatch, item, index, common }: BasicItemRowFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'basicItemsTable' });
    const isFirstRow = index === 0;

    function input(field: FormPath<EditableBasicItem>, value: unknown) {
        dispatch({ type: 'input', field: `basicItems.${index}.${field}`, value });
    }

    return (
        <div className={clsx('row gx-2', !isFirstRow && 'fl-remove-labels')}>
            <Form.Group className='col-1'>
                <Form.Label>{t('quantity-label')}</Form.Label>
                <Form.Control
                    type='number'
                    value={item.quantity}
                    onChange={e => input('quantity', transformToPrice(e.target.value))}
                    disabled={item.isDeleted}
                />
            </Form.Group>
            <Form.Group className='col'>
                <Form.Label>{t('title-label')}</Form.Label>
                <Form.Control
                    value={item.title}
                    onChange={e => input('title', e.target.value)}
                    as={TextareaAutosize}
                    minRows={1}
                    className='fw-medium py-1 line-height-21'
                    disabled={item.isDeleted}
                />
                {!item.isDeleted && (
                    <TranslatedErrorMessage translationId={state.formErrors?.[`basicItems.${index}.title`]} />
                )}
            </Form.Group>
            <Form.Group className='col-2'>
                <Form.Label>{t('unit-price-label')}</Form.Label>
                <Form.Control
                    type='number'
                    value={item.unitPrice}
                    onChange={e => input('unitPrice', transformToPriceNegative(e.target.value))}
                    disabled={item.isDeleted}
                />
            </Form.Group>
            {common.showVat && (
                <Form.Group className='col-1'>
                    <Form.Label>{t('vat-label')}</Form.Label>
                    <VatSelect
                        value={item.vat}
                        onChange={value => value && input('vat', value)}
                        className='rs-condensed rs-menu-top'
                        disabled={item.isDeleted}
                    />
                </Form.Group>
            )}
            <div className='col-1'>
                <Form.Label>{t('total-price-label')}</Form.Label>
                <div className={clsx('text-end py-2', item.isDeleted && 'text-decoration-line-through')}>
                    <MoneyDisplay money={common.prices.items[index]} />
                </div>
            </div>
            <div className='col-auto'>
                <Form.Label className='no-width opacity-0 text-nowrap'>{t(item.isDeleted ? 'restore-item-button' : 'remove-item-button')}</Form.Label>
                <div className='py-1'>
                    {item.isDeleted ? (
                        <AddButton aria={t('restore-item-button')} onClick={() => input('isDeleted', false)} />
                    ) : (
                        <DeleteButton aria={t('remove-item-button')} onClick={() => input('isDeleted', true)} disabled={!common.isRemovingAllowed} />
                    )}
                </div>
            </div>
        </div>
    );
}

function computeFormItemTotalPrice(item: EditableBasicItem, currency: CurrencyFE): Money {
    return {
        amount: addVatAsNumber(toNumber(item.unitPrice) * toNumber(item.quantity), item.vat).withVat,
        currency,
    };
}

function computeFormPrices(items: EditableBasicItem[], currency: CurrencyFE): { items: Money[], total: Money } {
    const itemPrices = items.map(item => computeFormItemTotalPrice(item, currency));
    const amount = itemPrices.filter((_, i) => !items[i].isDeleted).reduce((ans, price) => ans + price.amount, 0);

    return {
        items: itemPrices,
        total: { amount, currency },
    };
}
