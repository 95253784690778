import type { CountryCode } from ':utils/i18n';
import { BbanStructure, IBANBuilder, IBAN, BIC } from 'ibankit';
import { countryByCode } from 'ibankit/lib/cjs/country';
import { type BbanStructurePart, PartType } from 'ibankit/lib/cjs/structurePart';
import { z } from 'zod';

const partTypeIds = {
    [PartType.BANK_CODE]: 'bank-code',
    [PartType.BRANCH_CODE]: 'branch-code',
    [PartType.ACCOUNT_NUMBER]: 'account-number',
    [PartType.BRANCH_CHECK_DIGIT]: 'branch-check-digit',
    [PartType.NATIONAL_CHECK_DIGIT]: 'national-check-digit',
    [PartType.CURRENCY_TYPE]: 'currency-type',
    [PartType.ACCOUNT_TYPE]: 'account-type',
    [PartType.OWNER_ACCOUNT_NUMBER]: 'owner-account-type',
    [PartType.IDENTIFICATION_NUMBER]: 'identification-number',
} as const;

// select countries only from https://github.com/koblas/ibankit-js/blob/main/src/bbanStructure.ts
// except for GT, MU, SC -- contain currencyType
// except for BR -- contains ownerAccountType
const ibanSupportedCountries = [ 'AL', 'AT', 'BA', 'BE', 'CZ', 'DE', 'DK', 'ES', 'FR', 'GB', 'IT', 'NO', 'PL', 'PT', 'RO', 'RS', 'SE', 'SI', 'SK', 'UA' ] as const;
type IbanSupportedCountry = typeof ibanSupportedCountries[number];

export const supportedCountries = [ ...ibanSupportedCountries, 'US' ] as const;
export type SupportedCountry = typeof supportedCountries[number];

export function isCountrySupported(country: string): country is SupportedCountry {
    return supportedCountries.some(c => c === country);
}

const partIds = [ 'bank-code', 'branch-code', 'account-number', 'branch-check-digit', 'national-check-digit', 'currency-type', 'account-type', 'owner-account-type', 'identification-number', 'us-account-info' ] as const;
type PartId = typeof partIds[number];

export type RawBankAccountData = z.infer<typeof zRawBankAccountData>;
export const zRawBankAccountData = z.union([
    z.object({
        country: z.enum(ibanSupportedCountries),
        parts: z.record(z.enum(partIds), z.string()),
    }),
    z.object({
        country: z.literal('iban'),
        parts: z.object({
            iban: z.string(),
            swift: z.string(),
        }),
    }),
    z.object({
        country: z.literal('US'),
        parts: z.object({
            'us-account-info': z.string(),
        }),
    }),
]);

export type BankAccountNumberPart = {
    /** The part id identifies the part in the form. It's basically just extension of the PartType to allow US accounts. */
    id: PartId;
    /** This is needed for translation. */
    country: CountryCode;
    required: boolean;
    /** Undefined for non-IBAN countries (i.e., US). */
    bbanPart?: BbanStructurePart;
};

function buildBbanPart(ibanBuilder: IBANBuilder, numberPart: BankAccountNumberPart, input: string) {
    if (!numberPart.bbanPart)
        throw new Error('non-iban country passed');  // should't happen

    if (numberPart.bbanPart.getPartType() === PartType.CURRENCY_TYPE)
        throw new Error('unsupported bban part');  // does not have an associated IBANBuilder function(?)

    switch (numberPart.bbanPart.getPartType()) {
    case PartType.BANK_CODE:
        ibanBuilder.bankCode(input);
        break;
    case PartType.BRANCH_CODE:
        ibanBuilder.branchCode(input);
        break;
    case PartType.ACCOUNT_NUMBER:
        ibanBuilder.accountNumber(input);
        break;
    case PartType.BRANCH_CHECK_DIGIT:
        ibanBuilder.branchCheckDigit(input);
        break;
    case PartType.NATIONAL_CHECK_DIGIT:
        ibanBuilder.nationalCheckDigit(input);
        break;
    case PartType.ACCOUNT_TYPE:
        ibanBuilder.accountType(input);
        break;
    case PartType.OWNER_ACCOUNT_NUMBER:
        ibanBuilder.ownerAccountType(input);
        break;
    case PartType.IDENTIFICATION_NUMBER:
        ibanBuilder.identificationNumber(input);
        break;
    }
}

export function processInputData(country: SupportedCountry | 'iban', numberParts: string[], iban: string, swift: string): RawBankAccountData {
    if (country === 'iban') {
        return {
            country: 'iban',
            parts: {
                iban,
                swift,
            },
        };
    }

    if (country === 'US') {
        return {
            country: 'US',
            parts: {
                'us-account-info': numberParts[0],
            },
        };
    }

    const specification = getCountrySpecification(country);
    if (numberParts.length !== specification.parts.length)
        throw new Error('Invalid input data.');

    return {
        country,
        parts: Object.fromEntries(specification.parts.map((part, index) => ([ part.id, numberParts[index] ]))),
    };
}

type ParsedBankAccountData = {
    country: IbanSupportedCountry;
    iban: string;
} | {
    country: 'iban';
    iban: string;
    swift: string;
} | {
    country: 'US';
    accountInfo: string;
};

export function parseBankAccountData({ country, parts }: RawBankAccountData): ParsedBankAccountData {
    if (country === 'iban') {
        return {
            country,
            iban: new IBAN(parts.iban).toString(),
            swift: new BIC(parts.swift).toString(),
        };
    }

    if (country === 'US') {
        return {
            country,
            accountInfo: parts['us-account-info'],
        };
    }

    const specification = getCountrySpecification(country);

    const countryCode = countryByCode(country);
    if (!countryCode)
        throw new Error('invalid country');

    const ibanBuilder = new IBANBuilder();
    ibanBuilder.countryCode(countryCode);

    specification.parts.forEach(part => {
        // Some form parts are optional (e.g., branch code in CZ).
        const inputField = parts[part.id] ?? '';
        const processedInput = processInputField(part, inputField);
        buildBbanPart(ibanBuilder, part, processedInput);
    });

    return {
        country,
        iban: ibanBuilder.build().toString(),
    };
}

function processInputField(numberPart: BankAccountNumberPart, inputField: string) {
    if (!numberPart.bbanPart)
        throw new Error('Non-IBAN country passed.');  // should't happen

    return inputField
        .replaceAll('-', '')  // often used for some form of separation, can't be in IBAN
        .padStart(numberPart.bbanPart.getLength(), '0');
}

type CountryAccountSpecification = {
    /** Undefined for non-IBAN countries (i.e., US). */
    bbanStructure?: BbanStructure;
    parts: BankAccountNumberPart[];
};

/** Returns placeholder for 'iban'. */
export function getCountrySpecification(country: SupportedCountry | 'iban'): CountryAccountSpecification {
    if (country === 'iban') {
        return {
            parts: [],
        };
    }

    if (country === 'US') {
        return {
            parts: [ {
                id: 'us-account-info',
                country,
                required: true,
            } ],
        };
    }

    const bbanStructure = BbanStructure.forCountry(country);
    if (!bbanStructure)
        throw new Error('unsupported bban country');

    const bbanParts = bbanStructure.getParts();
    const parts = bbanParts.map(part => transformBbanPart(country, part));

    if (country === 'CZ' || country === 'SK') {
        // put bank code to the end as it's usually written
        const bankCode = parts.shift()!;
        parts.push(bankCode);
    }

    return {
        bbanStructure,
        parts,
    };
}

function transformBbanPart(country: IbanSupportedCountry, part: BbanStructurePart): BankAccountNumberPart {
    if (part.getPartType() === PartType.CURRENCY_TYPE)
        throw new Error('unsupported bban part');  // does not have an associated IBANBuilder function(?)

    const isCZPrefix = country === 'CZ' && part.getPartType() === PartType.BRANCH_CODE;

    return {
        id: partTypeIds[part.getPartType()],
        country,
        required: !isCZPrefix,
        bbanPart: part,
    };
}

export function computeBankAccountLabel(accountData: ParsedBankAccountData): string {
    if (accountData.country === 'US')
        return accountData.accountInfo;

    const iban = new IBAN(accountData.iban);
    if (!(ibanSupportedCountries as readonly string[]).includes(iban.getCountryCode()))
        return iban.toFormattedString();

    // If it's iban supported country, we want to compute the domestic label instead of just IBAN (both of them will be on the invoice).
    const country = iban.getCountryCode() as IbanSupportedCountry;
    const structure = BbanStructure.forCountry(country);
    if (!structure)
        return '';

    const bban = iban.getBban();
    const parts: Partial<Record<PartId, string>> = {};
    structure.getParts().forEach(part => {
        const partId = partTypeIds[part.getPartType()];
        const value = structure.extractValue(bban, part.getPartType());
        if (value)
            parts[partId] = value;
    });

    // We don't use the raw data there, because they might be inconsistently formatted.
    // E.g., the account number 0123 might be stored as 123, which is valid for computations, but not for displaying.
    return labelFunctions[country](parts, iban);
}

type LabelFunction = (parts: Partial<Record<PartId, string>>, iban: IBAN) => string;

const labelFunctions: Record<Exclude<SupportedCountry, 'US'>, LabelFunction> = {
    // This is potentially not correct.
    'AL': p => `${p['bank-code']} ${p['branch-code']} ${p['national-check-digit']} ${p['account-number']}`,
    'AT': p => `BLZ ${p['bank-code']} Kto ${p['account-number']}`,
    'BA': p => `${p['bank-code']}-${p['branch-code']}-${p['account-number']}-${p['national-check-digit']}`,
    'BE': p => `${p['bank-code']}-${p['account-number']}-${p['branch-code']}`,
    'CZ': p => `${optionalPrefix(p['branch-code'])}${p['account-number']}/${p['bank-code']}`,
    // This is potentially not correct.
    'DE': p => `BLZ ${p['bank-code']} Kto ${p['account-number']}`,
    'DK': p => `${p['bank-code']} ${p['account-number']}`,
    'ES': p => `${p['bank-code']} ${p['branch-code']} ${p['national-check-digit']} ${p['account-number']}`,
    'FR': p => `${p['bank-code']} ${p['branch-code']} ${p['account-number']} ${p['national-check-digit']}`,
    'GB': p => `${p['branch-code']?.substring(0, 2)}-${p['branch-code']?.substring(2, 4)}-${p['branch-code']?.substring(4)} ${p['account-number']}`,
    'IT': p => `${p['national-check-digit']} ${p['bank-code']} ${p['branch-code']} ${p['account-number']}`,
    'NO': p => `${p['bank-code']} ${p['account-number']?.substring(0, 2)} ${p['account-number']?.substring(2)}${p['national-check-digit']}`,
    // This is sus but probably correct.
    'PL': (_, iban) => iban.toFormattedString().substring(2),
    'PT': p => `${p['bank-code']}.${p['branch-code']}.${p['account-number']}.${p['national-check-digit']}`,
    'RO': p => `${p['bank-code']} ${p['account-number']}`,
    'RS': p => `${p['bank-code']}-${p['account-number']}-${p['national-check-digit']}`,
    'SE': p => `${p['account-number']} ${p['national-check-digit']}`,
    'SI': p => `${p['bank-code']}${p['branch-code']}-${p['account-number']}${p['national-check-digit']}`,
    'SK': skLabelFunction,
    'UA': p => `${p['bank-code']} ${p['account-number']}`,
};

function optionalPrefix(prefix?: string) {
    const trimmed = (prefix ?? '').replace(/^0*/, '');
    return trimmed.length === 0 ? '' : `${trimmed}-`;
}

function skLabelFunction(parts: Partial<Record<PartId, string>>): string {
    // The input itself is probably not correct - we should split branch-code from account-number.
    const padded = (parts['account-number'] ?? '').padStart(16, '0');
    return `${optionalPrefix(padded.substring(0, 6))}${padded.substring(6)}/${parts['bank-code']}`;
}

export function getInputFieldsFromAccountData(accountData: RawBankAccountData) {
    if (accountData.country === 'iban') {
        return [
            accountData.parts.iban,
            accountData.parts.swift,
        ];
    }

    return getCountrySpecification(accountData.country).parts
        .map(part => {
            if (!(part.id in accountData.parts))
                return '';
            // @ts-expect-error already checked
            return accountData.parts[part.id];
        });
}

export function validateIban(iban: string) {
    return IBAN.isValid(iban);
}

export function validateSwift(swift: string) {
    return BIC.isValid(swift);
}
