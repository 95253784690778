import { type Money, type TaxRateFE, priceToServer } from ':frontend/modules/money';
import { type Id } from ':utils/id';
import { EventInfoFE } from '../Event';
import { EventParticipantFE, type PayingParticipant } from '../EventParticipant';
import { type OrderItemFE } from './OrderItem';
import { type DateTime } from 'luxon';
import type { EventOrderItemOutput } from ':utils/entity/orderItem';

export class EventOrderItemFE implements OrderItemFE {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: Money,
        readonly vat: TaxRateFE,
        readonly index: number,
        readonly createdAt: DateTime,

        readonly participant: PayingParticipant,
        readonly event: EventInfoFE,
    ) {
    }

    static fromServer(base: OrderItemFE, input: EventOrderItemOutput): EventOrderItemFE {
        const participant = EventParticipantFE.fromServer(input.eventParticipant) as PayingParticipant;
        return new EventOrderItemFE(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.vat,
            base.index,
            base.createdAt,
            participant,
            EventInfoFE.fromServer(input.eventParticipant.event),
        );
    }
}

export type EventItemUpdateToServer = {
    /** The id of the item. */
    id: string;
    title: string;
    unitPrice: number;
    vat: Id;
    isDeleted?: boolean;
};

export function eventItemUpdateToServer(input: EditableEventItem): EventItemUpdateToServer {
    return {
        id: input.id,
        title: input.title,
        unitPrice: priceToServer(input.unitPrice as number),
        vat: input.vat.id,
        isDeleted: input.isDeleted ? true : undefined,
    };
}

export type EditableEventItem = {
    readonly id: string;
    readonly event: EventInfoFE;
    title: string;
    unitPrice: number | '' | '-';
    quantity: number;
    vat: TaxRateFE;
    isDeleted: boolean;
};

export function isEventItemEqual(form: EditableEventItem, item: EventOrderItemFE): boolean {
    return !form.isDeleted
        && form.title === item.title
        && form.unitPrice === item.unitPrice.amount
        && form.vat === item.vat;
}
