/* eslint-disable react/prop-types */

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Skeleton from './Skeleton';

type TableProps = React.HTMLAttributes<HTMLTableElement> & {
    compact?: boolean;
    noLines?: boolean;
    noOuterLines?: boolean;
};

export function Table({ compact, noLines, noOuterLines, className, children, ...rest }: TableProps) {

    return (
        <table className={clsx('fl-table', { compact, 'no-lines': noLines, 'no-outer-lines': noOuterLines }, className)} {...rest}>
            {children}
        </table>
    );
}

type HeaderProps = Readonly<React.HTMLAttributes<HTMLTableRowElement>>;

function Header({ children, ...rest }: HeaderProps) {
    return (
        <thead>
            <tr {...rest}>
                {children}
            </tr>
        </thead>
    );
}

type BodyProps = Readonly<React.HTMLAttributes<HTMLTableSectionElement>>;

function Body({ children, ...rest }: BodyProps) {
    return (
        <tbody {...rest}>
            {children}
        </tbody>
    );
}

type RowProps = Readonly<React.HTMLAttributes<HTMLTableRowElement>>;

function Row({ children, ...rest }: RowProps) {
    return (
        <tr {...rest}>
            {children}
        </tr>
    );
}

type ColWidth = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 1.5;

type ColProps = React.DetailedHTMLProps<React.HTMLProps<HTMLTableCellElement>, HTMLTableCellElement> & {
    xs?: ColWidth;
    truncate?: boolean;
    link?: boolean | string;
};

function HeaderCol({ xs, truncate, link, className, children, ...rest }: ColProps) {
    const inner = typeof link === 'string'
        ? <Link to={link}>{children}</Link>
        : children;

    return (
        <th {...rest} className={clsx({
            [`fl-xs-${xs}`]: xs,
            truncate,
            'table-link': link,
        }, className)}
        >
            {inner}
        </th>
    );
}

function Col({ xs, truncate, link, className, children, ...rest }: ColProps) {
    const inner = typeof link === 'string'
        ? <Link to={link}>{children}</Link>
        : children;

    return (
        <td {...rest} className={clsx({
            [`fl-xs-${xs}`]: xs,
            truncate,
            'table-link': link,
        }, className)}
        >
            {inner}
        </td>
    );
}

type SkeletonProps = Readonly<{
    height: number;
}>;

function HeaderSkeleton({ height }: SkeletonProps) {
    return (
        <Table.Header>
            <Table.HeaderCol xs={2}><Skeleton height={height} /></Table.HeaderCol>
            <Table.HeaderCol xs={5}><Skeleton height={height} /></Table.HeaderCol>
            <Table.HeaderCol xs={2}><Skeleton height={height} /></Table.HeaderCol>
            <Table.HeaderCol xs={3}><Skeleton height={height} /></Table.HeaderCol>
        </Table.Header>
    );
}

function RowSkeleton({ height }: SkeletonProps) {
    return (
        <Table.Row>
            <Table.Col xs={2}><Skeleton height={height} /></Table.Col>
            <Table.Col xs={5}><Skeleton height={height} /></Table.Col>
            <Table.Col xs={2}><Skeleton height={height} /></Table.Col>
            <Table.Col xs={3}><Skeleton height={height} /></Table.Col>
        </Table.Row>
    );
}

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.HeaderCol = HeaderCol;
Table.Col = Col;
Table.HeaderSkeleton = HeaderSkeleton;
Table.RowSkeleton = RowSkeleton;

export default Table;
