import en_US_frontend from './en-US/frontend';
import en_US_shared from './en-US/shared.json';
import cs_CZ_frontend from './cs-CZ/frontend';
import cs_CZ_shared from './cs-CZ/shared.json';

export default {
    'en-US': {
        ...en_US_frontend,
        shared: en_US_shared,
    },
    'cs-CZ': {
        ...cs_CZ_frontend,
        shared: cs_CZ_shared,
    },
};
