import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { type BaseLocationFE } from ':frontend/types/location';
import LocationDisplay from ':frontend/components/location/LocationDisplay';
import NewLocationModal from ':frontend/components/location/NewLocationModal';
import EditLocationModal from ':frontend/components/location/EditLocationModal';
import DeleteLocationModal from ':frontend/components/location/DeleteLocationModal';
import { useTranslation } from 'react-i18next';
import { useLocations } from ':frontend/hooks';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';

export function LocationsEditor() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations' });
    const { addAlert } = useNotifications();
    const { locations, setLocations } = useLocations([]);
    const [ showNewLocationModal, setShowNewLocationModal ] = useState(false);
    const [ editingLocation, setEditingLocation ] = useState<BaseLocationFE>();
    const [ deletingLocation, setDeletingLocation ] = useState<BaseLocationFE>();

    function onLocationEdit(updatedLocation: BaseLocationFE) {
        const updatedLocations = [ ...locations ];
        const index = updatedLocations.findIndex(location => location.id === updatedLocation.id);
        updatedLocations[index] = updatedLocation;
        setLocations(updatedLocations);
        setEditingLocation(undefined);

        addAlert(createTranslatedSuccessAlert('common:locationSavedAlert'));
    }

    function onLocationDelete(deletedLocation: BaseLocationFE) {
        const filteredLocations = locations.filter(location => location.id !== deletedLocation.id);
        setLocations(filteredLocations);
        setDeletingLocation(undefined);

        addAlert(createTranslatedSuccessAlert('common:locationDeletedAlert'));
    }

    return (
        <div>
            <Button
                variant='primary'
                onClick={() => setShowNewLocationModal(true)}
            >
                {t('add-location-button')}
            </Button>
            <NewLocationModal
                show={showNewLocationModal}
                onClose={() => setShowNewLocationModal(false)}
                onLocationCreated={newLocation => setLocations([ ...locations, newLocation ])}
            />
            <EditLocationModal
                location={editingLocation}
                onClose={() => setEditingLocation(undefined)}
                onLocationEdit={onLocationEdit}
            />
            <DeleteLocationModal
                location={deletingLocation}
                onClose={() => setDeletingLocation(undefined)}
                onDelete={onLocationDelete}
            />
            <h2 className='mt-4'>{t('saved-locations-section-title')}</h2>
            {locations.map((location, index) => (
                <div key={location.id}>
                    <LocationDisplay
                        location={location}
                        onEdit={() => setEditingLocation(location)}
                        onDelete={() => setDeletingLocation(location)}
                    />
                    {index < locations.length - 1 && <hr />}
                </div>
            ))}
        </div>
    );
}
