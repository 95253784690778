import { useCallback, useMemo } from 'react';
import { type SingleValue } from 'react-select';
import FormSelect from ':frontend/components/forms/FormSelect';
import { useMaster } from ':frontend/context/UserProvider';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { type Id } from ':utils/id';
import { useTranslation } from 'react-i18next';

type Option = {
    value: Id;
    label: string;
};

function profileToOption(profile: InvoicingProfileFE): Option {
    return {
        value: profile.id,
        label: profile.title,
    };
}

type PlainInvoicingProfileSelectProps = Readonly<{
    id?: Id;
    onChange: (id?: Id) => void;
    options?: InvoicingProfileFE[];
    disabled?: boolean;
}>;

export function PlainInvoicingProfileSelect({ id, onChange, options, disabled }: PlainInvoicingProfileSelectProps) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const handleOnChange = useCallback((option: SingleValue<Option>) => onChange(option !== null ? option.value : undefined), [ onChange ]);

    const { profiles } = useMaster();
    const innerOptions = useMemo(() => (options ?? profiles).map(profileToOption), [ options, profiles ]);
    const innerValue = useMemo(() => {
        if (!id)
            return undefined;

        const profile = (options ?? profiles).find(p => p.id === id);
        if (!profile)
            return undefined;

        return profileToOption(profile);
    }, [ id, options, profiles ]);

    return (
        <FormSelect
            placeholder={t('profile-placeholder')}
            value={innerValue}
            onChange={handleOnChange}
            options={innerOptions}
            isDisabled={disabled}
        />
    );
}

type ControlledInvoicingProfileSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    options?: InvoicingProfileFE[];
    disabled?: boolean;
};

export function ControlledInvoicingProfileSelect<TFieldValues extends FieldValues>({ control, name, options, disabled }: ControlledInvoicingProfileSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: Id, onChange: (value?: Id ) => void } }) => {
        return (
            <PlainInvoicingProfileSelect
                id={field.value}
                onChange={field.onChange}
                options={options}
                disabled={disabled}
            />
        );
    }, [ options ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
