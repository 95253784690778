import { useCallback, useEffect, useState } from 'react';

const DEFAULT_PAGE = 1;

export type PaginationController = {
    /** counted from 1 */
    page: number;
    setPage: (page: number) => void;
    totalItems: number;
    setTotalItems: (totalItems: number) => void;
    reset: () => void;
};

export function usePagination(initialItems?: number): PaginationController {
    const [ totalItems, setTotalItems ] = useState(initialItems ?? 0);
    useEffect(() => {
        if (initialItems !== undefined)
            setTotalItems(initialItems);
    }, [ initialItems ]);

    const [ page, setPage ] = useState(DEFAULT_PAGE);
    const reset = useCallback(() => setPage(DEFAULT_PAGE), [ setPage ]);

    return {
        page,
        setPage,
        totalItems,
        setTotalItems,
        reset,
    };
}
