export const ResultSuccess: Result = { status: true };
export const ResultError: Result = { status: false, error: 'Error' };

export function DataResultSuccess<TData>(data: TData): DataResult<TData> {
    return { status: true, data };
}

export type Result<TData = undefined> = TData extends undefined ? EmptyResult : DataResult<TData>;

export type ErrorResult = {
    status: false;
    error: any;
    response?: any; // For the logging of errors on backend.
};

export type SuccessEmptyResult = {
    status: true;
};

export type EmptyResult = SuccessEmptyResult | ErrorResult;

export type SuccessDataResult<Data> = {
    status: true;
    data: Data;
};

export type DataResult<TData> = SuccessDataResult<TData> | ErrorResult;
