import { type Filter, type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Query } from ':frontend/utils/common';
import { type ClientInfoFE } from ':frontend/types/Client';

const filterName = 'clientName';

type FilterState = {
    query: string;
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <Dropdown.Item className='p-3 bg-white fl-design text-dark'>
            <InnerFilter state={state} setState={setState} />
        </Dropdown.Item>
    );
}

function FilterRowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div className='fl-design compact text-dark' style={{ width: 300 }}>
            <InnerFilter state={state} setState={setState} />
        </div>
    );
}

function InnerFilter({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });

    return (
        <Form.Control
            onChange={e => setState({ query: e.target.value })}
            value={state.query}
            placeholder={t('search-placeholder')}
            style={{ height: '33px', minHeight: '33px' }}
        />
    );
}

function FilterItemBadge() {
    return null;
}

function remove(state: FilterState): FilterState {
    return state;
}

function toItems(): undefined[] {
    return [];
}

function createFilterFunction({ query }: FilterState): FilterFunction<ClientInfoFE> {
    if (!query)
        return () => true;

    const filterQuery = new Query(...query.split(' '));

    return (data: ClientInfoFE) => data.query.match(filterQuery);
}

function toServer(state: FilterState): string {
    return state.query;
}

const ClientNameFilter: FilterDefinition<undefined, FilterState, ClientInfoFE> = {
    name: filterName,
    defaultValues: {
        query: '',
    },
    FilterToggleMenu,
    FilterRowMenu,
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string,
};

export default ClientNameFilter as Filter;
