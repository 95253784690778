import { locationFromServer, type BaseLocationFE } from ':frontend/types/location';
import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import type { DefaultValue } from './utils';
import { trpc } from ':frontend/context/TrpcProvider';

// @ts-expect-error React api overload
export function useLocations(defaultValue: DefaultValue<BaseLocationFE[]>): {
    locations: BaseLocationFE[];
    setLocations: Dispatch<SetStateAction<BaseLocationFE[]>>;
    addLocation: (location: BaseLocationFE) => void;
}

export function useLocations(): {
    locations: BaseLocationFE[] | undefined;
    setLocations: Dispatch<SetStateAction<BaseLocationFE[] | undefined>>;
    addLocation: (location: BaseLocationFE) => void;
};

export function useLocations(defaultValue?: DefaultValue<BaseLocationFE[]>) {
    const locationsQuery = trpc.location.getLocations.useQuery();
    const [ locations, setLocations ] = useState(defaultValue);

    useEffect(() => {
        if (!locationsQuery.data)
            return;

        setLocations(locationsQuery.data.map(locationFromServer));
    }, [ locationsQuery.data ]);

    const addLocation = useCallback((location: BaseLocationFE) => {
        if (locations)
            setLocations([ ...locations, location ]);

    }, [ locations ]);

    return {
        locations,
        setLocations,
        addLocation,
    };
}
