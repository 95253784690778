import { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RiAlertFill } from 'react-icons/ri';
import { ErrorType, type PlanExceededError, type SubscriptionInvalidError, type SubscriptionError, isPlanExceededError } from ':frontend/types/Subscription';
import { Trans, useTranslation } from 'react-i18next';
import { emptyFunction } from ':frontend/utils/common';
import { routesFE } from ':utils/routes';
import { useCached } from ':frontend/hooks';
import SpinnerButton from '../common/SpinnerButton';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';

type PlanExceededErrorModalProps = Readonly<{
    error: PlanExceededError | undefined;
    onHide: () => void;
}>;

export function PlanExceededErrorModal({ error, onHide }: PlanExceededErrorModalProps) {
    return (
        <CommonModal error={error} onHide={onHide} />
    );
}

type SubscriptionEndedErrorModalProps = Readonly<{
    error: SubscriptionInvalidError | undefined;
}>;

export function SubscriptionEndedErrorModal({ error }: SubscriptionEndedErrorModalProps) {
    return (
        <CommonModal error={error} onHide={emptyFunction} />
    );
}

type CommonModalProps = Readonly<{
    error: SubscriptionError | undefined;
    onHide: () => void;
}>;

function CommonModal({ error, onHide }: CommonModalProps) {
    const cachedError = useCached(error);
    if (!cachedError)
        return null;

    return (
        <Modal
            show={!!error}
            backdrop='static'
            keyboard={false}
            centered
        >
            <ModalBody error={cachedError} onHide={onHide} />
        </Modal>
    );
}

type ModalBodyProps = Readonly<{
    error: SubscriptionError;
    onHide: () => void;
}>;

function ModalBody({ error, onHide }: ModalBodyProps) {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const { t } = useTranslation('common', { keyPrefix: isMasterOrFreelancer ? error.type : 'subscription.schedulerError' });
    const [ isFetching, setIsFetching ] = useState(false);
    const subscriptionSessionPromiseRef = useRef<Promise<string>>();

    const createSubscriptionSessionMutation = trpc.subscription.createSubscriptionSession.useMutation();

    useEffect(() => {
        if (error.type !== ErrorType.PlanPending)
            return;

        subscriptionSessionPromiseRef.current = (async () => {
            try {
                const response = await createSubscriptionSessionMutation.mutateAsync();
                return response.continueUrl;
            }
            catch {
                return '';
            }
        })();
    }, [ error, createSubscriptionSessionMutation ]);

    async function openSubscriptionSession() {
        if (!subscriptionSessionPromiseRef.current)
            return;

        setIsFetching(true);
        window.location.href = await subscriptionSessionPromiseRef.current;
    }

    return (
        <Modal.Body className='text-center'>
            <RiAlertFill size={60} className='text-warning' />
            <h2>{t('title')}</h2>
            {!isMasterOrFreelancer ? (
                <div className='pre-wrap mb-3'>
                    {t('text')}
                </div>
            ) : (isPlanExceededError(error) ? (<>
                <div>
                    <Trans>
                        {t('cart-payments-text', { count: error.cart })}
                        {t('available-payments-text', { count: error.available })}
                    </Trans>
                </div>
                <Row className='mt-4'>
                    <Col>
                        <Button onClick={onHide} className='fl-button-inverse float-end'>
                            {t('hide-button')}
                        </Button>
                    </Col>
                    <Col>
                        <Link to={routesFE.subscription.path} className='float-start'>
                            <Button>
                                {t('new-plan-button')}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>) : (error.type === ErrorType.PlanPending ? (<>
                <div className='pre-wrap mb-3'>
                    {t('text')}
                </div>
                <SpinnerButton isFetching={isFetching} onClick={openSubscriptionSession}>
                    {t('checkButton')}
                </SpinnerButton>
            </>) : (<>
                <div className='pre-wrap mb-3'>
                    {t('text')}
                </div>
                <Link to={routesFE.subscription.path}>
                    <Button>
                        {t('new-plan-button')}
                    </Button>
                </Link>
            </>)))}
        </Modal.Body>
    );
}
