import { useState, type ReactNode, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import OpenCloseChevronButton from './OpenCloseChevronButton';
import { Button } from 'react-bootstrap';
import { ChevronDownIcon, ChevronUpIcon } from ':components/icons';

type CollapseProps = {
    height: number;
    children?: ReactNode;
    openedByDefault?: boolean;
};

export default function Collapse(props: CollapseProps) {
    const [ collapsed, setCollapsed ] = useState(!props.openedByDefault);
    const [ maxHeight, setMaxHeight ] = useState(0);
    const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [ collapsed ]);

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!contentRef.current?.children[0])
            return;

        setMaxHeight(contentRef.current.children[0].getBoundingClientRect().height);
    }, [ props.children ]);

    return maxHeight && maxHeight < props.height ? (
        <div ref={contentRef}>
            {props.children}
        </div>
    ) : (
        <div className={clsx('fl-collapse', collapsed ? 'collapsed' : 'opened')}>
            <div
                className='fl-collapse-content'
                style={{ height: collapsed ? props.height : maxHeight }}
                ref={contentRef}
            >
                {props.children}
            </div>
            <div className='fl-collapse-control'>
                <div className='fl-collapse-overlay' />
                <OpenCloseChevronButton opened={!collapsed} onClick={toggleCollapsed} />
            </div>
        </div>
    );
}

type SpoilerButtonProps = Readonly<{
    showTitle: string;
    hideTitle: string;
    isShow: boolean;
    setIsShow: (isShow: boolean) => void;
}>;

export function SpoilerButton({ showTitle, hideTitle, isShow, setIsShow }: SpoilerButtonProps) {
    return (
        <Button variant='outline-primary' className='compact' onClick={() => setIsShow(!isShow)}>
            {isShow ? (<>
                <ChevronUpIcon size={18} className='me-2' />
                <span className='fw-medium'>{hideTitle}</span>
            </>) : (<>
                <ChevronDownIcon size={18} className='me-2' />
                <span className='fw-medium'>{showTitle}</span>
            </>)}
        </Button>
    );
}

type SpoilerButtonRowProps = Readonly<{
    title: string;
    isShow: boolean;
    setIsShow: (isShow: boolean) => void;
    className?: string;
}>;

export function SpoilerButtonRow({ title, className, ...rest }: SpoilerButtonRowProps) {
    return (
        <div className={clsx('d-flex align-items-center', className)}>
            <div className='flex-grow-1 border-bottom' />
            <SpoilerButton showTitle={title} hideTitle={title} {...rest} />
            <div className='flex-grow-1 border-bottom' />
        </div>
    );
}
