import { Button, Modal } from 'react-bootstrap';
import { type BlockerModalControl } from '../hooks/useBlocker';
import { useTranslation } from 'react-i18next';

type BlockNavigationModalProps = Readonly<{
    control: BlockerModalControl;
}>;

export default function BlockNavigationModal({ control }: BlockNavigationModalProps) {
    const { t } = useTranslation('common', { keyPrefix: 'blockNavigationModal' });

    return (
        <Modal show={control.show}>
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('text')}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={control.confirm} variant='outline-danger'>
                    {t('confirmButton')}
                </Button>
                <Button onClick={control.cancel} variant='primary'>
                    {t('cancelButton')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
