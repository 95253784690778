import clsx from 'clsx';
import { BiErrorAlt } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

type ErrorMessageProps = Readonly<{
    message: string | undefined;
    className?: string;
}>;

export default function ErrorMessage({ message, className }: ErrorMessageProps) {
    return (
        <div className={clsx('text-danger mt-1', className)}>
            <BiErrorAlt className='me-2' style={{ fontSize: '20px', verticalAlign: 'middle' }} />
            <span style={{ verticalAlign: 'text-top' }}>
                <span>{message ?? ''}</span>
            </span>
        </div>
    );
}

type TranslatedErrorMessageProps = Readonly<{
    translationId: string | undefined;
    className?: string;
}>;

export function TranslatedErrorMessage({ translationId, className }: TranslatedErrorMessageProps) {
    const { t } = useTranslation();

    if (!translationId)
        return null;

    return (
        <div className={clsx('text-danger mt-1', className)}>
            <BiErrorAlt className='me-2 align-middle' style={{ fontSize: '20px' }} />
            <span style={{ verticalAlign: 'text-top' }}>
                <span>{t(translationId)}</span>
            </span>
        </div>
    );
}
