import { zId } from ':utils/id';
import { z } from 'zod';
import { zAddressOutput, zAddressUpsert, type AddressOutput } from './address';

export enum LocationType {
    video = 'video',
    physical = 'physical',
}

/**
 * The keys and values of the enum must be the same because values are sometimes used as keys.
 * In a selectbox, the value part is used as the option's value because it's converted to a string. Then we need to convert it back, so we use Enum[value] ...
 */
export enum PlatformType {
    googleMeet = 'googleMeet',
    microsoftTeams = 'microsoftTeams',
    zoom = 'zoom',
    physicalLocation = 'physicalLocation',
    custom = 'custom',
}

const zBaseLocationOutput = z.object({
    id: zId,
    title: z.string(),
});

export type VideoLocationOutput = z.infer<typeof zVideoLocationOutput>;
const zVideoLocationOutput = zBaseLocationOutput.extend({
    type: z.literal(LocationType.video),
    platform: z.string(),
    url: z.string(),
});

export type PhysicalLocationOutput = z.infer<typeof zPhysicalLocationOutput>;
const zPhysicalLocationOutput = zBaseLocationOutput.extend({
    type: z.literal(LocationType.physical),
    address: zAddressOutput,
});

export type LocationOutput = z.infer<typeof zLocationOutput>;
export const zLocationOutput = z.discriminatedUnion('type', [ zPhysicalLocationOutput, zVideoLocationOutput ]);

type DisplayNameInput = {
    type: LocationType.video;
    platform: string;
    url: string;
} | {
    type: LocationType.physical;
    address: AddressOutput;
};
export function getLocationDisplayName(input: DisplayNameInput): string {
    return input.type === LocationType.video
        ? `${input.platform} - ${input.url}`
        : `${input.address.country} - ${input.address.city} - ${input.address.postalCode}, ${input.address.line1}`;
}

export type VideoLocationUpsert = z.infer<typeof zVideoLocationUpsert>;
const zVideoLocationUpsert = z.object({
    type: z.literal(LocationType.video),
    title: z.string(),
    platform: z.string(),
    url: z.string(),
});

export type PhysicalLocationUpsert = z.infer<typeof zPhysicalLocationUpsert>;
const zPhysicalLocationUpsert = z.object({
    type: z.literal(LocationType.physical),
    title: z.string(),
    address: zAddressUpsert,
});

export type LocationUpsert = z.infer<typeof zLocationUpsert>;
export const zLocationUpsert = z.discriminatedUnion('type', [ zPhysicalLocationUpsert, zVideoLocationUpsert ]);
