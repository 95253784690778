import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AppUserSettingsFE, TeamSettingsFE } from ':frontend/types/settings';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import { ControlledCountrySelect, ControlledTimezoneSelect } from ':frontend/components/forms';
import type { CountryCode, TimezoneCode } from ':utils/i18n';
import type { AppUserSettingsEdit } from ':utils/entity/settings';
import { useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { useAnalytics } from ':frontend/types/analytics';
import type { CurrencyId } from ':utils/id';
import { TeamMemberRole } from ':utils/entity/team';
import { ControlledCurrencySelect } from '../forms/CurrencySelect';

type PreferencesFormData = {
    timezone: TimezoneCode;
    country: CountryCode;
    currencyId?: CurrencyId;
};

export function PreferencesForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { addAlert } = useNotifications();
    const analytics = useAnalytics();
    const userContext = useUser();
    const { settings, setSettings } = userContext;

    const { control, handleSubmit } = useForm<PreferencesFormData>({ defaultValues: {
        timezone: settings.timezone,
        country: settings.country,
        currencyId: userContext.role !== TeamMemberRole.scheduler ? userContext.teamSettings.currency.id : undefined,
    } });

    const updateAppUserSettingsMutation = trpc.user.updateAppUserSettings.useMutation();

    function onPreferencesSubmit(edit: AppUserSettingsEdit) {
        updateAppUserSettingsMutation.mutate(edit, {
            onError: () => {
                setSettings({ ...settings });
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                analytics.settingsUpdated({ ...edit });
                setSettings(AppUserSettingsFE.fromServer(response.appUserSettings));
                if (userContext.role !== TeamMemberRole.scheduler)
                    userContext.setTeamSettings(TeamSettingsFE.fromServer(response.teamSettings!));

                addAlert(createTranslatedSuccessAlert('pages:settings.general.preferences-settings-success'));
            },
        });
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onPreferencesSubmit)} className='fl-design'>
            <Row className='gap-row-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('timezone-label')}</Form.Label>
                    <ControlledTimezoneSelect
                        control={control}
                        name='timezone'
                    />
                </Form.Group>
                {/*
                    The point is not to disable the locale select, but to kinda hide the fact that we currently support only two languages.
                    <Form.Group as={Col} xs={6}>
                        <Form.Label>{t('locale-label')}</Form.Label>
                        <ControlledLocaleSelect
                            control={control}
                            name='locale'
                            type='base'
                        />
                    </Form.Group>
                */}
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <ControlledCountrySelect
                        control={control}
                        name='country'
                    />
                </Form.Group>
                {userContext.role !== TeamMemberRole.scheduler && (
                    <Form.Group as={Col} xs={6}>
                        <Form.Label>{t(`currency-label-${userContext.role}`)}</Form.Label>
                        <ControlledCurrencySelect
                            control={control}
                            name='currencyId'
                        />
                    </Form.Group>
                )}
            </Row>
            <Row className='mt-4'>
                <Col>
                    <SpinnerButton
                        type='submit'
                        isFetching={updateAppUserSettingsMutation.isPending}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}
