import { z } from 'zod';

/**
 * This regex allows these characters: lowercase letters, uppercase letters, digits, underscore `_`, dash `-`, and dot `.`.
 * The plus sign `+` is prohibited because google maps all email with the pattern *+*@* to the same email *@*. Therefore, one account would receive emails from multiple accounts.
 *  - Also, nobody with at least two braincels would use `+` in his email address.
 *  - Similarly, uppercase letters should be prohibited because email is supposed to be case insensitive. Therefore, A@B.C is the same as a@b.c (but it would be, again, unique from the perspective of our system).
 *      - However, this pattern allows them. The reason is that we want to fix them automatically for the user by the canonizeEmail function.
 */
export const EMAIL_SOFT_VALIDATION_PATTERN = /^[a-zA-Z\d\\._-]+@([a-zA-Z\d_-]+\.)+[a-zA-Z\d_-]{2,}$/;

export function isEmail(input: string): boolean {
    return EMAIL_SOFT_VALIDATION_PATTERN.test(input);
}

/**
 * This function is supposed to be used together with the EMAIL_SOFT_VALIDATION_PATTERN. The pattern discards the really invalid ones while this function fixes the slightly invalid ones.
 * It also removes the google `+` part (which wouldn't be allowed by the pattern). So yeah, this function actually fixes more than the pattern allows. That's because we need to fix emails straight outta Google (or sklep).
 */
export function canonizeEmail(input: string): string {
    const lowercase = input.toLowerCase();
    // Remove everything between the `+` and the `@` signs (including the `+`).
    return lowercase.replace(/\+.*@/, '@');
}

export const PASSWORD_MINIMAL_LENGTH = 8;
export const zPassword = z.string().min(PASSWORD_MINIMAL_LENGTH);

const EMAIL_CANONIZED_VALIDATION_PATTERN = /^[a-z\d\\._-]+@([a-z\d_-]+\.)+[a-z\d_-]{2,}$/;
export const zCanonizedEmail = z.string().regex(EMAIL_CANONIZED_VALIDATION_PATTERN);
