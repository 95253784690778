import { type DateTime } from 'luxon';
import { isSubscription, type SubscriptionTierCode, type SubscriptionTierPaymentPeriod, type SubscriptionOutput } from ':utils/entity/subscription';

export class SubscriptionFE {
    constructor(
        readonly code: SubscriptionTierCode,
        readonly paymentPeriod: SubscriptionTierPaymentPeriod,
        readonly createdAt: DateTime,
        readonly trialExpiresAt: DateTime,
        readonly isActive: boolean,
        readonly isPending: boolean,
        readonly isTrial: boolean,
        readonly isLogoChangeable: boolean,
        readonly isBankIntegrationEnabled: boolean,
    ) {}

    static fromServer(input: SubscriptionOutput): SubscriptionFE {
        const trialExpiresAt = input.createdAt.endOf('day').plus({ days: input.trialDays ?? 0 });

        return new SubscriptionFE(
            input.tierCode,
            input.paymentPeriod,
            input.createdAt,
            trialExpiresAt,
            isSubscription.active(input),
            isSubscription.pending(input),
            isSubscription.trial(input),
            input.isCustomLogoEnabled,
            input.isBankIntegrationEnabled,
        );
    }
}

export enum ErrorType {
    PlanPending = 'subscription.planPending',
    PlanEnded = 'subscription.planEnded',
    PlanExceeded = 'subscription.planExceeded',
}

export type PlanExceededError = {
    type: ErrorType.PlanExceeded;
    available: number;
    cart: number;
};

export function isPlanExceededError(value: unknown): value is PlanExceededError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return value.type === ErrorType.PlanExceeded;
}

const invalidSubscriptionErrors = [ ErrorType.PlanPending, ErrorType.PlanEnded ];

export type SubscriptionInvalidError = {
    type: typeof invalidSubscriptionErrors[number];
};

export function isSubscriptionInvalidError(value: unknown): value is SubscriptionInvalidError {
    if (!value || typeof value !== 'object')
        return false;

    if (!('type' in value))
        return false;

    return invalidSubscriptionErrors.includes(value.type as ErrorType);
}

export type SubscriptionError = PlanExceededError | SubscriptionInvalidError;
