import { useCallback, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { GeneralSettings } from './GeneralSettings';
import { AdvancedSettings } from './AdvancedSettings';
import { InvoicingProfileSettings } from './InvoicingProfileSettings';
import { PaymentsSettings } from './PaymentsSettings';
import { InvoicingProfilesSettings } from './InvoicingProfilesSettings';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { OnboardingFE } from ':frontend/types/Onboarding';
import { OnboardingFlag } from ':utils/entity/user';
import { trpc } from ':frontend/context/TrpcProvider';
import { ModulesSettings } from './ModulesSettings';
import { PlusIcon } from ':components/icons';

export default function Settings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings' });
    const { key } = useParams();
    const navigate = useNavigate();

    const userContext = useUser();
    const { onboarding, setOnboarding } = userContext;
    const masterContext = toMaster(userContext);

    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.settings.resolve({ key: newKey })), [ navigate ]);

    const updateOnboardingMutation = trpc.user.updateOnboarding.useMutation();

    const syncOnboarding = useCallback(() => {
        updateOnboardingMutation.mutate({ [OnboardingFlag.SettingsOpened]: true }, {
            onSuccess: response => {
                setOnboarding(OnboardingFE.fromServer(response));
            },
        });
    }, []);

    useEffect(() => {
        if (onboarding.flags.settingsOpened)
            return;

        syncOnboarding();
    }, []);

    return (
        <div className='d-flex flex-column h-100'>
            <Tab.Container
                activeKey={key}
                transition={false}
                onSelect={handleSelect}
            >
                <div className='border-1 border-bottom' style={{ backgroundColor: '#F5F5F5' }}>
                    <div className='container-small d-flex align-items-center justify-content-between gap-4'>
                        <Nav className='fl-tabs nav-tabs' as='ul'>
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='general'>{t('general-tab')}</Nav.Link></Nav.Item>
                            {masterContext && (<>
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='invoicing'>{t('invoicing-tab')}</Nav.Link></Nav.Item>
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='payments'>{t('payments-tab')}</Nav.Link></Nav.Item>
                            </>)}
                            <Nav.Item as='li'><Nav.Link as='button' eventKey='advanced'>{t('advanced-tab')}</Nav.Link></Nav.Item>
                            {/* {masterContext && (
                                <Nav.Item as='li'><Nav.Link as='button' eventKey='modules' aria-label={t('modules-tab')}><PlusIcon /></Nav.Link></Nav.Item>
                            )} */}
                        </Nav>
                    </div>
                </div>
                <div className='fl-main-scroller py-4'>
                    <Tab.Content className='container-small'>
                        <Tab.Pane eventKey='general' unmountOnExit>
                            <GeneralSettings />
                        </Tab.Pane>
                        {masterContext && (masterContext.profiles.length === 1 ? (
                            <Tab.Pane eventKey='invoicing' unmountOnExit>
                                <InvoicingProfileSettings />
                            </Tab.Pane>
                        ) : (
                            <Tab.Pane eventKey='invoicing' unmountOnExit>
                                <InvoicingProfilesSettings />
                            </Tab.Pane>
                        ))}
                        {masterContext && (
                            <Tab.Pane eventKey='payments' unmountOnExit>
                                <PaymentsSettings />
                            </Tab.Pane>
                        )}
                        <Tab.Pane eventKey='advanced' unmountOnExit>
                            <AdvancedSettings />
                        </Tab.Pane>
                        {/* {masterContext && (
                            <Tab.Pane eventKey='modules' unmountOnExit>
                                <ModulesSettings />
                            </Tab.Pane>
                        )} */}
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    );
}
