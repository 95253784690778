import { Day, type Recurrence } from './Recurrence';
import type { TFunction, i18n } from 'i18next';
import { complexTranslation, type ComplexTDefinition, type LocaleTFunction, type ComplexTFunction, FORMATS } from ':utils/i18n';

export function recurrenceToString(recurrence: Recurrence, i18n: i18n): string {
    const { t, ct } = complexTranslation(translations, i18n, 'common', 'recurrence');

    if (recurrence.count === 1)
        return t('toString.never');

    const dateString = recurrenceDateToString(recurrence, t, ct);
    if (!dateString) {
        // This should never happen!
        console.warn('Invalid recurrence: ', recurrence);
        return i18n.t('toString.invalid');
    }

    if (recurrence.count)
        return t('toString.end-count', { recurrence: dateString, count: recurrence.count });

    if (recurrence.until)
        return t('toString.end-until', { recurrence: dateString, date: recurrence.until.toFormat(FORMATS.date) });

    return dateString;
}

function recurrenceDateToString(recurrence: Recurrence, t: TFunction, ct: ComplexTFunction<RecurrenceTranslations>): string | undefined {
    if (recurrence.frequency === 'DAILY') {
        return recurrence.interval === 1
            ? t('toString.daily-one')
            : t('toString.daily-other', { ordinalCount: ordinal(t, recurrence.interval) });
    }

    if (recurrence.frequency === 'WEEKLY') {
        if (!recurrence.byWeekDay || recurrence.byWeekDay.length === 0)
            return;

        const days = recurrence.byWeekDay;
        const count = recurrence.interval;

        return tryWeekdayOrWeekend(t, days, count) ?? ct('weekly', { days, count });
    }

    if (recurrence.frequency === 'MONTHLY') {
        const count = recurrence.interval;

        if (recurrence.byMonthDay)
            return t('toString.monthly-day', { count, dayNumber: ordinal(t, recurrence.byMonthDay) });

        if (recurrence.byDay) {
            const startEnd = recurrence.byDay.occurence > 0 ? 'start' : 'end';
            return t(`toString.monthly-weekday-${startEnd}`, {
                count,
                occurence: ordinal(t, recurrence.byDay.occurence),
                day: t(`day.${recurrence.byDay.weekDay}`),
            });
        }
    }
}

function ordinal(t: TFunction, count: number): string {
    return t('ordinal-number', { count, ordinal: true });
}

function tryWeekdayOrWeekend(t: TFunction, days: Day[], count: number): string | undefined {
    if (days.length === 5) {
        if (days.includes(Day.Saturday) || days.includes(Day.Sunday))
            return;

        return t('toString.every-weekday', { count });
    }

    if (days.length === 2) {
        if (!days.includes(Day.Saturday) || !days.includes(Day.Sunday))
            return;

        return t('toString.every-weekend', { count });
    }
}


type RecurrenceTranslations = {
    'weekly': LocaleTFunction<{ days: Day[], count: number }>;
};

const translations: ComplexTDefinition<RecurrenceTranslations> = {
    'cs-CZ': {
        'weekly': (t, { days, count }) => {
            const every = t(`toString.every-${days[0]}`);
            const joinedDays = days.map(day => t(`day.${day}`)).join(', ');

            if (count === 1)
                return every + ' ' + joinedDays;

            return every + ' ' + ordinal(t, count) + ' ' + joinedDays;
        },
    },
    'en-US': {
        'weekly': (t, { days, count }) => {
            const every = t('toString.every');
            const joinedDays = days.map(day => t(`day.${day}`)).join(', ');

            if (count === 1)
                return every + ' ' + joinedDays;

            return every + ' ' + ordinal(t, count) + ' ' + joinedDays;
        },
    },
};
