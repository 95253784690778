import { useState } from 'react';
import { ClientInfoFE } from ':frontend/types/Client';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import ClientForm from ':frontend/components/client/ClientForm';
import { routesFE } from ':utils/routes';
import BlockNavigationModal from ':frontend/components/BlockNavigationModal';
import { useBlockerModal } from ':frontend/hooks';
import { useTranslation } from 'react-i18next';
import { createErrorAlert } from ':frontend/components/notifications/TranslatedAlertMessage';
import { useAnalytics } from ':frontend/types/analytics';
import type { ClientWithProfileInit } from ':utils/entity/client';
import { trpc } from ':frontend/context/TrpcProvider';

export default function NewClient() {
    const { t } = useTranslation('pages', { keyPrefix: 'newClient' });
    const { addAlert } = useNotifications();
    const [ isDirty, setIsDirty ] = useState(false);
    const { navigateUnblocked, control } = useBlockerModal(isDirty);
    const analytics = useAnalytics();

    const createClientMutation = trpc.$client.createClient.useMutation();

    function onSubmit(init: ClientWithProfileInit) {
        createClientMutation.mutate(init, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                const client = ClientInfoFE.fromServer(response);
                analytics.clientCreated(client);

                addAlert(createTranslatedSuccessAlert('common:clientCreatedAlert', { links: {
                    a: routesFE.clients.detail.resolve({ id: client.id, key: 'general' }),
                } }));

                navigateUnblocked(routesFE.clients.list);
            },
        });
    }

    return (
        <div className='container-small pb-5'>
            <h1>{t('page-title')}</h1>
            <BlockNavigationModal control={control} />
            <ClientForm
                onSubmit={onSubmit}
                isFetching={createClientMutation.isPending}
                onChange={setIsDirty}
            />
        </div>
    );
}
