import { useCallback, useEffect, useRef, useState } from 'react';

export enum EditingPhase {
    View = 'view',
    Editing = 'editing',
    Updating = 'updating',
}

type UseEditingState<T> = {
    value: T;
    phase: EditingPhase;
}

/**
 * Synchronizes the new value with the server. Then returns if the process was successful.
 */
export type SyncFunction<T> = (newValue: T) => Promise<boolean>;

type UseEditingOptions<T> = {
    /**
     * Returns whether the new value equals to the old one.
     * If not provided, the default comparison (===) is used instead.
     */
    equals?: (newValue: T, oldValue: T) => boolean;
    /**
     * Returns whether the value is compatible with the rules.
     */
    rule?: (value: T) => boolean;
}

export function useEditing<T>(syncedValue: T, syncFunction: SyncFunction<T>, { equals, rule }: UseEditingOptions<T> = {}) {
    const [ state, setState ] = useState<UseEditingState<T>>({
        value: syncedValue,
        phase: EditingPhase.View,
    });

    const isFetching = useRef(false);

    const setValue = useCallback((newValue: T) => {
        if (!rule || rule(newValue))
            setState({ value: newValue, phase: EditingPhase.Editing });
    }, [ rule ]);

    async function doUpdate(options?: { value: T }): Promise<boolean> {
        // We want to enable inputing the value directly in this function (e.g., when deleting a string, we can just use this with '' as the value).
        const updatingValue = options ? options.value : state.value;

        if (equals ? equals(updatingValue, syncedValue) : (updatingValue === syncedValue)) {
            setState({ value: updatingValue, phase: EditingPhase.View });
            return false;
        }

        if (isFetching.current)
            return false;
        isFetching.current = true;

        setState(state => ({ ...state, phase: EditingPhase.Updating }));

        const syncSuccessful = await syncFunction(updatingValue);
        const newValue = syncSuccessful ? updatingValue : syncedValue;
        
        setState({ value: newValue, phase: EditingPhase.View });

        isFetching.current = false;
        return syncSuccessful;
    }

    const setEditing = useCallback(() => setState(oldState => oldState.phase === EditingPhase.Updating ? oldState : ({ ...oldState, phase: EditingPhase.Editing })), []);

    useEffect(() => {
        setState(oldState => ({ ...oldState, value: syncedValue }));
    }, [ syncedValue ]);

    return {
        state,
        setValue,
        doUpdate,
        setEditing,
    };
}


type UseUpdatingState<T> = {
    value: T;
    isUpdating: boolean;
    originalValue: T;
}

type UseUpdatingReturn<T> = [ T, (newValue: T) => void, boolean ];

/**
 * This hooks enables to override a value by its new value while it's being updated.
 * The value is always overriden but it is synced everytime the input value changes.
 * The reason is that there might be a delay between when the syncFunction finishes and the input value changes. This would cause a momentary flicker back to old value.
 * It also blocks any attempts to change while updating.
 */
export function useUpdating<T>(value: T, syncFunction: SyncFunction<T>): UseUpdatingReturn<T> {
    const [ state, setState ] = useState<UseUpdatingState<T>>({
        value,
        isUpdating: false,
        originalValue: value,
    });

    const isFetching = useRef(false);

    const updateValue = useCallback(async (newValue: T) => {
        if (isFetching.current)
            return;
        isFetching.current = true;

        setState(oldState => ({ value: newValue, isUpdating: true, originalValue: oldState.value }) );

        const result = await syncFunction(newValue);
        
        setState(oldState => ({ value: result ? oldState.value : oldState.originalValue, isUpdating: false, originalValue: oldState.originalValue }));

        isFetching.current = false;
    }, [ syncFunction ]);

    useEffect(() => {
        setState(oldState => ({ value, isUpdating: oldState.isUpdating, originalValue: value }));
    }, [ value ]);

    return [
        state.value,
        updateValue,
        state.isUpdating,
    ];
}
