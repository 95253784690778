import { type Dispatch, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import { OrderFE, type ProductOrderInit, productOrderToServer, type CustomOrderInit, customOrderToServer, type EventOrderInitFE, eventOrderToServer } from ':frontend/types/orders/Order';
import { createErrorAlert, createTranslatedSuccessAlert } from '../../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { routesFE } from ':utils/routes';
import { useAnalytics } from ':frontend/types/analytics';
import { PlanExceededErrorModal } from '../SubscriptionErrorModal';
import { type PlanExceededError, isPlanExceededError } from ':frontend/types/Subscription';
import { ClientInfoFE } from ':frontend/types/Client';
import { type CheckoutInput, CheckoutPhase, parseCcEmails, useCheckout, CheckoutType, type CheckoutState } from './useCheckout';
import { Overview } from './Overview';
import { EmailPreview } from './EmailPreview';
import { InvoicePreviewModal } from './InvoicePreviewModal';
import { type i18n } from 'i18next';
import type { OrderInit } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';

export type CheckoutOutput = {
    type: 'back' | 'close' | 'planExceeded';
} | {
    type: 'addClients';
    clients: ClientInfoFE | ClientInfoFE[];
} | {
    type: 'finish';
    orders: OrderFE[];
};

type CheckoutProps = Readonly<{
    input: CheckoutInput;
    output: Dispatch<CheckoutOutput>;
    showCloseButton?: boolean;
}>;

export function Checkout({ input, output, showCloseButton }: CheckoutProps) {
    const { i18n } = useTranslation('components', { keyPrefix: 'checkout' });
    const masterContext = useMaster();
    const { state, dispatch } = useCheckout(input);

    const { addAlert } = useNotifications();
    const analytics = useAnalytics();
    const [ planExceededError, setPlanExceededError ] = useState<PlanExceededError>();

    const createOrderMutation = trpc.order.createOrder.useMutation();

    function createOrder() {
        const init = createOrderInit(input, state, masterContext, i18n);
        createOrderMutation.mutate(init, {
            onError: error => {
                if (isPlanExceededError(error.data)) {
                    setPlanExceededError(error.data);
                    output({ type: 'planExceeded' });
                    return;
                }

                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                const orders = response.orders.map(OrderFE.fromServer);
                orders.forEach(order => {
                    analytics.orderCreated(order, input.type, input.type);
                    // TODO Replace by the general alert when all checkouts are united. But be careful about the wording!
                    if (input.type === CheckoutType.Product) {
                        addAlert(createTranslatedSuccessAlert('common:productOrderCreatedAlert', { links: {
                            a: routesFE.orders.detail.resolve({ id: order.id }),
                        }, count: input.items.length }));
                    }
                    else {
                        addAlert(createTranslatedSuccessAlert('common:orderCreatedAlert', { links: {
                            a: routesFE.orders.detail.resolve({ id: order.id }),
                        } }));
                    }
                });

                const newClients = response.newClients.map(ClientInfoFE.fromServer);
                newClients.forEach(client => analytics.clientCreated(client));
                output({ type: 'addClients', clients: newClients });

                output({ type: 'finish', orders });
            },
        });
    }

    const hidePreview = useCallback(() => dispatch({ type: 'invoicePreview' }), [ dispatch ]);

    return (<>
        <InvoicePreviewModal
            invoicePreview={state.invoicePreview}
            onHide={hidePreview}
            onPlanExceededError={setPlanExceededError}
        />
        <PlanExceededErrorModal error={planExceededError} onHide={() => setPlanExceededError(undefined)} />
        {state.phase === CheckoutPhase.Overview && (
            <Overview
                output={output}
                state={state}
                dispatch={dispatch}
                isFetching={createOrderMutation.isPending}
                createOrder={createOrder}
                showCloseButton={showCloseButton}
            />
        )}
        {state.phase === CheckoutPhase.EmailPreview && (
            <EmailPreview state={state} dispatch={dispatch} />
        )}
    </>);
}

function createOrderInit(input: CheckoutInput, state: CheckoutState, masterContext: MasterContext, i18n: i18n): OrderInit {
    const { paymentMethod, isSendNotification } = state.overview;
    const notification = isSendNotification
        ? { ...state.emailPreview.form, cc: parseCcEmails(state.emailPreview.form.cc) }
        : undefined;

    switch (input.type) {
    case CheckoutType.Custom: {
        const init: CustomOrderInit = {
            client: input.client,
            dueDays: input.dueDays,
            items: input.items,
            discountItems: state.cache.invoicesForClients[0].invoices.flatMap(invoice => invoice.discountItems),
            paymentMethod,
            notification,
        };
        return { custom: customOrderToServer(init, masterContext, i18n) };
    }
    case CheckoutType.Product: {
        const init: ProductOrderInit = {
            client: input.client,
            guest: input.guest,
            scheduler: input.scheduler,
            items: input.items,
            discountItems: state.cache.invoicesForClients[0].invoices.flatMap(invoice => invoice.discountItems),
            paymentMethod,
            notification,
        };
        return { product: productOrderToServer(init, masterContext, i18n) };
    }
    case CheckoutType.Event: {
        const init: EventOrderInitFE = {
            paymentMethod,
            notification,
            forClients: input.forClients,
        };
        return { event: eventOrderToServer(init, i18n) };
    }
    }
}
