import { type Dispatch, useCallback, useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { OrderFE } from ':frontend/types/orders/Order';
import CustomOrderForm, { type PreselectedData } from ':frontend/components/orders/CustomOrderForm';
import BlockNavigationModal from ':frontend/components/BlockNavigationModal';
import { useBlockerModal, useClients, type NavigationProperty, useNavigationAction, useCached } from ':frontend/hooks';
import { type Id } from ':utils/id';
import { routesFE } from ':utils/routes';
import { Checkout, type CheckoutOutput } from ':frontend/components/orders/checkout/Checkout';
import { type CustomCheckoutInput } from ':frontend/components/orders/checkout/useCheckout';
import { trpc } from ':frontend/context/TrpcProvider';

export type PreselectCustom = NavigationProperty<'preselectCustom', {
    clientId?: Id;
    orderId?: Id;
}>;

export default function NewCustomOrder() {
    const { clients, addClients } = useClients();
    const [ checkoutInput, setCheckoutInput ] = useState<CustomCheckoutInput>();
    const [ isPlanExceededError, setIsPlanExceededError ] = useState(false);
    const [ isDirty, setIsDirty ] = useState(false);

    function handleSubmit(orderInit: CustomCheckoutInput) {
        setCheckoutInput(orderInit);
    }

    const { navigateUnblocked, control } = useBlockerModal(isDirty && !isPlanExceededError);
    const handleDiscard = useCallback(() => navigateUnblocked(routesFE.root), [ navigateUnblocked ]);

    const action = useNavigationAction<PreselectCustom>('preselectCustom');
    const [ preselected, setPreselected ] = useState(initialPreselectedState(action?.data));

    const trpcUtils = trpc.useUtils();

    const fetchOrder = useCallback(async (id: Id) => {
        try {
            const response = await trpcUtils.order.getOrder.fetch({ id });
            const order = OrderFE.fromServer(response);
            setPreselected({ order });
        }
        catch {
            setPreselected(undefined);
        }
    }, [ trpcUtils ]);

    useEffect(() => {
        if (!action?.data?.orderId)
            return;

        fetchOrder(action?.data?.orderId);
    }, [ action, fetchOrder ]);

    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            setCheckoutInput(undefined);
            break;
        case 'addClients':
            addClients(action.clients);
            break;
        case 'finish':
            navigateUnblocked(routesFE.orders.list);
            break;
        case 'planExceeded':
            setIsPlanExceededError(true);
            break;
        }
    }, [ addClients, navigateUnblocked ]);

    if (!clients || preselected === 'fetching')
        return null;

    return (<>
        <CheckoutModal input={checkoutInput} output={checkoutOutput} />
        <BlockNavigationModal control={control} />
        <Container className='content-large'>
            <CustomOrderForm
                clients={clients}
                onSubmit={handleSubmit}
                onDiscard={handleDiscard}
                onChange={setIsDirty}
                preselected={preselected}
            />
        </Container>
    </>);
}

type PreselectedState = PreselectedData | 'fetching';

function initialPreselectedState(input: PreselectCustom['data']): PreselectedState | undefined {
    if (!input)
        return undefined;

    if (input.clientId)
        return { clientId: input.clientId };

    if (input.orderId)
        return 'fetching';
}

type CheckoutModalProps = Readonly<{
    input?: CustomCheckoutInput;
    output: Dispatch<CheckoutOutput>;
}>;

function CheckoutModal({ input, output }: CheckoutModalProps) {
    const cachedInput = useCached(input);
    if (!cachedInput)
        return null;

    return (
        <Modal
            show={!!input}
            className='fl-checkout-modal'
        >
            <Modal.Body className='p-0'>
                <Checkout
                    input={cachedInput}
                    output={output}
                />
            </Modal.Body>
        </Modal>
    );
}
