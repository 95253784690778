export abstract class TypedError extends Error {
    readonly httpStatus: number;

    // TODO: Causes should be added everywhere where it makes sense.
    protected constructor(
        readonly type: string,
        httpStatus?: number,
        cause?: unknown,
    ) {
        super(type, { cause: cause instanceof Error ? cause : undefined });
        this.httpStatus = httpStatus ?? 400;
    }

    getMessage(): string {
        return `${this.type} [${this.httpStatus}] (${this.cause ? 'with cause' : 'without cause'})`;
    }
}
