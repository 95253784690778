import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import OrdersTable from ':frontend/components/orders/OrdersTable';
import { InfoCard } from ':frontend/components/settings/InfoCard';

export default function Orders() {
    const { t } = useTranslation('pages');

    return (
        <Container className='pb-4'>
            <h1>{t('orders.page-title')}</h1>
            <InfoCard infoKey='orders' className='mb-4' />
            <OrdersTable />
        </Container>
    );
}
