import { useCallback } from 'react';
import { Table } from ':frontend/components/common';
import { Container } from 'react-bootstrap';
import { TeamFE, TeamMemberFE, TeamMembers } from ':frontend/types/Team';
import { getPersonName } from ':frontend/types/Person';
import { useMaster, useUser } from ':frontend/context/UserProvider';
import EditableTextInput from ':frontend/components/forms/EditableTextInput';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { trpc } from ':frontend/context/TrpcProvider';
import { useUpdating } from ':frontend/hooks';
import { ColorPickerDropdown } from ':frontend/components/forms/ColorPicker';

export default function TeamDetail() {
    const { t } = useTranslation('pages', { keyPrefix: 'team' });
    const { team, setTeam, teamMembers } = useMaster();

    const updateTeamMutation = trpc.team.updateTeam.useMutation();

    const syncTitle = useCallback(async (title: string) => {
        try {
            const response = await updateTeamMutation.mutateAsync({ title });
            const newTeam = TeamFE.fromServer(response);
            setTeam(newTeam);

            return true;
        }
        catch {
            return false;
        }
    }, [ setTeam, updateTeamMutation ]);

    return (
        <Container className='container-large pb-5 mt-4'>
            <EditableTextInput
                className='d-flex align-items-center gap-3 fl-reset-form-control fs-4 fw-medium mb-4'
                viewClassName='text-truncate'
                value={team.title}
                syncFunction={syncTitle}
            />
            <Table>
                <Table.Header>
                    <Table.HeaderCol xs='auto' />
                    <Table.HeaderCol>
                        {t('name')}
                    </Table.HeaderCol>
                    <Table.HeaderCol>
                        {t('role')}
                    </Table.HeaderCol>
                    <Table.HeaderCol>
                        {t('email')}
                    </Table.HeaderCol>
                    <Table.HeaderCol>
                        {t('phone')}
                    </Table.HeaderCol>
                </Table.Header>
                <Table.Body>
                    <MemberList members={teamMembers.all} />
                </Table.Body>
            </Table>
            <span className='d-block text-center w-100 fs-4 pt-4 pre-line lha-4'>
                <Trans
                    t={t}
                    i18nKey='add-members'
                    components={{
                        email: <a href='mailto:info@flowlance.com' target='_blank' rel='noreferrer' className='text-decoration-none' />,
                    }}
                />
            </span>
        </Container>
    );
}

type MemberListProps = Readonly<{
    members: TeamMemberFE[];
}>;

function MemberList({ members }: MemberListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'team' });
    const { appUser } = useUser();

    if (members.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={7} className='text-center fs-4 py-5'>
                    {t('no-members-text')}
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {members?.map(member => (
            <Table.Row className={clsx('hoverable', { 'bg-success bg-opacity-25': member.appUserId === appUser.id })} key={member.id}>
                <Table.Col xs='auto'><MemberColorSelect member={member} /></Table.Col>
                <Table.Col className='fw-medium'>{getPersonName(member)}</Table.Col>
                <Table.Col className='fw-medium'>{t(`roles.${member.role}`)}</Table.Col>
                <Table.Col className='fw-medium'>{member.email}</Table.Col>
                <Table.Col>{member.phoneNumber}</Table.Col>
            </Table.Row>
        ))}
    </>);
}

type MemberColorSelectProps = Readonly<{
    member: TeamMemberFE;
}>;

function MemberColorSelect({ member }: MemberColorSelectProps) {
    const { appUser, setTeamMembers } = useMaster();

    const updateTeamMemberMutation = trpc.team.updateTeamMember.useMutation();

    const syncColor = useCallback(async (newValue: string) => {
        if (member.color === newValue)
            return false;

        try {
            const response = await updateTeamMemberMutation.mutateAsync({ id: member.id, color: newValue });
            const updatedMember = TeamMemberFE.fromServer(response);
            setTeamMembers(members => {
                const newAll = [ ...members.all ];
                const index = newAll.findIndex(m => m.id === updatedMember.id);
                newAll[index] = updatedMember;

                return new TeamMembers(appUser, newAll);
            });

            return true;
        }
        catch {
            return false;
        }
    }, [ member, appUser, setTeamMembers, updateTeamMemberMutation ]);

    const [ state, updateState ] = useUpdating(member.color, syncColor);

    return (
        <ColorPickerDropdown
            color={state}
            onSave={updateState}
        />
    );
}

