import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form } from 'react-bootstrap';
import PasswordInput from ':frontend/components/forms/PasswordInput';
import FormErrorMessage from ':frontend/components/forms/FormErrorMessage';
import { useForm } from 'react-hook-form';
import { PASSWORD_MINIMAL_LENGTH } from ':utils/forms';
import { SpinnerButton } from ':frontend/components/common';
import useNotifications from ':frontend/context/NotificationProvider';
import useAuth from ':frontend/context/AuthProvider';
import { Variant, Visibility } from ':frontend/types/notifications';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';

type PasswordFormData = {
    password: string;
    newPassword: string;
};

export function PasswordForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.password' });
    const { addAlert } = useNotifications();
    const { auth } = useAuth();
    const [ isFetching, setIsFetching ] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm<PasswordFormData>();

    async function onSubmit(data: PasswordFormData) {
        setIsFetching(true);
        const response = await auth.updatePassword(data.password, data.newPassword);
        setIsFetching(false);
        if (!response.status) {
            // TODO handle error

            addAlert(createTranslatedErrorAlert('pages:settings.password.error-alert'));
            return;
        }

        addAlert({ message: t('success-alert'), variant: Variant.Success }, Visibility.Global);
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className='fl-design'>
            <Form.Group>
                <Form.Label>{t('current-password-label')}</Form.Label>
                <PasswordInput
                    register={register('password', { required: t('current-password-required-error') })}
                />
                <FormErrorMessage errors={errors} name='password' />
            </Form.Group>
            <Form.Group className='mt-3'>
                <Form.Label>{t('new-password-label')}</Form.Label>
                <PasswordInput
                    register={register('newPassword', {
                        required: t('new-password-required-error'),
                        minLength: { value: PASSWORD_MINIMAL_LENGTH, message: t('new-password-too-short', { minimalLength: PASSWORD_MINIMAL_LENGTH }) },
                    })}
                />
                <FormErrorMessage errors={errors} name='newPassword' />
            </Form.Group>
            <Row className='mt-4'>
                <Col>
                    <SpinnerButton
                        type='submit'
                        isFetching={isFetching}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}
