import { Modal, Row, Col } from 'react-bootstrap';
import { type BaseLocationFE } from ':frontend/types/location';
import { SpinnerButton } from ':frontend/components/common';
import { useTranslation } from 'react-i18next';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { trpc } from ':frontend/context/TrpcProvider';

type DeleteLocationModalProps = Readonly<{
    location?: BaseLocationFE;
    onClose(): void;
    onDelete?(deletedLocation: BaseLocationFE): void;
}>;

export default function DeleteLocationModal({ location, onClose, onDelete }: DeleteLocationModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations' });
    const { addAlert } = useNotifications();

    const deleteLocationMutation = trpc.location.deleteLocation.useMutation();

    function deleteLocation() {
        if (!location)
            return;

        deleteLocationMutation.mutate({ id: location.id }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete?.(location);
            },
        });
    }

    return (
        <Modal show={!!location} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('delete-location-modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-center'>
                    <Col>{t('delete-location-modal-text')}<br /></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton
                    variant='danger'
                    isFetching={deleteLocationMutation.isPending}
                    onClick={deleteLocation}
                    className='mx-auto'
                >
                    {t('delete-location-modal-confirm')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}
