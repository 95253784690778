import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { type ClientInfoFE } from ':frontend/types/Client';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert } from '../notifications';
import { SpinnerButton } from '../common';
import { PlainInvoicingProfileSelect } from '../forms';
import ClientIconLink from '../client/ClientIconLink';
import { type Id } from ':utils/id';
import { trpc } from ':frontend/context/TrpcProvider';

type DeleteProfileModalProps = Readonly<{
    profile?: InvoicingProfileFE;
    allClients: ClientInfoFE[];
    onClose(): void;
    onDelete(deletedLocation: InvoicingProfileFE): void;
}>;

export default function DeleteProfileModal({ profile, allClients, onClose, onDelete }: DeleteProfileModalProps) {
    // TODO cache the profile

    const { t } = useTranslation('components', { keyPrefix: 'deleteProfileModal' });
    const { addAlert } = useNotifications();
    const { profiles } = useMaster();

    const clients = useMemo(
        () => profile && allClients.filter(client => client.invoicingProfileId === profile.id),
        [ profile, allClients ],
    );

    const availableProfiles = useMemo(
        () => profile && profiles.filter(p => p.id !== profile.id),
        [ profile, profiles ],
    );

    const [ replacementId, setReplacementId ] = useState<Id>();

    useEffect(() => {
        setReplacementId(availableProfiles?.[0].id);
    }, [ availableProfiles ]);

    const deleteInvoicingProfileMutation = trpc.invoicing.deleteInvoicingProfile.useMutation();

    function deleteProfile() {
        if (!profile)
            return;

        deleteInvoicingProfileMutation.mutate({ id: profile.id, replacementId }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete(profile);
            },
        });
    }

    return (
        <Modal show={!!profile} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-center'>
                    <Col>{t('text')}<br /></Col>
                </Row>
                {availableProfiles && clients && clients.length > 0 && (<>
                    <Row className='mt-4'><Col>{t('client-dependency-text', { count: clients.length })}</Col></Row>
                    <Row className='mt-2'><Col>{clients.map(client => <ClientIconLink key={client.id} client={client} />)}</Col></Row>
                    <Form.Group className='mt-2'>
                        <Form.Label>{t('new-profile-label', { count: clients.length })}</Form.Label>
                        <PlainInvoicingProfileSelect
                            id={replacementId}
                            onChange={setReplacementId}
                            options={availableProfiles}
                        />
                    </Form.Group>
                </>)}
            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton
                    variant='danger'
                    isFetching={deleteInvoicingProfileMutation.isPending}
                    onClick={deleteProfile}
                    className='mx-auto'
                >
                    {t('confirm-button')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}
