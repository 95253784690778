import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type LoadingProps = Readonly<{
    translationId?: string;
}>;

export default function Loading({ translationId }: LoadingProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'loading' });

    return (
        <div className='position-absolute w-100 h-100 d-flex flex-column justify-content-center'>
            <div className='d-flex justify-content-center'>
                <div style={{ marginBottom: '100px' }}>
                    <div className='m-3 ms-4 fs-1 text-secondary'>
                        {t([ translationId ?? '',  'default-text' ])}
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Spinner
                            animation='border'
                            variant='secondary'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
