import { z, type EnumLike } from 'zod';
import { zDateTime, SortOrder } from './common';
import type { DateTime } from 'luxon';

type DateTimeQuery = z.infer<typeof zDateTimeQuery>;
export const zDateTimeQuery = z.object({
    before: zDateTime.optional(),
    beforeInclusive: zDateTime.optional(),
    after: zDateTime.optional(),
    afterInclusive: zDateTime.optional(),
    exact: zDateTime.optional(),
});

export function dateTimeQueryToOrm({ before, beforeInclusive, after, afterInclusive, exact }: DateTimeQuery) {
    if (exact)
        return { $eq: exact };

    const output = {} as Record<'$lt' | '$lte' | '$gt' | '$gte', DateTime>;
    if (before)
        output.$lt = before;
    if (beforeInclusive)
        output.$lte = beforeInclusive;
    if (after)
        output.$gt = after;
    if (afterInclusive)
        output.$gte = afterInclusive;

    return output;
}

// TODO this could be handled on FE?
export function enumFilterQueryToOrm<E extends string>(enumObject: EnumLike, input: E[]) {
    if (input.length === 0 || input.length === Object.keys(enumObject).length)
        return {};

    return { $in: input };
}

export const zPage = z.number().int().gte(1);

export function zPaginated<T>(schema: z.ZodType<T>) {
    return z.object({
        items: z.array(schema),
        total: z.number().int().gte(0),
    });
}

export function paginatedQueryToOrm({ page }: { page?: number }) {
    if (page === undefined)
        return {};

    return {
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: (page - 1) * DEFAULT_ITEMS_PER_PAGE,
    };
}

export const DEFAULT_ITEMS_PER_PAGE = 15;

export function zOrderBy<TKey extends string>(keys: TKey[]): z.ZodObject<Record<TKey, z.ZodOptional<z.ZodNativeEnum<typeof SortOrder>>>> {
    const object = {} as Record<TKey, z.ZodOptional<z.ZodNativeEnum<typeof SortOrder>>>;
    for (const key of keys)
        object[key] = z.nativeEnum(SortOrder).optional();

    return z.object(object);
}
