import { useEffect, useMemo, useState } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { Controller, useFieldArray, useForm, type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { SpinnerButton } from '../common';
import FormErrorMessage from ':frontend/components/forms/FormErrorMessage';
import { useTranslation } from 'react-i18next';
import { useNestedForm } from ':frontend/utils/forms';
import { type BankAccountFE } from ':frontend/types/BankAccount';
import { getAllCurrencies } from ':frontend/modules/money';
import { useMaster } from ':frontend/context/UserProvider';
import { getCountrySpecification, getInputFieldsFromAccountData, isCountrySupported, processInputData, supportedCountries, validateIban, validateSwift, type SupportedCountry, type BankAccountNumberPart } from ':utils/entity/bankAccountData';
import { TabRadio } from '../forms/Radio';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { type AppUserSettingsFE } from ':frontend/types/settings';
import { ControlledCountrySelect } from '../forms';
import { useToggle } from ':frontend/hooks';
import ErrorMessage from '../forms/ErrorMessage';
import { type Id } from ':utils/id';
import type { CountryCode } from ':utils/i18n';
import type { BankAccountUpsert } from ':utils/entity/money';

/*
 * react-hook-form doesn't support flat arrays (just string[])
 * @see https://github.com/orgs/react-hook-form/discussions/7770#discussioncomment-2126583
 */
type ArrayStringValue = {
    value: string;
};

type BankingFormData = {
    country: SupportedCountry | 'iban';
    numberParts: ArrayStringValue[];
    currencyIds: Id[];
    iban: string;
    swift: string;
};

type BankingFormProps = Readonly<{
    input?: BankAccountFE;
    defaultCurrencyIds?: Id[];
    onSubmit: (output: BankAccountUpsert) => void;
    isFetching: boolean;
    onDelete?: () => void;
}>;

export default function BankingForm({ input, defaultCurrencyIds, onSubmit, isFetching, onDelete }: BankingFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'bankingForm' });
    const { settings, bankAccounts } = useMaster();

    const inputData = useMemo(() => inputToForm(input, settings.country, defaultCurrencyIds), [ input, settings.country, defaultCurrencyIds ]);
    const { register, handleSubmit, control, reset, watch, setValue, formState: { errors } } = useForm<BankingFormData>({
        defaultValues: inputData,
    });

    const handleNestedSubmit = useNestedForm(handleSubmit);
    const [ waitingForUpdate, setWaitingForUpdate ] = useState(false);

    const { fields } = useFieldArray({
        control,
        name: 'numberParts',
    });

    useEffect(() => {
        if (!waitingForUpdate)
            return;

        setWaitingForUpdate(false);
        reset(inputToForm(input, settings.country));
    }, [ input ]);

    const [ isError, setError ] = useToggle(false);

    function onValid(data: BankingFormData) {
        setWaitingForUpdate(true);
        let newAccountData;
        try {
            newAccountData = processInputData(data.country, arrayValuesToStrings(data.numberParts), data.iban, data.swift);
        }
        catch {
            setError.true();
            return;
        }

        setError.false();
        onSubmit({ raw: newAccountData, currencies: data.currencyIds });
    }

    const availableCurrencies = useMemo(() => {
        const alreadyTaken = bankAccounts.filter(account => account.id !== input?.id).flatMap(account => account.currencies);
        return getAllCurrencies().filter(currency => !alreadyTaken.find(c => c.id === currency.id));
    }, [ input, bankAccounts ]);

    const country = watch('country');
    const [ lastNonIbanCountry, setLastNonIbanCountry ] = useState<SupportedCountry>(getInitialNonIbanCountry(country, settings));

    useEffect(() => {
        if (country !== 'iban')
            setLastNonIbanCountry(country);
    }, [ country, setValue ]);

    const tabOptions = useMemo(() => [
        { value: 'accountNumber', label: t('account-number'), aria: t('account-number') },
        { value: 'iban', label: t('iban'), aria: t('iban') },
    ], [ t ]);

    function changeTab(value: string) {
        setValue('country', value === 'iban' ? 'iban' : lastNonIbanCountry);
        setError.false();
    }

    const countrySpecification = useMemo(() => getCountrySpecification(country), [ country ]);

    function changeCountry(country?: CountryCode) {
        if (!country || !isCountrySupported(country))
            return;

        const info = getCountrySpecification(country);
        setValue('numberParts', createEmptyInputs(info.parts.length));
        setError.false();
    }

    return (
        <Form noValidate onSubmit={handleNestedSubmit(onValid)}>
            <TabRadio
                value={country === 'iban' ? 'iban' : 'accountNumber'}
                onChange={changeTab}
                options={tabOptions}
            />
            {country !== 'iban' && (<>
                <Form.Group className='mt-3'>
                    <Form.Label className='d-flex align-items-center gap-1'>
                        {t('bank-account-country')}
                        <OverlayTrigger placement='right' overlay={<Tooltip>{t('bank-account-country-tooltip')}</Tooltip>}>
                            <span>
                                <IoHelpCircleOutline size={18} className='text-primary' />
                            </span>
                        </OverlayTrigger>
                    </Form.Label>
                    <ControlledCountrySelect
                        control={control}
                        name='country'
                        filterCountries={supportedCountries}
                        onChange={changeCountry}
                    />
                </Form.Group>

                <Row className='mt-3 gap-row-3'>
                    {fields.map((field, index) => (
                        <Form.Group as={Col} xs={fields.length === 1 ? 12 : 6} key={field.id}>
                            <NumberPartInput part={countrySpecification.parts[index]} index={index} register={register} errors={errors} />
                        </Form.Group>
                    ))}
                </Row>
            </>)}
            {country === 'iban' && (
                <div className='d-flex flex-column gap-2 mt-3'>
                    <Form.Group>
                        <Form.Label>
                            {t('iban-input')}
                        </Form.Label>
                        <Form.Control
                            key='iban'
                            {...register('iban', {
                                required: t('error.iban-not-entered'),
                                validate: (input) => validateIban(input) || t('error.iban-invalid'),
                            })}
                        />
                        <FormErrorMessage errors={errors} name={'iban'} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {t('swift-input')}
                        </Form.Label>
                        <Form.Control
                            key='swift'
                            {...register('swift', {
                                required: t('error.swift-not-entered'),
                                validate: (input) => validateSwift(input) || t('error.swift-invalid'),
                            })}
                        />
                        <FormErrorMessage errors={errors} name={'swift'} />
                    </Form.Group>
                </div>
            )}
            <Form.Group className='mt-3'>
                <Form.Label>{t('currencies-label')}</Form.Label>
                <Controller
                    control={control}
                    name='currencyIds'
                    render={({ field: { value, onChange } }) => (
                        <Row className='fw-medium gap-row-3'>
                            {getAllCurrencies().map((currency) => (
                                <Col xs={3} key={currency.id.toString()}>
                                    <Form.Check
                                        type='switch'
                                        disabled={!availableCurrencies.some(c => c.id === currency.id)}
                                        checked={value.some(c => c === currency.id)}
                                        onChange={event => {
                                            if (event.target.checked)
                                                onChange([ ...value, currency.id ]);
                                            else
                                                onChange(value.filter(c => c !== currency.id));
                                        }}
                                        label={currency.label}
                                    />
                                </Col>
                            ))}
                        </Row>
                    )}
                />
            </Form.Group>
            {isError && (
                <div className='mt-5'>
                    <ErrorMessage message={t('error-message')} />
                </div>
            )}
            <div className='d-flex gap-3 mt-5'>
                <SpinnerButton
                    className='w-100'
                    type='submit'
                    isFetching={isFetching}
                >
                    {t('save-button')}
                </SpinnerButton>
                {input && (
                    <Button
                        variant='danger'
                        className='w-100'
                        onClick={onDelete}
                    >
                        {t('delete-button')}
                    </Button>
                )}
            </div>
        </Form>
    );
}

type NumberPartInputProps = Readonly<{
    part: BankAccountNumberPart;
    index: number;
    register: UseFormRegister<BankingFormData>;
    errors: FieldErrors<BankingFormData>;
}>;

function NumberPartInput({ part, index, register, errors }: NumberPartInputProps) {
    const { t } = useTranslation('components', { keyPrefix: 'bankingForm' });
    // Fallback to generic label if country-specific label is not found. E.g., for 'branch-code' and 'CZ', we try (in this order):
    //  - part-label.CZ.branch-code
    //  - part-label.branch-code
    const labelIds = [ `part-label.${part.country}.${part.id}`, `part-label.${part.id}` ];
    // Fallback to generic label (similarly as above). However, if no label is found, we don't show any placeholder (see the default placeholder below).
    const placeholderIds = [ `part-placeholder.${part.country}.${part.id}`, `part-placeholder.${part.id}` ];

    return (<>
        <Form.Label>
            {t(labelIds)} {part.required ? '*' : ''}
        </Form.Label>
        <Form.Control
            placeholder={t(placeholderIds, DEFAULT_PLACEHOLDER)}
            {...register(`numberParts.${index}.value`, {
                required: {
                    value: part.required,
                    message: t('error.required-not-entered'),
                },
                maxLength: part.bbanPart ? {
                    value: part.bbanPart!.getLength(),
                    message: t('error.value-too-long'),
                } : undefined,
            })}
        />
        <FormErrorMessage errors={errors} name={`numberParts.${index}.value`} />
    </>);
}

const DEFAULT_PLACEHOLDER = '';

function createEmptyInputs(count: number): ArrayStringValue[] {
    return [ ...Array(count) ].map(() => ({ value: '' }));
}

function stringsToArrayValues(strings: string[]): ArrayStringValue[] {
    return strings.map(str => ({ value: str }));
}

export function arrayValuesToStrings(values: ArrayStringValue[]): string[] {
    return values.map(val => val.value);
}

export function inputToForm(input?: BankAccountFE, userCountry?: string, defaultCurrencyIds?: Id[]): BankingFormData {
    if (!input) {
        const defaultCountry = userCountry ? getDefaultCountry(userCountry) : 'iban';
        const countryInfo = getCountrySpecification(defaultCountry);

        return {
            country: defaultCountry,
            numberParts: createEmptyInputs(countryInfo.parts.length),
            currencyIds: defaultCurrencyIds ?? [],
            iban: '',
            swift: '',
        };
    }

    if (input.raw.country === 'iban') {
        return {
            country: 'iban',
            numberParts: [],
            currencyIds: input?.currencies.map(currency => currency.id),
            iban: input.raw.parts.iban,
            swift: input.raw.parts.swift,
        };
    }

    return {
        country: input.raw.country,
        numberParts: stringsToArrayValues(getInputFieldsFromAccountData(input.raw)),
        currencyIds: input.currencies.map(currency => currency.id),
        iban: '',
        swift: '',
    };
}

function getDefaultCountry(userCountry: string): SupportedCountry | 'iban' {
    return isCountrySupported(userCountry) ? userCountry : 'iban';
}

function getInitialNonIbanCountry(country: SupportedCountry | 'iban', settings: AppUserSettingsFE): SupportedCountry {
    if (country !== 'iban')
        return country;

    const defaultCountry = getDefaultCountry(settings.country);
    if (defaultCountry !== 'iban')
        return defaultCountry;

    return supportedCountries[0];
}
