import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { createActionState, NavigateWithState } from ':frontend/hooks';

import MasterDashboard from ':frontend/pages/master/MasterDashboard';
import SchedulerDashboard from ':frontend/pages/scheduler/SchedulerDashboard';
import Clients from ':frontend/pages/client/Clients';
import NewBackpayOrder from ':frontend/pages/client/NewBackpayOrder';
import ClientDetail from ':frontend/pages/client/ClientDetail';
import NewClient from ':frontend/pages/client/NewClient';
import ImportClients from ':frontend/pages/client/ImportClients';
import EventDetail from ':frontend/pages/event/EventDetail';
import Settings from ':frontend/pages/settings/Settings';
import OrderDetail from ':frontend/pages/orders/OrderDetail';
import NewCustomOrder from ':frontend/pages/orders/NewCustomOrder';
import Subscriptions from ':frontend/pages/Subscriptions';
import CalendarDetail, { type PreselectEvent } from ':frontend/pages/CalendarDetail';
import Orders from ':frontend/pages/orders/Orders';
import InvoicingProfileDetail from ':frontend/pages/InvoicingProfileDetail';
import DevPage from ':frontend/components/dev/DevPage';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import TeamDetail from ':frontend/pages/TeamDetail';
import { OrdersExport } from ':frontend/pages/orders/OrdersExport';
import RegisterGoogleCallback from ':frontend/pages/auth/RegisterGoogleCallback';
import { StoreDetail } from ':frontend/pages/store/StoreDetail';

const enableDevPage = import.meta.env.DEV;

export default function AuthorizedRoutes() {
    const location = useLocation();
    const from = (location.state as { from: string | undefined })?.from ?? routesFE.root;
    const isMasterOrFreelancer = !!toMaster(useUser());

    return (
        <Routes>
            <Route path={routesFE.register.google.path}               element={<RegisterGoogleCallback />} />

            <Route path={routesFE.calendar}                           element={<CalendarDetail />} />

            <Route path={routesFE.clients.list}                       element={<Clients />} />
            <Route path={routesFE.clients.detail.path}                element={<ClientDetail />} />
            <Route path={routesFE.clients.new}                        element={<NewClient />} />
            <Route path={routesFE.clients.import}                     element={<ImportClients />} />

            <Route path={routesFE.orders.list}                        element={<Orders />} />

            <Route path={routesFE.events.detail.path}                 element={<EventDetail />} />
            <Route path={routesFE.settings.path}                      element={<Settings />} />

            {isMasterOrFreelancer ? (<>
                <Route path={routesFE.dashboard}                      element={<MasterDashboard />} />

                <Route path={routesFE.orders.detail.path}             element={<OrderDetail />} />
                <Route path={routesFE.orders.export}                  element={<OrdersExport />} />
                <Route path={routesFE.orders.newBackpay}              element={<NewBackpayOrder />} />
                <Route path={routesFE.orders.newCustom}               element={<NewCustomOrder />} />
                <Route path={routesFE.orders.newEventGroup}           element={<NavigateWithState state={newEventGroupAction} replace to={routesFE.calendar} />} />
                {/* <Route path={routesFE.store.path}                     element={<StoreDetail />} /> */}
                <Route path={routesFE.team}                           element={<TeamDetail />} />
                <Route path={routesFE.invoicingProfiles.detail.path}  element={<InvoicingProfileDetail />} />
                <Route path={routesFE.subscription.path}                   element={<Subscriptions />} />
            </>) : (<>
                <Route path={routesFE.dashboard}                      element={<SchedulerDashboard />} />
            </>)}

            {enableDevPage && <Route path={routesFE.dev} element={<DevPage />} />}

            <Route path='*' element={<Navigate replace to={from} />} />
        </Routes>
    );
}

const newEventGroupAction = createActionState<PreselectEvent>('preselectEvent', 'new');
