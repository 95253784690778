import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { OrdersStatsFE, type PerCurrency, type OrderStateStats, type TimeChartData, orderStatsStateKeys } from ':frontend/types/orders/OrdersStats';
import { Trans, useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Doughnut, Line } from 'react-chartjs-2';
import { ArcElement, type ChartData, Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip as ChartJSTooltip, type ChartOptions, type Plugin, type TooltipItem } from 'chart.js';
import { MoneyDisplay } from '../common';
import { PercentDisplay } from '../common/MoneyDisplay';
import { ChevronLeftIcon, ChevronRightIcon, StonksIcon } from ':components/icons';
import { type TFunction } from 'i18next';
import DateTimeDisplay, { DateRangeDisplay, dateRangeToString } from '../common/DateTimeDisplay';
import { StringSelect } from '../forms/FormSelect';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import CurrencySelect from '../forms/CurrencySelect';
import { type CurrencyFE } from ':frontend/modules/money';
import { type OrderInfoFE } from ':frontend/types/orders/Order';
import clsx from 'clsx';
import { ORDER_STATE_DESCRIPTIONS } from './OrderStateBadge';
import { OrderState } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';
import type { DateRange } from ':utils/common';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, ChartJSTooltip);

export const PERIODS_COUNT = 4;

export function OrdersStatsDisplay() {
    const [ rangeState, setRangeState ] = useState<DateRangeState>(cache({
        today: DateTime.now().startOf('day'),
        unit: 'month',
    }));
    const [ currency, setCurrency ] = useState<CurrencyFE>();
    const orderStatsQuery = trpc.order.getStats.useQuery(computePeriodsToServer(rangeState));
    const trpcUtils = trpc.useUtils();

    const stats = useMemo(() => {
        if (!orderStatsQuery.data)
            return;
        const newStats = OrdersStatsFE.fromServer(orderStatsQuery.data);
        if (newStats.perCurrency.length > 0)
            setCurrency(newStats.perCurrency[0].currency);

        return newStats;
    }, [ orderStatsQuery.data ]);

    useEffect(() => {
        trpcUtils.order.getStats.invalidate();
    }, [ rangeState ]);

    return (
        <div className='bg-white' style={{ borderRadius: '20px', width: '1020px' }}>
            <StatsHeader state={rangeState} setState={setRangeState} currency={currency} setCurrency={setCurrency} currencies={stats?.currencies} />
            <div style={{ height: '520px' }}>
                {stats ? (
                    <StatsInner stats={stats} currency={currency} />
                ) : (
                    <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
                        <Spinner variant='secondary' animation='border' className='fl-updating-spinner' />
                    </div>
                )}
            </div>
        </div>
    );
}

type StatsInnerProps = Readonly<{
    stats: OrdersStatsFE;
    currency?: CurrencyFE;
}>;

function StatsInner({ stats, currency }: StatsInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });
    const statsForCurrency = stats.perCurrency.find(perCurrency => perCurrency.currency === currency);

    if (statsForCurrency) {
        return (
            <StatsForCurrency stats={statsForCurrency} periods={stats.periods} />
        );
    }

    const exampleStats = OrdersStatsFE.createExample().perCurrency[0];

    return (
        <div className='position-relative'>
            <StatsForCurrency stats={exampleStats} periods={stats.periods} />
            <div className='position-absolute top-0 bg-white opacity-75 d-flex w-100 h-100 justify-content-center align-items-center'>
                <div className='fs-4'>{t('no-orders-stats-text')}</div>
            </div>
        </div>
    );
}

type StatsForCurrencyProps = Readonly<{
    stats: PerCurrency;
    periods: DateRange[];
}>;

function StatsForCurrency({ stats, periods }: StatsForCurrencyProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });

    return (
        <div className='d-flex'>
            <div className='p-4 border-end d-flex flex-column' style={{ width: '361px' }}>
                <StatsDougnhunt stats={stats} periods={periods} />
                <div className='mt-4 d-flex flex-grow-1 flex-column gap-3 justify-content-center'>
                    {Object.values(stats.states).map(data => orderStateRow(data, stats.maxStatePercent, t))}
                </div>
            </div>
            <div className='p-4 flex-grow-1 d-flex flex-column'>
                <div className='overflow-auto hide-scrollbar fl-scroll-shadow rounded rounded-4 border p-2' style={{ height: '300px' }}>
                    <StatsItemsDisplay orders={stats.orders} />
                </div>
                <div className='d-flex gap-4 mt-4'>
                    <TimeChart data={stats.productsSold} labelKey='products-sold-label' color='#6c93c1' chartColor='#a7beda' />
                    <TimeChart data={stats.activeClients} labelKey='active-clients-label' color='#b990d9' chartColor='#d5bce8' />
                </div>
            </div>
        </div>
    );
}

type StatsDougnhuntProps = Readonly<{
    stats: PerCurrency;
    periods: DateRange[];
}>;

function StatsDougnhunt({ stats, periods }: StatsDougnhuntProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });

    const data: ChartData<'doughnut', number[]> = useMemo(() => {
        const values = stats.totalValue.amount === 0
            ? {
                data: [ 1 ],
                backgroundColor: [ '#f5f5f5' ],
            } : {
                data: orderStatsStateKeys.map(state => stats.states[state].shareFromTotal),
                backgroundColor: [
                    'rgba(43, 147, 17, 0.7)',
                    'rgba(234, 157, 7, 0.7)',
                    'rgba(175, 20, 30, 0.7)',
                ],
            };
        const nonZeroValues = values.data.filter(value => value !== 0).length;

        return {
            datasets: [ {
                ...values,
                cutout: '75%',
                rotation: 180,
                spacing: nonZeroValues === 1 ? 0 : 2,
                borderWidth: 0,
            } ],
        };
    }, [ stats ]);

    return (
        <div className='position-relative'>
            <Doughnut data={data} options={DOUGHNUT_OPTIONS} />
            <div className='position-absolute d-flex flex-column align-items-center justify-content-center w-100 top-0' style={{ height: '300px' }}>
                <span className='text-secondary'>{t('total-earnings-label')}</span>
                <MoneyDisplay money={stats.totalValue} className='fw-bold fs-1 mb-2' noColor />
                <OverlayTrigger overlay={<Tooltip><IncreasePercentTooltip periods={periods} /></Tooltip>}>
                    <span style={{ color: getInreaseColor(stats.totalIncrease) }}>
                        <StonksIcon size={20} className='me-2' />
                        <PercentDisplay amount={stats.totalIncrease} className='fw-bold' noColor />
                    </span>
                </OverlayTrigger>
            </div>
        </div>
    );
}

function IncreasePercentTooltip({ periods }: Readonly<{ periods: DateRange[] }>) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });
    const previousPeriod = periods[1];

    return (
        <Trans
            t={t}
            i18nKey='increase-percent-tooltip'
            components={{
                // Again, we have to change the displayed date to inclusive one.
                range: <DateRangeDisplay {...previousPeriod} className='d-inline-flex' />,
            }}
        />
    );
}

function getInreaseColor(increase: number) {
    if (isNaN(increase))
        return undefined;

    const type = increase > 0
        ? OrderState.fulfilled
        : (increase < 0)
            ? OrderState.overdue
            : OrderState.new;

    return ORDER_STATE_DESCRIPTIONS[type].color;
}

const DOUGHNUT_OPTIONS: ChartOptions<'doughnut'> = {
    plugins: {
        legend: { display: false }, // Hide legend.
    },
    events: [], // Disable all hover events (this also hides tooltips).
};

function orderStateRow({ state, currentValue, shareFromTotal }: OrderStateStats, maxStatePercent: number, t: TFunction) {
    const backgroundColor = ORDER_STATE_DESCRIPTIONS[state].color;

    return (
        <div key={state} className='d-flex flex-nowrap align-items-center gap-2'>
            <div className='rounded-circle' style={{ width: '18px', height: '18px', backgroundColor }} />
            <span>{t(`${state}-state-label`)}</span>
            <div className='flex-grow-1' />
            <MoneyDisplay money={currentValue} noColor />
            {!isNaN(shareFromTotal) && (<>
                <div style={{ width: '4px', height: '20px', borderRadius: '2px', backgroundColor }} />
                <PercentDisplay amount={shareFromTotal} padToAmount={maxStatePercent} noColor />
            </>)}
        </div>
    );
}

type TimeChartProps = Readonly<{
    data: TimeChartData<number>;
    labelKey: string;
    color: string;
    chartColor: string;
}>;

function TimeChart({ data: { currentValue, history }, labelKey, color, chartColor }: TimeChartProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });
    const chartData: ChartData<'line', number[]> = useMemo(() => ({
        labels: history.map(point => dateRangeToString(point.from, point.to, undefined, true)),
        datasets: [ {
            data: history.map(point => point.value),
            borderColor: chartColor,
            pointBackgroundColor: chartColor,
        } ],
    }), [ history, chartColor ]);

    return (
        <div className='border p-3 rounded-4 flex-grow-1 flex-basis-0 d-flex justify-content-between'>
            <div style={{ color }} >
                <div className='fs-3 fw-bold'>{t(labelKey)}</div>
                <div className='fw-bold' style={{ fontSize: '50px' }}>{currentValue}</div>
            </div>
            <div style={{ width: '180px', height: '100px' }}>
                <Line data={chartData} options={LINE_OPTIONS} plugins={LINE_PLUGINS} />
            </div>
        </div>
    );
}

const dataLabels: Plugin<'line'> = {
    id: 'dataLabels',
    afterDatasetsDraw: (chart) => {
        const { ctx } = chart;
        ctx.save();

        const dataset = chart.config.data.datasets[0];
        const meta = chart.getDatasetMeta(0);

        ctx.font = 'bold 12px Work Sans';
        ctx.fillStyle = '' + dataset.pointBackgroundColor;

        for (let i = 0; i < dataset.data.length; i++) {
            const value = '' + dataset.data[i];
            const point = meta.data[i];
            const textWidth = ctx.measureText(value).width;

            ctx.fillText(value, point.x - textWidth / 2, point.y - 10);
        }
    },
};

const LINE_PLUGINS = [ dataLabels ];

const LINE_OPTIONS: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    plugins: {
        legend: { display: false }, // Hide legend.
        tooltip: {
            displayColors: false,
            callbacks: {
                // This is extremely misleading. The `label` callback means "the value of the data point". However, the `item.label` is "the label of the data point", i.e., its label on the x-axis.
                // Meanwhile, the `title` callback is the tooltip title, which would normally be `item.label`. Unfortunatelly, we can't use that, because then there would be a small gap under it.
                label: (item: TooltipItem<'line'>) => item.label,
                title: () => '',
            },
        },
    },
    scales: {
        y: {
            ticks: { display: false, count: PERIODS_COUNT },
            grid: { color: '#dee2e6', drawTicks: false },
            grace: '50%',
        },
        x: {
            ticks: { display: false },
            grid: { color: '#dee2e6', drawTicks: false },
            offset: true,
        },
    },
};

type StatsItemsDisplayProps = Readonly<{
    orders: OrderInfoFE[];
}>;

function StatsItemsDisplay({ orders }: StatsItemsDisplayProps) {
    return (
        <div className='d-flex flex-column'>
            {orders.map((order, index) => (
                <div key={order.id} className={clsx('d-flex align-items-center gap-3 pb-2 mb-2', index !== orders.length - 1 && 'border-bottom')}>
                    {/* <div className='fs-3' style={{ width: '20px' }}>{order.icon}</div> */}
                    <div>
                        <div className='fs-6 fw-bold'>{order.title}</div>
                        <DateTimeDisplay dateTime={order.issueDate} date className='fs-small text-secondary' />
                    </div>
                    <div className='flex-grow-1' />
                    {moneyDisplayWithState(order)}
                </div>
            ))}
        </div>
    );
}

function moneyDisplayWithState({ price, state }: OrderInfoFE) {
    const color = ORDER_STATE_DESCRIPTIONS[state].color;

    return (
        <span className='fw-bold' style={{ color }}>
            {state === OrderState.fulfilled && '+ '}<MoneyDisplay money={price} className='fw-bold' />
        </span>
    );
}

const UNIT_OPTIONS = [ 'week', 'month', 'quarter', 'year' ] as const;
type DateRangeState = {
    today: DateTime;
    unit: typeof UNIT_OPTIONS[number];
    cache: {
        /** Inclusive. */
        from: DateTime;
        /** Exclusive. */
        to: DateTime;
        /** Inclusive. Because we want to show `1.7. - 31.7.` instead of `1.7. - 1.8.`. */
        toDisplayed: DateTime;
        isOnEnd: boolean;
    };
};

function cache(state: Omit<DateRangeState, 'cache'>): DateRangeState {
    const from = state.today.startOf(state.unit);
    const fullTo = from.plus({ [state.unit]: 1 });
    const now = DateTime.now().startOf('day').plus({ days: 1 });
    const isOnEnd = +fullTo > +now;
    const to = (isOnEnd ? now : fullTo);
    const toDisplayed = to.minus({ days: 1 });

    return { ...state, cache: { from, to, toDisplayed, isOnEnd } };
}

// TODO DateRange from :utils
function computePeriodsToServer(state: DateRangeState): DateRange[] {
    let { from, to } = state.cache;
    const output = [ { from, to } ];
    for (let i = 0; i < PERIODS_COUNT - 1; i++) {
        to = from;
        from = from.minus({ [state.unit]: 1 });
        output.push({ from, to });
    }

    return output;
}

type StatsHeaderProps = Readonly<{
    state: DateRangeState;
    setState: Dispatch<SetStateAction<DateRangeState>>;
    currency?: CurrencyFE;
    setCurrency: Dispatch<SetStateAction<CurrencyFE | undefined>>;
    currencies?: CurrencyFE[];
}>;

function StatsHeader({ state, setState, currency, setCurrency, currencies }: StatsHeaderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });
    const { t: tu } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.units' });

    const move = useCallback((amount: number) => {
        setState(state => cache({ ...state, today: state.today.plus({ [state.unit]: amount }) }));
    }, [ setState ]);

    const setUnit = useCallback((unit?: string) => {
        setState(state => cache({ ...state, unit: unit as typeof UNIT_OPTIONS[number] }));
    }, [ setState ]);

    return (
        <div className='border-bottom px-4 py-3 d-flex align-items-center gap-3'>
            <DateRangeDisplay from={state.cache.from} to={state.cache.toDisplayed} className='fs-2 fw-bold' dateClassName='fw-bold' />
            <div className='flex-grow-1' />
            {currency && currencies && currencies.length > 1 && (
                <div style={{ width: '200px' }}>
                    <CurrencySelect
                        value={currency}
                        onChange={setCurrency}
                        options={currencies}
                        className='compact'
                    />
                </div>
            )}
            <span className='fl-close-buttons gap-2' style={{ height: '33px' }}>
                <button
                    className='fl-close-button icon'
                    type='button'
                    onClick={() => move(-1)}
                    aria-label={t(`prev-${state.unit}-button`)}
                >
                    <ChevronLeftIcon size={18} />
                </button>
                <button
                    className='fl-close-button icon'
                    type='button'
                    onClick={() => move(1)}
                    aria-label={t(`next-${state.unit}-button`)}
                    disabled={state.cache.isOnEnd}
                >
                    <ChevronRightIcon size={18} />
                </button>
            </span>
            <div style={{ width: '120px' }}>
                <StringSelect
                    value={state.unit}
                    onChange={setUnit}
                    options={UNIT_OPTIONS as unknown as string[]}
                    t={tu}
                    className='compact'
                />
            </div>
        </div>
    );
}
