import type { TrpcAppRouter } from ':backend/api/trpc.api';
import type { ReactNode } from 'react';
import { createTRPCReact } from '@trpc/react-query';
import { observable } from '@trpc/server/observable';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink, loggerLink, type TRPCClientError, type TRPCLink } from '@trpc/client';
import superjson from 'superjson';
import { env } from ':env';
import { routesBE } from ':utils/routes';
import { api } from ':frontend/utils/api';
import { AccessDeniedError } from ':utils/error/generic.error';

export const trpc = createTRPCReact<TrpcAppRouter>({
    // So that we don't have to manually call abort the requests because of double-rendering.
    abortOnUnmount: true,
});

const authorizer = api.backend.authorizer;

type TrpcErrorListener = (error: TRPCClientError<TrpcAppRouter>) => void;
const errorListeners = new Map<string, TrpcErrorListener>();

/** The key should be unique for each listener. */
export function setTrpcListener(key: string, listener: TrpcErrorListener | null) {
    if (listener === null)
        errorListeners.delete(key);
    else
        errorListeners.set(key, listener);
}

const errorLink: TRPCLink<TrpcAppRouter> = () => {
    // Here we just got initialized in the app - this happens once per app. Useful for storing cache for instance.
    return ({ next, op }) => {
        // This is when passing the result to the next link. Each link needs to return an observable which propagates results.
        return observable(observer => {
            const unsubscribe = next(op).subscribe({
                next(value) {
                    observer.next(value);
                },
                error(error) {
                    errorListeners.forEach(listener => listener(error));
                    if (error.message === AccessDeniedError.type)
                        authorizer.JWTExpired();

                    observer.error(error);
                },
                complete() {
                    observer.complete();
                },
            });
            return unsubscribe;
        });
    };
};

export const queryClient = new QueryClient();

const trpcClient = trpc.createClient({
    links: [
        loggerLink(),
        errorLink,
        httpBatchLink({
            url: env.VITE_APP_URL + routesBE.trpc.$router.prefix,
            transformer: superjson,
            fetch: (url, options) => {
                return fetch(url, {
                    ...options,
                    credentials: 'include',
                });
            },
            headers() {
                return {
                    authorization: authorizer.getAuthorizationHeader(),
                };
            },
        }),
    ],
});

export function TrpcProvider({ children }: Readonly<{ children: ReactNode }>) {
    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </trpc.Provider>
    );
}
