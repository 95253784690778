import { CrossedEyeIcon } from ':components/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type OnlyToYouLabelProps = Readonly<{
    className?: string;
}>;

export function OnlyToYouLabel({ className }: OnlyToYouLabelProps) {
    const { t } = useTranslation('components');

    return (
        <div className={clsx('fs-base d-flex align-items-center gap-2 text-muted fw-medium', className)}>
            <CrossedEyeIcon size={18} />
            <span>{t('only-to-you-label')}</span>
        </div>
    );
}
