import { type ReactNode } from 'react';
import clsx from 'clsx';
import { localizer } from '.';
import { type DateTime } from 'luxon';

type TimeSlotGroupProps = Readonly<{
    renderSlot?: (dateTime: DateTime, index: number) => ReactNode;
    group: DateTime[];
    date?: DateTime;
}>;

export default function TimeSlotGroup({ renderSlot, group, date }: TimeSlotGroupProps) {
    const isWeekend = date && localizer.isWeekend(date);

    return (
        <div className={clsx('rbc-timeslot-group', isWeekend && 'weekend')}>
            {group.map((dateTime, index) => (
                <div key={index} className={'rbc-time-slot'}>
                    {renderSlot?.(dateTime, index)}
                </div>
            ))}
        </div>
    );
}
