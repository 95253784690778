import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type Id } from ':utils/id';
import { type ClientTagFE } from ':frontend/types/ClientTag';
import FormSelect from ':frontend/components/forms/FormSelect';
import { useMaster } from ':frontend/context/UserProvider';
import { components, type OptionProps, type SingleValue } from 'react-select';
import { ClientTagDisplay } from ':frontend/components/client/ClientTags';

const filterName = 'clientTag';

type FilterState = {
    selectedIds: Id[];
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <Dropdown.Item className='p-3 bg-white fl-design text-dark'>
            <InnerFilter state={state} setState={setState} />
        </Dropdown.Item>
    );
}

function FilterRowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div className='fl-design compact text-dark' style={{ width: 200 }}>
            <InnerFilter state={state} setState={setState} />
        </div>
    );
}

function InnerFilter({ state, setState }: FilterMenuProps<FilterState>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });
    const { clientTags } = useMaster();
    const options: TagOption[] = useMemo(() => clientTags
        .filter(tag => !state.selectedIds.find(tagId => tagId === tag.id))
        .map(tag => ({ value: tag, label: tag.name })),
    [ clientTags, state ],
    );

    const [ innerValue, setInnerValue ] = useState<TagOption>();

    const handleOnChange = useCallback((option: SingleValue<TagOption>) => {
        if (!option)
            return;

        setState({ ...state, selectedIds: [ ...state.selectedIds, option.value.id ] });
        setInnerValue(undefined);
    }, [ state, setState ]);

    return (
        <FormSelect
            value={innerValue ?? null}
            options={options}
            onChange={handleOnChange}
            placeholder={t('search-placeholder')}
            components={{
                Option: OptionComponent as (props: OptionProps<TagOption, false>) => JSX.Element,
            }}
        />
    );
}

type TagOption = {
    value: ClientTagFE;
    label: ReactNode;
};

function OptionComponent({ children, ...props }: Readonly<OptionProps<TagOption, false>>) {
    if (typeof children !== 'string')
        return <components.Option {...props}>{children}</components.Option>;

    const tag = props.data.value;

    return (
        <components.Option {...props}>
            <div className='d-flex align-items-center'>
                <ClientTagDisplay tag={tag} />
            </div>
        </components.Option>
    );
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<Id>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });
    const { clientTags } = useMaster();
    const tag = useMemo(() => clientTags.find(tag => tag.id === item), [ item, clientTags ]);

    useEffect(() => {
        if (!tag)
            onClose(item);
    }, [ tag, item ]);

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <Trans t={t} i18nKey='badge-label' components={{ sm: <span className='fw-medium' /> }} values={{ name: tag?.name }} />
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: Id): FilterState {
    return {
        selectedIds: state.selectedIds.filter(id => id !== item),
    };
}

function toItems(state: FilterState): Id[] {
    return state.selectedIds;
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    if (state.selectedIds.length === 0)
        return () => true;

    return (data: ClientInfoFE) => state.selectedIds.every(id => data.tagIds.some(tagId => tagId === id));
}

function toServer(): undefined[] {
    return [];
}

const ClientTagFilter: FilterDefinition<Id, FilterState, ClientInfoFE> = {
    name: filterName,
    defaultValues: {
        selectedIds: [],
    },
    FilterToggleMenu,
    FilterRowMenu,
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => undefined[],
};

export default ClientTagFilter as Filter;
