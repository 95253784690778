import { useCallback, useMemo } from 'react';
import { getCountryOptionsTranslator } from ':frontend/types/i18n';
import FormSelect from './FormSelect';
import { useTranslation } from 'react-i18next';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { getI18nLocale, type CountryCode } from ':utils/i18n';

const CLEAR_VALUE = 'clear';

type CountrySelectProps = Readonly<{
    value?: CountryCode;
    onChange: (value?: CountryCode) => void;
    filterCountries?: readonly CountryCode[];
    placeholder?: string;
    isClearable?: boolean;
    disabled?: boolean;
}>;

//const MIN_LETTERS_TO_OPEN = 2;

export function CountrySelect({ value, onChange, filterCountries, placeholder: inputPlaceholder, isClearable, disabled }: CountrySelectProps) {
    const { t, i18n } = useTranslation('components', { keyPrefix: 'countrySelect' });
    //const [ menuIsOpen, setMenuIsOpen ] = useState(false);

    const placeholder = inputPlaceholder ?? t('placeholder');
    const { options, countryToOption } = getCountryOptionsTranslator(getI18nLocale(i18n));

    const filteredOptions = useMemo(() => {
        const filtered = filterCountries
            ? options.filter(option => filterCountries.includes(option.value))
            : options;

        const withClear = isClearable
            ? [ { value: CLEAR_VALUE, label: <span style={{ color: '#b3b3b6' }}>{placeholder}</span> }, ...filtered ]
            : filtered;

        return withClear;
    }, [ options, filterCountries, isClearable, placeholder ]);

    return (
        <FormSelect
            placeholder={placeholder}
            noOptionsMessage={() => t('no-options-message')}
            options={filteredOptions}
            value={(value && value !== CLEAR_VALUE) ? countryToOption(value) : undefined}
            onChange={option => onChange(option?.value !== CLEAR_VALUE ? option?.value : undefined)}
            isDisabled={disabled}
            //menuIsOpen={menuIsOpen}
            //onInputChange={newValue => setMenuIsOpen(newValue !== undefined && newValue.length >= MIN_LETTERS_TO_OPEN)}
        />
    );
}

type ControlledCountrySelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    filterCountries?: readonly CountryCode[];
    onChange?: (value?: CountryCode) => void;
    disabled?: boolean;
};

export function ControlledCountrySelect<TFieldValues extends FieldValues>({ control, name, filterCountries, onChange: externalOnChange, disabled }: ControlledCountrySelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: CountryCode, onChange: (value?: CountryCode ) => void } }) => {
        const finalOnChange = externalOnChange
            ? (value?: CountryCode) => {
                field.onChange(value);
                externalOnChange(value);
            } : field.onChange;

        return (
            <CountrySelect
                value={field.value}
                onChange={finalOnChange}
                filterCountries={filterCountries}
                disabled={disabled}
            />
        );
    }, [ filterCountries, externalOnChange ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
