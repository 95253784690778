import React from 'react';
import { useUncontrolled } from 'uncontrollable';
import clsx from 'clsx';
import { Navigate, rightToLeft } from './utils/common';
import { Views } from './Views';
import Toolbar from './Toolbar';
import { DateTime } from 'luxon';
import Month from './Month';
import Week from './Week';
import Agenda from './Agenda';
import { getNow } from './localizer';

const ViewObjects = {
    [Views.MONTH]: Month,
    [Views.WEEK]: Week,
    [Views.AGENDA]: Agenda,
};

class Calendar extends React.Component {
    static defaultProps = {
        events: [],
    };

    render() {
        const { view, events, style, className, elementProps, ...props } = this.props;

        const current = this.props.date || getNow();
        const ViewComponent = ViewObjects[view].component;

        return (
            <div
                {...elementProps}
                className={clsx(className, 'rbc-calendar', rightToLeft && 'rbc-rtl')}
                style={style}
            >
                <Toolbar
                    date={current}
                    view={view}
                    onView={this.handleViewChange}
                    onNavigate={this.handleNavigate}
                />
                <ViewComponent
                    {...props}
                    events={events}
                    date={current}
                    onNavigate={this.handleNavigate}
                    onDrillDown={this.handleDrillDown}
                    onSelectEvent={this.props.onSelectEvent}
                    onSelectSlot={this.props.onSelectSlot}
                    onShowMore={this.props.onShowMore}
                />
            </div>
        );
    }

    handleNavigate = (action, newDate) => {
        const { view, date, onNavigate } = this.props;
        const today = getNow();
        const finalDate = moveDate(view, action, newDate || date || today, today);

        onNavigate(finalDate, view, action);
    };

    handleViewChange = (view) => {
        if (view !== this.props.view)
            this.props.onView(view);
    };

    handleDrillDown = (date, view) => {
        if (view)
            this.handleViewChange(view);

        this.handleNavigate(Navigate.DATE, date);
    };
}

// export default uncontrollable(Calendar, {
//     date: 'onNavigate',
//     selected: 'onSelectEvent',
// });

function moveDate(view, action, date, today) {
    if (action === Navigate.TODAY)
        return today || DateTime.now();

    if (action === Navigate.DATE)
        return date;

    return ViewObjects[view].navigateTo(date, action);
}

const UncontrolledCalendar = (props) => {
    const controlledProps = useUncontrolled(props, {
        date: 'onNavigate',
        selected: 'onSelectEvent',
    });

    return <Calendar {...controlledProps} />;
};

export default UncontrolledCalendar;
