import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormSelect from './FormSelect';
import { useMaster } from ':frontend/context/UserProvider';
import { Button, Form, Modal } from 'react-bootstrap';
import BankingForm, { arrayValuesToStrings, inputToForm } from ':frontend/components/settings/BankingForm';
import { BankAccountFE } from ':frontend/types/BankAccount';
import { getAllCurrencies, getCurrency } from ':frontend/modules/money';
import { processInputData } from ':utils/entity/bankAccountData';
import { CloseIcon } from ':components/icons';
import { SpinnerButton } from '../common';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { useCached, useToggle } from ':frontend/hooks';
import { type Id } from ':utils/id';
import type { BankAccountUpsert } from ':utils/entity/money';
import { trpc } from ':frontend/context/TrpcProvider';

type NoBankAccountModalProps = Readonly<{
    show: boolean;
    onClose: () => void;
    /** Only the currencies that are currently unsupported. */
    currencyIds: Id[];
}>;

export default function NoBankAccountModal({ show, onClose, currencyIds }: NoBankAccountModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noBankAccountModal' });
    const { bankAccounts } = useMaster();
    const [ isFetching, setIsFetching ] = useState(false);
    const canBeEditing = bankAccounts.length > 0;
    const [ isEditing, setIsEditing ] = useToggle(canBeEditing);

    useEffect(() => {
        if (show && !canBeEditing)
            setIsEditing.false();
    }, [ show, canBeEditing, setIsEditing ]);

    const cachedCurrencyId = useCached(currencyIds[0]);
    if (!cachedCurrencyId)
        return null;

    const currency = getCurrency(cachedCurrencyId);

    return (
        <Modal contentClassName='fl-modal-medium' show={show} onHide={() => onClose()}>
            <Modal.Body>
                <CloseIcon
                    size={18}
                    className='clickable text-muted'
                    onClick={() => onClose()}
                    role='button'
                    style={{ position: 'absolute', top: 20, right: 20 }}
                />
                <div className='d-flex flex-column align-items-center'>
                    <img src='/static/images/bank-accounts/credit-card.svg' className='non-draggable' />
                    <h2 className='text-center'>
                        {t(isEditing ? 'editing-title' : 'creating-title')}
                    </h2>
                    <p className='text-center lha-3 mb-3'>
                        {isEditing ? t('editing-description', { currency: currency.label }) : t('creating-description')}
                    </p>
                </div>
                {isEditing ? (
                    <EditAccount onClose={onClose} currencyIds={currencyIds} isFetching={isFetching} setIsFetching={setIsFetching} />
                ) : (
                    <CreateAccount onClose={onClose} currencyIds={currencyIds} isFetching={isFetching} setIsFetching={setIsFetching} />
                )}
                {/* If we are editing, we can switch to creating. However, we can switch back only if there is an account to edit. */}
                {(isEditing || canBeEditing) && (
                    <div className='d-flex justify-content-end pt-2'>
                        <Button variant='link' className='text-decoration-none fw-medium' onClick={setIsEditing.toggle} disabled={isFetching}>
                            {t(isEditing ? 'start-creating-button' : 'start-editing-button')}
                        </Button>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}

type EditAccountProps = Readonly<{
    onClose: () => void;
    currencyIds: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function EditAccount({ onClose, currencyIds, isFetching, setIsFetching }: EditAccountProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noBankAccountModal' });
    const { addAlert } = useNotifications();
    const { bankAccounts, setBankAccounts } = useMaster();
    const [ selectedBankAccount, setSelectedBankAccount ] = useState<BankAccountFE>();

    const updateBankAccountMutation = trpc.money.updateBankAccount.useMutation();

    function updateAccount() {
        if (isFetching || !selectedBankAccount)
            return;

        const form = inputToForm(selectedBankAccount);
        // TODO check if all currencyIds are enabled
        form.currencyIds = enabledCurrencies;
        const editAccountData = processInputData(form.country, arrayValuesToStrings(form.numberParts), form.iban, form.swift);
        const edit = {
            raw: editAccountData,
            currencies: form.currencyIds,
            id: selectedBankAccount.id,
        };

        setIsFetching(true);
        updateBankAccountMutation.mutate(edit, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                const updatedAccount = BankAccountFE.fromServer(response);
                setBankAccounts((oldAccounts: BankAccountFE[]) => oldAccounts.map(a => a.id !== updatedAccount.id ? a : updatedAccount));

                addAlert(createTranslatedSuccessAlert('common:bankAccount.edited-alert'));
                // TODO don't close if there are still unsupported currencies.
                // TODO base the modal on state instead of on callbacks.
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    const bankAccountOptions = useMemo(() => {
        return bankAccounts.map(bankAccount => bankAccountToOption(bankAccount));
    }, [ bankAccounts ]);

    const availableCurrencies = useMemo(() => {
        if (!selectedBankAccount)
            return [];

        const alreadyTaken = bankAccounts.filter(account => account.id !== selectedBankAccount.id).flatMap(account => account.currencies);
        return getAllCurrencies().filter(currency => !alreadyTaken.find(c => c.id === currency.id));
    }, [ selectedBankAccount, bankAccounts ]);

    const [ enabledCurrencies, setEnabledCurrencies ] = useState<Id[]>([]);

    // Ww want to disable saving unless at least one new currency is selected.
    const newCurrencySelected = useMemo(() => {
        return currencyIds.some(needed => enabledCurrencies.some(enabled => enabled === needed));
    }, [ currencyIds, enabledCurrencies ]);

    return (<>
        <FormSelect
            value={selectedBankAccount ? bankAccountToOption(selectedBankAccount) : null}
            options={bankAccountOptions}
            onChange={option => {
                if (option) {
                    setSelectedBankAccount(option.value);
                    setEnabledCurrencies(option.value.currencies.map(currency => currency.id));
                }
                else {
                    setSelectedBankAccount(undefined);
                    setEnabledCurrencies([]);
                }
            }}
            className='mb-3'
            placeholder={t('account-select-placeholder')}
        />
        {selectedBankAccount && (
            <div className='d-flex flex-column gap-2 fw-medium'>
                {getAllCurrencies().map((currency) => (
                    <div className='d-flex' key={currency.id.toString()}>
                        <Form.Check
                            type='switch'
                            disabled={!availableCurrencies.some(availableCurrency => availableCurrency.id === currency.id)}
                            checked={enabledCurrencies.some(c => c === currency.id)}
                            onChange={event => {
                                if (event.target.checked)
                                    setEnabledCurrencies([ ...enabledCurrencies, currency.id ]);
                                else
                                    setEnabledCurrencies(enabledCurrencies.filter(c => c !== currency.id));
                            }}
                            label={currency.label}
                        />
                    </div>
                ))}
            </div>
        )}
        <SpinnerButton
            isFetching={isFetching}
            onClick={updateAccount}
            className='w-100 mt-4'
            disabled={!newCurrencySelected}
        >
            {t('submit-edit-button')}
        </SpinnerButton>
    </>);
}

function bankAccountToOption(bankAccount: BankAccountFE) {
    return {
        value: bankAccount,
        label: bankAccountToLabel(bankAccount),
    };
}

function bankAccountToLabel(bankAccount: BankAccountFE) {
    return (
        <div className='d-flex justify-content-between align-items-center'>
            <div>{bankAccount.label}</div>
            <div className='d-flex gap-1'>
                {bankAccount.currencies.map(currency => (
                    <div key={currency.id} className='border border-primary rounded px-1' style={{ fontSize: 12, lineHeight: '18px' }}>
                        {currency.label}
                    </div>
                ))}
            </div>
        </div>
    );
}

type CreateAccountProps = Readonly<{
    onClose: () => void;
    currencyIds: Id[];
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}>;

function CreateAccount({ onClose, currencyIds, isFetching, setIsFetching }: CreateAccountProps) {
    const { addAlert } = useNotifications();
    const { setBankAccounts } = useMaster();

    const createBankAccountMutation = trpc.money.createBankAccount.useMutation();

    function createAccount(init: BankAccountUpsert) {
        setIsFetching(true);
        createBankAccountMutation.mutate(init, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                const newAccount = BankAccountFE.fromServer(response);
                setBankAccounts((oldAccounts: BankAccountFE[]) => [ ...oldAccounts, newAccount ]);

                addAlert(createTranslatedSuccessAlert('common:bankAccount.created-alert'));
            },
            onSettled: () => {
                setIsFetching(false);
                onClose();
            },
        });
    }

    return (
        <BankingForm
            defaultCurrencyIds={currencyIds}
            onSubmit={createAccount}
            isFetching={isFetching}
        />
    );
}
