import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from ':frontend/context/UserProvider';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { navigateNewTab } from ':frontend/utils/common';
import { secondsToMilliseconds } from ':utils/common';
import { trpc } from ':frontend/context/TrpcProvider';

type NoStripeModalProps = Readonly<{
    show: boolean;
    onClose: (isSuccess: boolean) => void;
}>;

export function NoStripeModal({ show, onClose }: NoStripeModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'noStripeModal' });
    const { team } = useUser();
    const [ connectionUrl, setConnectionUrl ] = useState<string>();
    const [ isConnecting, setIsConnecting ] = useState(false);

    const connectStripeMutation = trpc.team.connectStripe.useMutation();

    const fetchConnectionUrl = useCallback(() => {
        connectStripeMutation.mutate(undefined, {
            onError: () => {
                // TODO Do something.
            },
            onSuccess: response => {
                setConnectionUrl(response.onboardingUrl);
            },
        });
    }, [ connectStripeMutation.mutate ]);

    useEffect(() => {
        // The link should be fetched in two cases:
        // - The modal was just opened (and wasn't opened before).
        // - The user clicked on the new-link-button.
        if (!show || connectionUrl || isConnecting)
            return;

        fetchConnectionUrl();
    }, [ show, connectionUrl, isConnecting, fetchConnectionUrl ]);

    const connectToStripe = useCallback(() => {
        if (!connectionUrl)
            return;

        setIsConnecting(true);
        setConnectionUrl(undefined);
        navigateNewTab(connectionUrl);
    }, [ connectionUrl ]);

    const utils = trpc.useUtils();

    useEffect(() => {
        if (!isConnecting)
            return;

        const interval = setInterval(() => utils.team.getTeam.invalidate(), secondsToMilliseconds(1));

        return () => clearInterval(interval);
    }, [ isConnecting, utils ]);

    useEffect(() => {
        if (team.isStripeConnected) {
            setIsConnecting(false);
            onClose(true);
        }
    }, [ team, onClose ]);

    return (
        <Modal size='lg' show={show} onHide={() => onClose(false)}>
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='fl-description-no-border'>{t('text')}</div>
                <div className='mt-5'>
                    {isConnecting ? (<>
                        <div>
                            {t('wait-for-connection-text')}
                        </div>
                        <div className='d-flex align-items-center mt-3'>
                            <span className='me-3'>{t('new-link-text')}</span>
                            <Button onClick={() => setIsConnecting(false)} variant='outline-primary' className='compact'>
                                {t('new-link-button')}
                            </Button>
                        </div>
                    </>) : (connectionUrl ? (
                        <Button onClick={connectToStripe}>
                            {t('connect-stripe-button')}
                        </Button>
                    ) : (
                        <div style={{ height: '42px' }} className='d-flex align-items-center'>
                            <span className='me-3'>{t('wait-for-url-text')}</span>
                            <Spinner size='sm' variant='dark' animation='border' />
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
}
