import { useCallback, useMemo, useState } from 'react';
import { type SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { type BaseLocationFE } from ':frontend/types/location';
import NewLocationModal from './NewLocationModal';
import { type Id } from ':utils/id';
import { useTranslation } from 'react-i18next';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { classNames, handleKeyDown } from '../forms/FormSelect';

type Option = {
    value: string;
    label: string;
};

function locationToOption(location: BaseLocationFE): Option {
    return {
        value: location.id,
        label: location.title,
    };
}

type LocationSelectProps = {
    locations: BaseLocationFE[];
    onLocationCreated?: (newLocation: BaseLocationFE) => void;
    value: Id | undefined;
    readOnly?: boolean;
    onChange?: (value: Id | undefined) => void;
    className?: string;
};

export default function LocationSelect({ locations, onLocationCreated, value, readOnly, onChange, className }: LocationSelectProps) {
    const { t } = useTranslation('components', { keyPrefix: 'locationSelect' });
    const [ showNewLocationModal, setShowNewLocationModal ] = useState(false);
    const [ newLocationTitle, setNewLocationTitle ] = useState('');

    const options = useMemo(() => locations.map(locationToOption), [ locations ]);
    const option = useMemo(() => options.find(option => value && value === option.value), [ options, value ]);

    // const option = undefined;

    const handleOnChange = useCallback((value: SingleValue<Option>) => {
        const selectedId = locations
            .map(location => location.id)
            .find(id => id === value?.value);

        onChange?.(selectedId);
    }, [ locations, onChange ]);

    const handleBeforeCreated = useCallback((value: string) => {
        setNewLocationTitle(value);
        setShowNewLocationModal(true);
        onChange?.(undefined);
    }, [ onChange ]);

    const handleLocationCreated = useCallback((location: BaseLocationFE) => {
        onLocationCreated?.(location);
        onChange?.(location.id);
    }, [ onLocationCreated, onChange ]);

    const { t: tf } = useTranslation('common', { keyPrefix: 'select' });
    const newOptionLabel = useCallback((input: string) => (<>
        {tf('create-option-label') + ' '}<span className='fw-semibold'>{input}</span>
    </>), [ tf ]);

    return (<>
        <NewLocationModal
            defaultTitle={newLocationTitle}
            show={showNewLocationModal}
            onClose={() => setShowNewLocationModal(false)}
            onLocationCreated={handleLocationCreated}
        />
        <CreatableSelect
            className={className}
            classNames={classNames}
            onKeyDown={handleKeyDown}
            placeholder={t('placeholder')}
            noOptionsMessage={() => t('no-options-placeholder')}
            options={options}
            value={option}
            menuIsOpen={readOnly ? false : undefined}
            onChange={handleOnChange}
            isDisabled={readOnly ? true : undefined}
            onCreateOption={handleBeforeCreated}
            formatCreateLabel={newOptionLabel}
            isClearable
        />
    </>);
}

type PlainLocationSelectProps = {
    locations: BaseLocationFE[];
    onLocationCreated?: (newLocation: BaseLocationFE) => void;
    value?: Id;
    readOnly?: boolean;
    onChange?: (value?: Id | null) => void;
    className?: string;
};

function PlainLocationSelect({ onChange, ...rest }: PlainLocationSelectProps) {
    const handleOnChange = useCallback((id?: Id) => onChange?.(id || null), [ onChange ]);

    return (
        <LocationSelect
            locations={rest.locations}
            onLocationCreated={rest.onLocationCreated}
            value={rest.value}
            onChange={handleOnChange}
            className={rest.className}
        />
    );
}

type ControlledLocationSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    locations: BaseLocationFE[];
    onLocationCreated?: (newLocation: BaseLocationFE) => void;
    className?: string;
};

export function ControlledLocationSelect<TFieldValues extends FieldValues>({ control, name, locations, onLocationCreated, className }: ControlledLocationSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: Id, onChange: (value?: Id | null) => void } }) => {
        return (
            <PlainLocationSelect
                locations={locations}
                onLocationCreated={onLocationCreated}
                value={field.value}
                onChange={field.onChange}
                className={className}
            />
        );
    }, [ locations, onLocationCreated, className ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
