import { zId } from ':utils/id';
import { z } from 'zod';
import { zClientParticipantUpsert, zContactParticipanUpsert, zEventParticipantOutput } from './eventParticipant';
import { DateTime } from 'luxon';
import { zDateTimeQuery, zOrderBy, zPage } from ':utils/query';
import { zClientInfoOutput, zClientInit } from './client';
import { millisecondsToSeconds, zDateTime } from ':utils/common';

export enum EventState {
    ready = 'ready',
    finished = 'finished',
    canceled = 'canceled',
}

export type EventInfoOutput = z.infer<typeof zEventInfoOutput>;
export const zEventInfoOutput = z.object({
    id: zId,
    title: z.string(),
    isCanceled: z.boolean(),
    start: zDateTime,
    end: zDateTime,
});

export type EventOutput = z.infer<typeof zEventOutput>;
export const zEventOutput = zEventInfoOutput.and(z.object({
    participants: z.array(zEventParticipantOutput),
    recurrenceIndex: z.number().optional(),
    notes: z.string(),
    description: z.string(),
    locationId: zId.optional(),
    googleId: z.string().optional(),
    googleCalendarId: z.string().optional(),
    deleted: z.boolean(),
    productIndex: z.number().optional(),
    productSessionsCount: z.number().optional(),
    appUser: zId,
}));

export function isEventEnded({ end }: { end: DateTime }): boolean {
    return end <= DateTime.now();
}

export type GetEventsQuery = z.infer<typeof zGetEventsQuery>;
export const zGetEventsQuery = z.object({
    state: z.array(z.nativeEnum(EventState)),
    participants: z.array(zId),
    notes: z.string(),
    start: zDateTimeQuery,

    page: zPage,
    orderBy: zOrderBy([ 'start' ]),
}).partial();

export type EventsOperationOutput = z.infer<typeof zEventsOperationOutput>;
export const zEventsOperationOutput = z.object({
    events: z.array(zEventOutput),
    newClients: z.array(zClientInfoOutput),
});

export type EventInit = z.infer<typeof zEventInit>;
export const zEventInit = z.object({
    title: z.string(),
    description: z.string(),
    start: zDateTime,
    end: zDateTime,
    recurrence: z.string().optional(),
    locationId: zId.optional(),
    clientParticipants: z.array(zClientParticipantUpsert),
    contactParticipants: z.array(zContactParticipanUpsert),
    notes: z.string().optional(),
    /** AppUser */
    schedulerId: zId.optional(),

    sendNotification: z.boolean(),
});

export type ProductEventInit = z.infer<typeof zProductEventInit>;
export const zProductEventInit = z.object({
    title: z.string(),
    locationId: zId.optional(),
    start: zDateTime,
    end: zDateTime,
    recurrence: z.string().optional(),
    /** Client */
    clientGuests: z.array(zId),
    /** Client */
    contactGuests: z.array(zClientInit),
    /** ProductOrderItem */
    orderItemId: zId,
    /** AppUser */
    schedulerId: zId.optional(),

    sendNotification: z.boolean(),
});

export type GoogleEventInit = z.infer<typeof zGoogleEventInit>;
export const zGoogleEventInit = z.object({
    clientParticipants: z.array(zClientParticipantUpsert),
    contactParticipants: z.array(zContactParticipanUpsert),
    googleCalendarId: z.string(),
    eventId: z.string(),
    recurringEventId: z.string().optional(),
});

export enum RecurrenceRange {
    This = 'this',
    Following = 'following',
    All = 'all',
}

export enum EventTransition {
    Cancel = 'cancel',
    Revoke = 'revoke',
}

export type EventEdit = z.infer<typeof zEventEdit>;
export const zEventEdit = z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    locationId: zId.nullish(),
    start: zDateTime.optional(),
    end: zDateTime.optional(),
    clientParticipants: z.array(zClientParticipantUpsert).optional(),
    contactParticipants: z.array(zContactParticipanUpsert).optional(),
    notes: z.string().optional(),
    transition: z.nativeEnum(EventTransition).optional(),

    id: zId,
    range: z.nativeEnum(RecurrenceRange),
    sendNotification: z.boolean(),
});

/**
 * This is a manual transition, meaning that it doesn't care about whether the event actually ended or other conditions.
 */
export function isEventTransitionPossible({ isCanceled }: { isCanceled: boolean }, transition: EventTransition): boolean {
    switch (transition) {
    case EventTransition.Cancel:
        return !isCanceled;
    case EventTransition.Revoke:
        return isCanceled;
    }
}

export type EventRemoval = z.infer<typeof zEventRemoval>;
export const zEventRemoval = z.object({
    id: zId,
    range: z.nativeEnum(RecurrenceRange),
    sendNotification: z.boolean().optional(),
});

/** Computes the duration of event in seconds. */
export function computeEventDuration({ start, end }: { start: DateTime, end: DateTime }): number {
    return millisecondsToSeconds(+end - +start);
}
