import { useState } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import { useUser } from ':frontend/context/UserProvider';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import clsx from 'clsx';
import { createTranslatedErrorAlert } from '../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { type AppUserFE } from ':frontend/types/AppUser';

export function GoogleIntegration() {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.google' });
    const { state, isFetching, connect } = useGoogleIntegration();

    return (<>
        <Row>
            <Col xs='auto' style={{ width: 80 }} className='d-flex align-items-center'>
                <img src={`/static/integration-icons/google-${state === IntegrationState.Partial ? 'email' : 'calendar'}.svg`} />
            </Col>
            <Col>
                <h2 className='mt-0'>{t('product')}</h2>
                <p className='mb-2'>{t('description')}</p>
                <div>
                    {state === IntegrationState.Partial ? (<>
                        {t(`features-${state}-active`).split(';').map(text => (
                            <BulletFeature text={text} key={text} isActive={true} />
                        ))}
                        {t(`features-${state}-available`).split(';').map(text => (
                            <BulletFeature text={text} key={text} isActive={false} />
                        ))}
                    </>) : (<>
                        {t(`features-${state}`).split(';').map(text => (
                            <BulletFeature text={text} key={text} isActive={state === IntegrationState.All} />
                        ))}
                    </>)}
                </div>
            </Col>
            <Col xs='auto' className='d-flex align-items-center'>
                {state === IntegrationState.All ? (
                    <Link to={routesFE.calendar}>
                        <Button variant='success'>
                            {t(`action-button-${state}`)}
                        </Button>
                    </Link>
                ) : (
                    <SpinnerButton
                        isFetching={isFetching}
                        onClick={() => connect()}
                    >
                        {t(`action-button-${state}`)}
                    </SpinnerButton>
                )}
            </Col>
        </Row>
        <Row>
            <Col xs='auto' style={{ width: 80 }} />
            <Col>

            </Col>
        </Row>
    </>);
}

function useGoogleIntegration() {
    const { addAlert } = useNotifications();
    const { appUser } = useUser();
    const [ isFetching, setIsFetching ] = useState(false);

    async function connect() {
        if (isFetching)
            return;

        const connectRes = await api.backend.googleIntegration();
        setIsFetching(false);
        if (!connectRes.status || !connectRes.data.redirectUrl) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        window.location.href = connectRes.data.redirectUrl;
    }

    return {
        state: getIntegrationState(appUser),
        isFetching,
        connect,
    };
}

enum IntegrationState {
    None = 'none',
    Partial = 'partial',
    All = 'all',
}

function getIntegrationState(appUser: AppUserFE) {
    // TODO this needs to be rethinked. There are more states now.

    if (!appUser.google.calendarId)
        return IntegrationState.None;

    return appUser.google.isContacts ? IntegrationState.All : IntegrationState.Partial;
}

type BulletFeatureProps = {
    text: string;
    isActive: boolean;
};

function BulletFeature({ text, isActive }: BulletFeatureProps) {
    return (
        <div>
            <IoIosCheckmarkCircle size={20} className={clsx('me-3', isActive ? 'text-success' : 'text-primary')} />
            <span>{text}</span>
        </div>
    );
}

export function GoogleIntegrationBadge() {
    const { t } = useTranslation('pages', { keyPrefix: 'integrations.google' });
    const { state, isFetching, connect } = useGoogleIntegration();

    if (state === IntegrationState.All)
        return null;

    return (<div>
        <OverlayTrigger
            placement='left'
            overlay={<Tooltip>{t(`badge-text-${state}`)}</Tooltip>}
            delay={200}
        >
            <div>
                <SpinnerButton
                    isFetching={isFetching}
                    className='w-100'
                    onClick={connect}
                    variant='outline-primary'
                >
                    <div className='fl-google-integration-icon me-2'>
                        <img src={`/static/integration-icons/google-${state === IntegrationState.Partial ? 'email' : 'calendar'}.svg`} />
                    </div>
                    <div>
                        {t(`badge-title-${state}`)}
                    </div>
                </SpinnerButton>
            </div>
        </OverlayTrigger>
    </div>);
}
