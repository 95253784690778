import type { i18n, TFunction } from 'i18next';
import { z } from 'zod';

/**
 * ISO 3166-1 alpha-2
 * /[A-Z]{2}/
 */
export type CountryCode = string;
export const zCountryCode = z.string().regex(/^[A-Z]{2}$/).transform(value => value as CountryCode);

export type TimezoneCode = string;
export const zTimezoneCode = z.string().transform(value => value as TimezoneCode);

export type LocaleType = 'base' | 'invoice';

export const SUPPORTED_LOCALES = {
    base: [
        'cs-CZ',
        'en-US',
    ],
    invoice: [
        'cs-CZ',
        'en-US',
        'de-DE',
        'es-ES',
    ],
} as const;

export type LocaleCode<TType extends LocaleType = 'base'> = typeof SUPPORTED_LOCALES[TType][number];
export const zLocaleCode = z.enum(SUPPORTED_LOCALES.base);
export const zInvoiceLocaleCode = z.enum(SUPPORTED_LOCALES.invoice);

export function getI18nLocale(i18n: i18n): LocaleCode {
    return i18n.language as LocaleCode;
}

// Complex translations - when the translation is too complicated to be created equally for each locale.

type SupportedLocales = typeof SUPPORTED_LOCALES.base[number];

export type LocaleTFunction<TData> = (t: TFunction, data: TData) => string;
type LocaleTDefinition = Record<string, LocaleTFunction<any>>;

export type ComplexTDefinition<T> = Record<SupportedLocales, T>;
type ComplexTData<TDef extends LocaleTDefinition, TKey extends keyof TDef> = TDef[TKey] extends LocaleTFunction<infer TData> ? TData : never;
export type ComplexTFunction<TDef extends LocaleTDefinition> = <TKey extends keyof TDef>(key: TKey, data: ComplexTData<TDef, TKey>) => string;

export function complexTranslation<TDef extends LocaleTDefinition>(
    definition: ComplexTDefinition<TDef>,
    i18n: i18n,
    namespace: string,
    kexPrefix: string,
): { t: TFunction, ct: ComplexTFunction<TDef>} {
    const t = i18n.getFixedT(i18n.language, namespace, kexPrefix);
    return {
        t,
        ct: (key, data) => definition[i18n.language as SupportedLocales][key](t, data),
    };
}

export const FORMATS = {
    date: 'dd.MM.yyyy',
    time: 'HH:mm',
    dateTime: 'dd.MM.yyyy HH:mm',
};
