import { type Dispatch, useCallback, useMemo } from 'react';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { MoneyDisplay, OpenCloseChevronButton, Skeleton, Table } from ':frontend/components/common';
import { getSkeletonArray } from ':frontend/utils/math';
import { useTranslation } from 'react-i18next';
import { type InvoicingClient, useToggle, type EventItem, type UseBackpayDispatch, useInvoiceBackpay, useCached } from ':frontend/hooks';
import DateTimeDisplay from ':frontend/components/common/DateTimeDisplay';
import { ShoppingBagIcon } from ':components/icons';
import { type Id } from ':utils/id';
import EventStateBadge from ':frontend/components/event/EventStateBadge';
import { emptyFunction } from ':frontend/utils/common';
import ClientIconLink from ':frontend/components/client/ClientIconLink';
import FilterRow, { useFilters, useFiltersApply, type FilterFunction } from ':frontend/components/common/filters/FilterRow';
import EventStateFilter, { filterName as eventFiltersName } from ':frontend/components/common/filters/EventStateFilter';
import createEventClientFilter, { filterName as clientFilterName } from ':frontend/components/common/filters/ClientFilter';
import clsx from 'clsx';
import { type EventFE } from ':frontend/types/Event';
import { InfoCard, useInfoCardState } from ':frontend/components/settings/InfoCard';
import { type EventCheckoutInput } from ':frontend/components/orders/checkout/useCheckout';
import { Checkout, type CheckoutOutput } from ':frontend/components/orders/checkout/Checkout';
import { routesFE } from ':utils/routes';
import { useNavigate } from 'react-router-dom';

export default function NewBackpayOrder() {
    const { t } = useTranslation('pages', { keyPrefix: 'invoiceBackpay' });
    const navigate = useNavigate();

    const { state: { invoicingClients, clients, checkoutInput }, selectedItems, preselected, dispatch } = useInvoiceBackpay();
    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            dispatch({ type: 'checkoutEnd' });
            break;
        case 'finish':
            navigate(routesFE.orders.list);
            break;
        }
    }, [ dispatch, navigate ]);

    const filters = useMemo(() => [
        EventStateFilter,
        createEventClientFilter(clients ?? []),
    ], [ clients ]);

    const filtersControl = useFilters(filters);
    const applyEvents = useFiltersApply(filtersControl, eventFiltersName);
    const applyClients = useFiltersApply(filtersControl, clientFilterName);
    const filteredInvoicingClients = useMemo(() => invoicingClients?.filter(client => applyClients(client.client)), [ invoicingClients, applyClients ]);

    const isInvoicing = selectedItems !== 0;
    const isInfoCardClosed = useInfoCardState('backpay').cardState.closed;

    return (<>
        <CheckoutModal
            input={checkoutInput}
            output={checkoutOutput}
        />
        <div className='d-flex flex-column h-100'>
            <div className='container-large'>
                <Row className={clsx(!isInvoicing && 'my-3')} style={isInvoicing ? { height: '0px', opacity: '0' } : { height: '42px' }}>
                    <Col>
                        <h1 className='m-0'>{t('page-title')}</h1>
                    </Col>
                    <Col xs='auto' className='d-flex align-items-center justify-content-center fs-6'>
                        {isInfoCardClosed && t('start-invoicing-text')}
                    </Col>
                    <Col />
                </Row>
                {isInvoicing && (
                    <div className='my-3 ps-3 pe-2 d-flex align-items-center justify-content-between rounded rounded-2 border border-primary bg-white' style={{ height: '42px' }}>
                        <span className='fw-semibold monospace-numbers'>
                            {t('selected-items-label', { count: selectedItems })}
                        </span>
                        <div className='d-flex align-items-center gap-2'>
                            <Button onClick={() => dispatch({ type: 'checkoutStart' })} className='compact me-2'>
                                <ShoppingBagIcon size={18} className='me-2' />{t('open-cart-button')}
                            </Button>
                            <Button onClick={() => dispatch({ type: 'reset' })} className='compact' variant='outline-danger'>
                                {t('cancel-button')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <InfoCard infoKey='backpay' className='mb-4' />
            {/* The marginTop value is very carefully chosen so that the filter row and the top of the table is in the same height as on other pages. */}
            <div className='container-large mb-3' style={{ marginTop: '16.8px' }}>
                <FilterRow control={filtersControl} />
            </div>
            <div className='fl-main-scroller pb-5'>
                <Table className='container-large'>
                    <Table.Header>
                        <Table.HeaderCol xs='auto' />
                        <Table.HeaderCol>{t('client-label')}</Table.HeaderCol>
                        <Table.HeaderCol className='text-end'>{t('events-label')}</Table.HeaderCol>
                        <Table.HeaderCol>{t('email-label')}</Table.HeaderCol>
                        <Table.HeaderCol xs='auto' />
                    </Table.Header>
                    <Table.Body>
                        <InvoicingClientsList
                            invoicingClients={filteredInvoicingClients}
                            dispatch={dispatch}
                            preselectedClientId={preselected?.clientId}
                            applyEvents={applyEvents}
                        />
                    </Table.Body>
                </Table>
            </div>
        </div>
    </>);
}

type InvoicingClientsListProps = Readonly<{
    invoicingClients?: InvoicingClient[];
    preselectedClientId?: Id;
    dispatch: UseBackpayDispatch;
    applyEvents: FilterFunction<EventFE>;
}>;

function InvoicingClientsList({ invoicingClients, dispatch, preselectedClientId, applyEvents }: InvoicingClientsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoiceBackpay' });
    if (!invoicingClients)
        return (<>{getSkeletonArray().map(id => <InvoicingClientSkeleton key={id} />)}</>);

    if (invoicingClients.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={5} className='text-center fs-4 py-5'>
                    {t('no-invoicing-clients-yet-text')}
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {invoicingClients.map(invoicingClient => (
            <InvoicingClientRow
                key={invoicingClient.client.id}
                invoicingClient={invoicingClient}
                dispatch={dispatch}
                isPreselected={invoicingClient.client.id === preselectedClientId}
                applyEvents={applyEvents}
            />
        ))}
        <Table.Row className='fl-hide-if-not-first-row'>
            <Table.Col colSpan={5} className='text-center fs-4 py-5'>
                {t('no-invoicing-clients-text')}
            </Table.Col>
        </Table.Row>
    </>);
}

type InvoicingClientRowProps = Readonly<{
    invoicingClient: InvoicingClient;
    dispatch: UseBackpayDispatch;
    isPreselected: boolean;
    applyEvents: FilterFunction<EventFE>;
}>;

function InvoicingClientRow({ invoicingClient: { client, items }, dispatch, isPreselected, applyEvents }: InvoicingClientRowProps) {
    const { visibleItems, selectedItems, selectedVisibleItems } = useMemo(() => {
        const visibleItems = items.filter(item => applyEvents(item.event));
        return {
            visibleItems,
            selectedItems: items.filter(i => i.isSelected),
            selectedVisibleItems: visibleItems.filter(i => i.isSelected),
        };
    }, [ items, applyEvents ]);

    const [ isExpanded, setIsExpanded ] = useToggle(isPreselected || selectedVisibleItems.length > 0);
    const isNotAllSelected = selectedVisibleItems.length !== visibleItems.length;

    if (visibleItems.length === 0)
        return null;

    return (<>
        {/* The doulble toggle is intentional - the second one negates the first one so there is an 'unclickable zone' around the checkbox. */}
        <Table.Row className='clickable hoverable' onClick={setIsExpanded.toggle}>
            <Table.Col className='non-clickable hoverable-exception' onClick={setIsExpanded.toggle}>
                <Form.Check
                    checked={selectedVisibleItems.length > 0}
                    onChange={e => dispatch({ type: 'select', items: visibleItems, isSelected: e.target.checked })}
                    className={isNotAllSelected ? 'fl-checkbox-dash' : ''}
                />
            </Table.Col>
            <Table.Col truncate>
                <ClientIconLink client={client} className='hoverable-exception' />
            </Table.Col>
            <Table.Col xs='auto' className='fw-medium monospace-numbers text-end text-nowrap'>
                <span className='text-white bg-primary rounded-1' style={{ padding: '2px 4px' }}>{selectedItems.length}</span>
                <span>{` / ${items.length}`}</span>
            </Table.Col>
            <Table.Col truncate>{client.email}</Table.Col>
            <Table.Col xs='auto'>
                <OpenCloseChevronButton opened={isExpanded} onClick={emptyFunction} />
            </Table.Col>
        </Table.Row>
        {isExpanded && (
            <Table.Row>
                <Table.Col colSpan={5} className='p-0'>
                    {visibleItems.map(item => (
                        <ParticipantRow key={item.event.id} item={item} dispatch={dispatch} />
                    ))}
                </Table.Col>
            </Table.Row>
        )}
    </>);
}

type ParticipantRowProps = Readonly<{
    item: EventItem;
    dispatch: UseBackpayDispatch;
}>;

function ParticipantRow({ item, dispatch }: ParticipantRowProps) {
    const { event, participant, isSelected } = item;

    return (
        <Row className='align-items-center'>
            <Col xs='auto' className='pe-3'>
                <div className='ps-3'>
                    <Form.Check checked={isSelected} onChange={e => dispatch({ type: 'select', item, isSelected: e.target.checked })} />
                </div>
            </Col>
            <Col xs='auto' className='py-3'>
                <DateTimeDisplay dateTime={event.start} date />
            </Col>
            <Col xs='auto'>
                <DateTimeDisplay dateTime={event.start} time />
                {' - '}
                <DateTimeDisplay dateTime={event.end} time />
            </Col>
            {/* <Col className='d-flex align-items-center text-truncate fw-medium'>
                <CalendarIcon calendar={calendar} className='me-2' />
                <div className='text-truncate'>{event.displayTitle}</div>
            </Col> */}
            <Col className='text-truncate fw-medium'>
                {event.displayTitle}
            </Col>
            <Col xs={2}>
                <EventStateBadge event={item.event}/>
            </Col>
            <Col xs={1}>
                <MoneyDisplay money={participant.payment.price} />
            </Col>
        </Row>
    );
}

function InvoicingClientSkeleton() {
    return (
        <Table.Row>
            <Table.Col>
                <Form.Check readOnly />
            </Table.Col>
            <Table.Col><Skeleton height={20} /></Table.Col>
            <Table.Col><Skeleton height={20} /></Table.Col>
            <Table.Col><Skeleton height={20} /></Table.Col>
            <Table.Col>
                <OpenCloseChevronButton opened={false} onClick={emptyFunction} />
            </Table.Col>
        </Table.Row>
    );
}

type CheckoutModalProps = Readonly<{
    input?: EventCheckoutInput;
    output: Dispatch<CheckoutOutput>;
}>;

function CheckoutModal({ input, output }: CheckoutModalProps) {
    const cachedInput = useCached(input);
    if (!cachedInput)
        return null;

    return (
        <Modal
            show={!!input}
            className='fl-checkout-modal'
        >
            <Modal.Body className='p-0'>
                <Checkout
                    input={cachedInput}
                    output={output}
                />
            </Modal.Body>
        </Modal>
    );
}
