import { useMemo } from 'react';
import { MoneyDisplay } from '../common';
import { Button } from 'react-bootstrap';
import { CheckIcon } from ':components/icons';
import { useTranslation } from 'react-i18next';
import { participantIsBillable, type EditablePayingParticipant } from ':frontend/types/EventParticipant';
import { type Money, type CurrencyFE, addVatAsNumber, type TaxRateFE } from ':frontend/modules/money';
import type { UseEventState } from './useEvent';
import { toNumber } from ':frontend/utils/math';

type PriceSummary = {
    vatBreakdown: VatSummary;
    isOnlyZeroVat: boolean;
};

type VatSummary = {
    vat: TaxRateFE;
    withoutVat: Money;
    withVat: Money;
    difference: Money;
};

export function computePriceSummary(clients: EditablePayingParticipant[], totalEvents: number): PriceSummary | undefined {
    if (clients.length === 0)
        return undefined;

    const currency = clients[0].currency;
    const vat = clients[0].vat;

    let totalWithoutVat = 0;
    let totalWithVat = 0;
    let totalDifference = 0;
    clients.forEach(client => {
        const { withoutVat, withVat, difference } = addVatAsNumber(toNumber(client.price), vat);
        totalWithoutVat += withoutVat;
        totalWithVat += withVat;
        totalDifference += difference;
    });

    return {
        vatBreakdown: {
            vat,
            withoutVat: { amount: totalWithoutVat * totalEvents, currency },
            withVat: { amount: totalWithVat * totalEvents, currency },
            difference: { amount: totalDifference * totalEvents, currency },
        },
        isOnlyZeroVat: vat.isZero,
    };
}

type OrderSummaryProps = Readonly<{
    state: UseEventState;
}>;

export function OrderSummary({ state }: OrderSummaryProps) {
    const payment = state.payment!;

    const summary = useMemo(() => computeOrderPriceSummary(payment.clients), [ payment.clients ]);
    if (!summary)
        return null;

    return (
        <div className='d-flex align-items-center w-100' style={{ height: '42px' }}>
            {summary.isAllPaid ? (
                <AllPaid total={summary.total} />
            ) : (
                <SomeInOrder total={summary.total} paid={summary.paid} isBillable={summary.isBillable} />
            )}
        </div>
    );
}

function computeOrderPriceSummary(clients: EditablePayingParticipant[]) {
    if (clients.length === 0)
        return undefined;

    const currency = clients[0].currency;
    const paidClients = clients.filter(c  => c.original?.isPaid);
    const billableClients = clients.filter(c => !c.original || participantIsBillable(c.original));

    return {
        isBillable: billableClients.length > 0,
        isAllPaid: paidClients.length === clients.length,
        paid: sumWithVat(paidClients, currency),
        total: sumWithVat(clients, currency),
    };
}

function sumWithVat(clients: EditablePayingParticipant[], currency: CurrencyFE) {
    const amount = clients.reduce((ans, p) => ans + addVatAsNumber(toNumber(p.price), p.vat).withVat, 0);
    return { amount, currency };
}

type AllPaidProps = Readonly<{
    total: Money;
}>;

function AllPaid({ total }: AllPaidProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment.orderSummary' });

    return (<>
        <div className='flex-grow-1' />
        <div className='fw-semibold fs-6 me-3'>
            <span className='me-2'>{t('total-label')}</span>
            <MoneyDisplay className='fw-semibold' money={total} />
        </div>
        <Button variant='outline-success' className='readonly'>
            <CheckIcon size={18} className='me-2' />
            {t('all-paid-button')}
        </Button>
    </>);
}

type SomeInOrderProps = Readonly<{
    total: Money;
    paid: Money;
    isBillable: boolean;
}>;

function SomeInOrder({ total, paid, isBillable }: SomeInOrderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment.orderSummary' });

    return (<>
        <div className='flex-grow-1' />
        <div className='me-3 text-end'>
            <div className='fw-medium text-secondary'>
                <span className='me-2'>{t('paid-label')}</span>
                <MoneyDisplay money={paid} />
            </div>
            <div className='fw-semibold fs-6'>
                <span className='me-2'>{t('total-label')}</span>
                <MoneyDisplay className='fw-semibold' money={total} />
            </div>
        </div>
        {!isBillable && (
            <Button variant='outline-warning' className='readonly'>
                {t('all-invoiced-button')}
            </Button>
        )}
    </>);
}
