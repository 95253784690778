import superjson from 'superjson';
import { DateTime } from 'luxon';

/**
 * Registers Luxon DateTime serialization.
 * Warning: During the deserialization, all timezones are converted to UTC. It's probably because Luxon doesn't know what timezone +02:00 is (because there are many candidates).
 */
export function setupSuperjson() {
    superjson.registerCustom({
        isApplicable: (v): v is DateTime => DateTime.isDateTime(v),
        serialize: v => v.toISO(),
        deserialize: v => DateTime.fromISO(v),
    }, 'datetime');
}
