import { roundMoney } from ':frontend/utils/math';
import { type Money, type TaxRateFE } from './types';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type Id } from ':utils/id';
import type { CountryCode } from ':utils/i18n';

const ALL_TAX_RATES_MAP: Map<Id, TaxRateFE> = new Map;

export function getAllTaxRatesForCountries(countries: CountryCode[]): TaxRateFE[] {
    const output = [ getDefaultTaxRate() ];
    countries.forEach(country => getAllTaxRatesForOneCountryOnly(country).forEach(taxRate => output.push(taxRate)));

    return output;
}

function getAllTaxRatesForOneCountryOnly(country: CountryCode): TaxRateFE[] {
    return [ ...ALL_TAX_RATES_MAP.values() ].filter(taxRate => taxRate.country === country).sort((a, b) => a.value - b.value);
}

export function addTaxRate(taxRate: TaxRateFE) {
    if (ALL_TAX_RATES_MAP.has(taxRate.id))
        return;

    ALL_TAX_RATES_MAP.set(taxRate.id, taxRate);
}

export function getTaxRate(id: Id): TaxRateFE {
    const taxRate = ALL_TAX_RATES_MAP.get(id);
    if (!taxRate)
        throw new Error(`Tax rate with id ${id} not found.`);

    return taxRate;
}

/**
 * The zero tax rate is supposed to be common for all countries so it is a natural fit for a default tax rate.
 */
let zeroTaxRate: TaxRateFE | undefined = undefined;

export function getDefaultTaxRate(): TaxRateFE {
    if (!zeroTaxRate) {
        const foundTaxRate = [ ...ALL_TAX_RATES_MAP.values() ].find(taxRate => !taxRate.country);
        if (!foundTaxRate)
            throw new Error('Default tax rate not found');

        zeroTaxRate = foundTaxRate;
    }

    return zeroTaxRate;
}

export function findTaxRate(profiles: InvoicingProfileFE[], client?: ClientInfoFE): TaxRateFE {
    if (!profiles || profiles.length === 0)
        return getDefaultTaxRate();

    if (client) {
        const profile = profiles.find(p => p.id === client.invoicingProfileId);
        if (profile)
            return profile.vat;
    }

    return profiles[0].vat;
}

export function addVatAsNumber(amount: number, vat: TaxRateFE): { withoutVat: number, withVat: number, difference: number } {
    if (vat.isInclusive) {
        const withoutVat = roundMoney(amount / (1 + vat.value));
        const withVat = roundMoney(amount);
        const difference = roundMoney(withVat - withoutVat);

        return { withoutVat, withVat, difference };
    }
    else {
        const withoutVat = roundMoney(amount);
        const withVat = roundMoney(amount * (1 + vat.value));
        const difference = roundMoney(withVat - withoutVat);

        return { withoutVat, withVat, difference };
    }
}

export function addVat(base: Money, vat: TaxRateFE): { withoutVat: Money, withVat: Money, difference: Money } {
    const numberResult = addVatAsNumber(base.amount, vat);

    return {
        withoutVat: {
            amount: numberResult.withoutVat,
            currency: base.currency,
        },
        withVat: {
            amount: numberResult.withVat,
            currency: base.currency,
        },
        difference: {
            amount: numberResult.difference,
            currency: base.currency,
        },
    };
}
