import { getEnumValues } from ':utils/common';
import { DateTime } from 'luxon';
import { z } from 'zod';

export enum Day {
    Monday = 'MO',
    Tuesday = 'TU',
    Wednesday = 'WE',
    Thursday = 'TH',
    Friday = 'FR',
    Saturday = 'SA',
    Sunday = 'SU',
}

export const WEEK_DAYS = Object.values(Day);
export const WORK_WEEK_DAYS = [ Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday ];

export function getWeekDay(date: DateTime): Day {
    return WEEK_DAYS[date.weekday - 1];
}

const WEEK_DAY_NUMBERS = generateWeekDayNumbers();

export function getWeekDayNumber(weekDay: Day): number {
    return WEEK_DAY_NUMBERS[weekDay];
}

function generateWeekDayNumbers(): { [key in Day]: number } {
    const output = {} as { [key in Day]: number };
    WEEK_DAYS.forEach((day, index) => output[day] = index + 1);
    return output;
}

/**
 * The days of the week to apply the recurrence to. Each day is preceded by a number, indicating a specific occurence within the interval.
 * E.g., 1MO (the first Monday of the interval), 3MO (the third Monday), -1MO (the last Monday).
 */
export type ByDay = {
    weekDay: Day;
    occurence: number;
};

export enum Frequency {
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
}

export const FREQUENCIES = getEnumValues(Frequency);

/**
 * This implementation is very incomplete.
 * For more information (on how it should look like), see RFC5545.
 * Also try this page: https://icalendar.org/rrule-tool.html
 */
export type Recurrence = {
    frequency: Frequency;
    // How often is the pattern repeated - 1 means each week, 2 means each two weeks etc.
    interval: number;
    count?: number;
    until?: DateTime;

    /**
     * The days of the month to apply the recurrence to, from 1 to 31 or -31 to -1.
     * E.g., 2 (the second day of the month), -1 (the last day of the month).
     * Currently, only one value is supported. Also, negative values are not supported.
     */
    byMonthDay?: number;

    // Normally, there should be a `byDay?: ByDay[]` property here, but it was split into two properties: byDay and byWeekDay.
    // The reason is that we support only a small subset of the standard.

    /**
     * Se the ByDay type for more information.
     * Used for monthly recurrences similarly to byMonthDay.
     */
    byDay?: ByDay;

    /**
     * The days of the week to apply the recurrence to.
     * Used for weekly recurrences.
     * E.g., [ MO, WE ] (each Monday and Wednesday of the selected weeks).
     */
    byWeekDay?: Day[];
};

export function stringifyRecurrence(recurrence: Recurrence): string {
    const parts: string[] = [];
    parts.push('FREQ=' + recurrence.frequency);

    if (recurrence.interval !== 1)
        parts.push('INTERVAL=' + recurrence.interval);

    if (recurrence.byMonthDay !== undefined)
        parts.push('BYMONTHDAY=' + recurrence.byMonthDay);
    else if (recurrence.byDay)
        parts.push('BYDAY=' + recurrence.byDay.occurence + recurrence.byDay.weekDay);
    else if (recurrence.byWeekDay)
        parts.push('BYDAY=' + recurrence.byWeekDay.join(','));

    if (recurrence.count !== undefined) {
        parts.push('COUNT=' + recurrence.count);
    }
    else if (recurrence.until) {
        // According to the RFC, the until time should be in UTC.
        const dateString = recurrence.until
            .set({ millisecond: 0 })
            .toUTC()
            .toISO({ format: 'basic', suppressMilliseconds: true });
        parts.push('UNTIL=' + dateString);
    }

    return parts.join(';');
}

// This covers only the subset of the standard that we use.
export function parseRecurrence(input: string): Recurrence {
    // This prefix is used in the iCalendar format (RFC 5545) for specifying multiple recurrence rules, so it might just be here.
    if (input.startsWith('RRULE:'))
        input = input.substring('RRULE:'.length);

    try {
        return innerParseRecurrence(input);
    }
    catch (error) {
        throw new Error('Invalid recurrence format: ' + error);
    }
}

/** Might throw exceptions. */
function innerParseRecurrence(input: string): Recurrence {
    const recurrence: Partial<Recurrence> = {
        interval: 1,
    };

    input.split(';')
        .map(raw => {
            const split = raw.split('=');
            if (split.length !== 2)
                throw new Error('Invalid part value.');

            // The format is kinda strict (no spaces allowed), so we don't have to use trimming or such.
            return { key: split[0], value: split[1] };
        })
        .forEach(({ key, value }) => {
            switch (key) {
            case 'FREQ':
                recurrence.frequency = z.nativeEnum(Frequency).parse(value);
                break;
            case 'INTERVAL':
                recurrence.interval = z.coerce.number().int().positive().parse(value);
                break;
            case 'COUNT':
                recurrence.count = z.coerce.number().int().positive().parse(value);
                break;
            case 'UNTIL':
                recurrence.until = DateTime.fromISO(value, { zone: 'UTC' });
                break;
            case 'BYMONTHDAY':
                recurrence.byMonthDay = z.coerce.number().int().min(1).max(31).parse(value);
                break;
            case 'BYDAY': {
                // First, we try to match byDay.
                const matches1 = RegExp(/^(-?[1-9])([A-Z]{2})$/).exec(value);
                if (matches1) {
                    recurrence.byDay = {
                        occurence: z.coerce.number().int().parse(matches1[1]),
                        weekDay: z.nativeEnum(Day).parse(matches1[2]),
                    };
                    break;
                }

                // Then, we try to match byWeekDay.
                const matches2 = RegExp(/^[A-Z]{2}(,[A-Z]{2})*$/).exec(value);
                if (matches2) {
                    recurrence.byWeekDay = value.split(',').map(day => z.nativeEnum(Day).parse(day));
                    break;
                }

                throw new Error('Invalid BYDAY value.');
            }
            default:
                throw new Error('Invalid key.');
            }
        });

    if (!recurrence.frequency)
        throw new Error('Missing frequency.');

    return recurrence as Recurrence;
}

export function createDefaultRecurrence(date: DateTime, count?: number): Recurrence {
    return {
        frequency: Frequency.Weekly,
        interval: 1,
        byWeekDay: [ getWeekDay(date) ],
        count: count ?? 12, // TODO remove when unlimited recurrence is supported.
    };
}

export enum PredefinedOption {
    /** Every day. */
    Daily = 'daily',
    /** Every weekday. */
    Weekdaily = 'weekdaily',
    /** E.g., every Monday. */
    Weekly = 'weekly',
    /** E.g., every other Monday. */
    TwoWeekly = 'twoWeekly',
    /** E.g., each month on the 12th. */
    MonthlyByDay = 'monthlyByMonthDay',
    /** E.g., each month on the 2nd Monday. */
    MonthlyFromStart = 'monthlyByWeekDayStart',
    /** E.g., each month on the last Friday. */
    MonthlyFromEnd = 'monthlyByWeekDayEnd',
    Custom = 'custom',
    Never = 'never',
}
