import { Button } from 'react-bootstrap';
import { ChevronUpIcon } from ':components/icons';
import clsx from 'clsx';

type CloseChevronButtonProps = {
    disabled?: boolean;
    className?: string;
    onClick: () => void;
};

export default function CloseChevronButton({ disabled, className, onClick }: CloseChevronButtonProps) {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            className={clsx(className, 'chevron-button')}
            variant='light'
        >
            <ChevronUpIcon size={18} />
        </Button>
    );
}
