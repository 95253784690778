import { SortOrder } from ':utils/common';
import { useMemo, useState, type Dispatch, type SetStateAction } from 'react';

type UseSortOrderSet = {
    value: Dispatch<SetStateAction<SortOrder | undefined>>;
    ascending: () => void;
    descending: () => void;
    toggle: () => void;
};

export function useSortOrder(initialValue: SortOrder | (() => SortOrder)): [ SortOrder, UseSortOrderSet ]
export function useSortOrder(): [ SortOrder | undefined, UseSortOrderSet ];

export function useSortOrder(initialValue?: SortOrder | (() => SortOrder)) {
    const [ value, setValue ] = useState(initialValue);

    const generalSetValue = useMemo(() => ({
        value: setValue,
        ascending: () => setValue(SortOrder.Ascending),
        descending: () => setValue(SortOrder.Descending),
        // The default value (undefined) is ascending.
        toggle: () => setValue(oldValue => (oldValue ?? SortOrder.Ascending) === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending),
    }), []);

    return [ value, generalSetValue ];
}
