import findIndex from 'lodash/findIndex';
import { localizer } from '..';

export function endOfRange({ dateRange, unit = 'day' }) {
    return {
        first: dateRange[0],
        last: localizer.add(dateRange[dateRange.length - 1], 1, unit),
    };
}

// Properly calculating segments requires working with dates in the timezone we're working with, so we use the localizer.
export function eventSegments(event, range) {
    const { first, last } = endOfRange({ dateRange: range });

    const slots = localizer.diff(first, last, 'day');
    const start = localizer.max(
        localizer.startOf(event.start, 'day'),
        first,
    );
    const end = localizer.min(localizer.ceil(event.end, 'day'), last);

    const padding = findIndex(range, (x) => localizer.isSameDate(x, start));

    let span = localizer.diff(start, end, 'day');
    span = Math.min(span, slots);
    // The segmentOffset is necessary when adjusting for timezones ahead of the browser timezone.
    span = Math.max(span - localizer.segmentOffset, 1);

    return {
        event,
        span,
        left: padding + 1,
        right: Math.max(padding + span, 1),
    };
}

/**
 * If enablePlaceholders is true, an placeholder segment will be generated for each multi-day event that would be displayed first in it's days.
 * The reason is that in the month view, the date numbers are displayed in the top right corner. Single-day events fit neatly next to them but the multi-day ones would hide several of those numbers.
 */
export function eventLevels(rowSegments, limit = Infinity, enablePlaceholders = false) {
    const levels = [];
    const extra = [];

    for (const segment of rowSegments) {
        let j = 0;
        if (enablePlaceholders && segment.span > 1) {
            levels[0] = levels[0] || [];
            if (!segmentsOverlap(segment, levels[0]))
                levels[0].push({ ...segment, isPlaceholder: true });

            j = 1;
        }

        while (j < levels.length && segmentsOverlap(segment, levels[j])) 
            j++;

        if (j >= limit) 
            extra.push(segment);
        else 
            (levels[j] || (levels[j] = [])).push(segment);
    }

    for (const level of levels) 
        level.sort((a, b) => a.left - b.left) //eslint-disable-line

    return { levels, extra };
}

function segmentsOverlap(segment, otherSegments) {
    return otherSegments.some(s => s.left <= segment.right && s.right >= segment.left);
}
