import { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getPersonName } from ':frontend/types/Person';
import { useUser } from ':frontend/context/UserProvider';
import { type TeamMemberFE } from ':frontend/types/Team';
import localStorage from ':frontend/utils/localStorage';

// For the team members, we store a set of disabled member ids. That's different from the google calendars, where we store a set of enabled calendar ids.
// The reason is that for google, we want to first fetch the events of enabled calendars, so we need to know their ids. Only after that we fetch all calendars and, if needed, update the enabled calendars.

type TeamMemberCalendarsFilterProps = Readonly<{
    disabledIds: Set<string>;
    toggleMember: (member: TeamMemberFE, newValue: boolean) => void;
}>;

export function TeamMemberCalendarsFilter({ disabledIds, toggleMember }: TeamMemberCalendarsFilterProps) {
    const { teamMembers } = useUser();

    return (
        <div className='d-flex flex-column gap-2'>
            {teamMembers.all.map(member => {
                const memberId = member.id.toString();
                const isEnabled = !disabledIds.has(memberId);

                return (
                    <div key={memberId} className='fl-design'>
                        <Form.Check>
                            <Form.Check.Input
                                checked={isEnabled}
                                onChange={event => toggleMember(member, event.target.checked)}
                                style={{ '--fl-base-color': '#' + member.color }}
                                id={`check-${member.id}`}
                            />
                            <Form.Check.Label htmlFor={`check-${member.id}`}>{getPersonName(member)}</Form.Check.Label>
                        </Form.Check>
                    </div>
                );
            })}
        </div>
    );
}


type UseTeamMemberCalendarsFilterReturn = {
    disabledIds: Set<string>;
    toggleMember: (member: TeamMemberFE, newValue: boolean) => void;
}

export function useTeamMemberCalendarsFilter(): UseTeamMemberCalendarsFilterReturn {
    const [ disabledIds, setDisabledIds ] = useState(() => getDisabledMemberIds());

    const toggleMember = useCallback((member: TeamMemberFE, newValue: boolean) => {
        setDisabledIds(updateDisabledId(member.id.toString(), newValue));
    }, []);

    return {
        disabledIds,
        toggleMember,
    };
}

const FILTER_KEY = 'team_members_filter';

type MemberFilter = string[];

function updateDisabledId(id: string, idValue: boolean): (oldValue: Set<string>) => Set<string> {
    return (oldValue: Set<string>) => {
        const newValue = computeNewDisabledIds(oldValue, id, idValue);
        if (oldValue !== newValue)
            cacheDisabledId(id, idValue);

        return newValue;
    };
}

function computeNewDisabledIds(oldValue: Set<string>, id: string, idValue: boolean): Set<string> {
    if (!idValue) {
        if (oldValue.has(id))
            return oldValue;

        const newValue = new Set(oldValue);
        newValue.add(id);

        return newValue;
    }

    if (!oldValue.has(id))
        return oldValue;

    const newValue = new Set(oldValue);
    newValue.delete(id);

    return newValue;
}

function cacheDisabledId(id: string, idValue: boolean) {
    const filter = localStorage.get<MemberFilter>(FILTER_KEY) ?? [];
    const newFilter = idValue
        ? filter.filter(disabledId => disabledId !== id)
        : (filter.includes(id) ? filter : [ ...filter, id ]);

    localStorage.set(FILTER_KEY, newFilter);
}

function getDisabledMemberIds(): Set<string> {
    const filter = localStorage.get<MemberFilter>(FILTER_KEY) ?? [];
    return new Set(filter);
}
