import { type Money, type TaxRateFE, priceToServer } from ':frontend/modules/money';
import { type Id } from ':utils/id';
import { toNumber } from ':frontend/utils/math';
import type { OrderItemFE } from './OrderItem';
import { type DateTime } from 'luxon';
import type { OrderItemEdit } from ':utils/entity/orderItem';

export class CustomOrderItemFE implements OrderItemFE {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly quantity: number,
        readonly unitPrice: Money,
        readonly vat: TaxRateFE,
        readonly index: number,
        readonly createdAt: DateTime,
    ) {}

    static fromServer(base: OrderItemFE): CustomOrderItemFE {
        return new CustomOrderItemFE(
            base.id,
            base.title,
            base.quantity,
            base.unitPrice,
            base.vat,
            base.index,
            base.createdAt,
        );
    }
}

export function basicItemUpdateToServer(input: EditableBasicItem): OrderItemEdit {
    return {
        id: input.id,
        title: input.title,
        quantity: toNumber(input.quantity),
        unitPrice: priceToServer(toNumber(input.unitPrice)),
        vat: input.vat.id,
        isDeleted: input.isDeleted ? true : undefined,
    };
}

export type EditableBasicItem = {
    readonly id: string;
    title: string;
    quantity: number | '';
    unitPrice: number | '' | '-';
    vat: TaxRateFE;
    isDeleted: boolean;
};

export function isBasicItemEqual(form: EditableBasicItem, item: OrderItemFE): boolean {
    return !form.isDeleted
        && form.title === item.title
        && form.quantity === item.quantity
        && form.unitPrice === item.unitPrice.amount
        && form.vat === item.vat;
}
