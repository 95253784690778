import { useCallback,  useEffect,  useMemo, useState, type ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EventFE } from ':frontend/types/Event';
import { createActionState, useClients, useLocations } from ':frontend/hooks';
import type { PreselectEvent, Selected } from '../CalendarDetail';
import { routesFE } from ':utils/routes';
import { EventDisplayInner } from ':frontend/components/calendar/EventSidebar';
import { useEvent, type UpdateEventsAction } from ':frontend/components/event/useEvent';
import { Container } from 'react-bootstrap';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type BaseLocationFE } from ':frontend/types/location';
import { eventToCalendarEvent } from ':frontend/types/calendar/Calendar';
import clsx from 'clsx';
import { trpc } from ':frontend/context/TrpcProvider';
import { useUser } from ':frontend/context/UserProvider';

// TODO this component doesn't work properly yet. The event can't be saved and we are still waiting for a new design.
// Therefore, all event-detail requests should be redirected to the calendar for now.

export default function EventDetail() {
    const { id } = useParams();
    const [ event, setEvent ] = useState<EventFE>();
    const { clients, addClients } = useClients();
    const { locations, addLocation } = useLocations();
    const { teamMembers } = useUser();

    const eventOutput = trpc.event.getEvent.useQuery({ id: id! });

    useEffect(() => {
        if (eventOutput.data)
            setEvent(EventFE.fromServer(eventOutput.data));
    }, [ eventOutput.data ]);

    const navigate = useNavigate();

    const updateEvents = useCallback((action?: UpdateEventsAction) => {
        if (!action)
            return;

        const newEvent = action.events.find(e => e.id === id);
        if (!newEvent)
            return;

        if (action.type === 'delete')
            navigate(routesFE.calendar);

        setEvent(newEvent);
    }, [ id, navigate ]);

    const input = useMemo(() => event ? { event: eventToCalendarEvent(event, teamMembers) } : undefined, [ event, teamMembers ]);

    if (!input || !clients || !locations)
        return null;

    return (
        <EventDetailInner
            input={input}
            onEventsUpdate={updateEvents}
            clients={clients}
            addClients={addClients}
            locations={locations}
            addLocation={addLocation}
        />
    );
}

type EventDetailInnerProps = Readonly<{
    input: Selected;
    onEventsUpdate: (action?: UpdateEventsAction) => void;
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE[]) => void;
    locations: BaseLocationFE[];
    addLocation: (location: BaseLocationFE) => void;
}>;

function EventDetailInner({ input, onEventsUpdate, addClients, ...rest }: EventDetailInnerProps) {
    const { state, dispatch } = useEvent(input, onEventsUpdate, addClients);

    return (
        <Container className='content-large d-flex flex-column'>
            <EventDisplayInner
                state={state}
                dispatch={dispatch}
                {...rest}
            />
        </Container>
    );
}

type EventLinkProps = Readonly<{
    event: EventFE;
    className?: string;
    children: ReactNode;
}>;

// export function EventDetailLink({ event, className, children }: EventLinkProps) {
export function EventDetailLink(props: EventLinkProps) {
    // TODO temporary fix until we redesign the detail.
    return EventInCalendarLink(props);

    // return (
    //     // TODO temporary fix until we can transfer state via Link (probably by url)
    //     // <Link to={routes.calendar} state={createActionState<PreselectEvent>('preselectEvent', event.id)} className={className}>
    //     <Link to={routes.events.detail.resolve({ id: event.id })} className={className}>
    //         {children}
    //     </Link>
    // );
}

export function EventInCalendarLink({ event, className, children }: EventLinkProps) {
    const navigate = useNavigate();

    return (
        // TODO temporary fix until we can transfer state via Link (probably by url)
        // <Link to={routes.calendar} state={createActionState<PreselectEvent>('preselectEvent', event.id)} className={className}>
        <div
            onClick={() => navigate(routesFE.calendar, { state: createActionState<PreselectEvent>('preselectEvent', event.id) })}
            className={clsx(className, 'todo-link-fix text-truncate clickable')}
        >
            {children}
        </div>
    );
}
