import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import type { Id } from ':utils/id';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert } from '../notifications';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';

type InvoicingProfileExampleModalProps = Readonly<{
    profile?: InvoicingProfileFE;
    onHide: () => void;
}>;

export function InvoicingProfileExampleModal({ profile, onHide }: InvoicingProfileExampleModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });
    const [ pdfBlobUrl, setPdfBlobUrl ] = useState('');
    const { addAlert } = useNotifications();

    const profileCache = useRef<InvoicingProfileFE>();

    const fetchInvoicingProfileExample = useCallback(async (id: Id, signal: AbortSignal) => {
        setPdfBlobUrl('');
        const response = await api.backend.getInvoicingProfileExample(id, signal);
        if (!response.status) {
            profileCache.current = undefined;
            addAlert(createTranslatedErrorAlert());
            return;
        }

        const blobUrl = URL.createObjectURL(response.data);
        setPdfBlobUrl(blobUrl);
    }, [ addAlert ]);

    useEffect(() => {
        if (!profile || profile === profileCache.current)
            return;

        profileCache.current = profile;

        const [ signal, abort ] = api.prepareAbort();
        fetchInvoicingProfileExample(profile.id, signal);
        return abort;
    }, [ profile, fetchInvoicingProfileExample ]);

    return (
        <Modal show={!!profile} onHide={onHide} size='xl' contentClassName='h-100' dialogClassName='h-85'>
            <Modal.Header closeButton>
                <Modal.Title>{t('example-modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfBlobUrl ? (
                    <iframe className='w-100 h-100' src={pdfBlobUrl} />
                ) : (
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <Spinner className='mx-auto' animation='border' />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
}
