import { useCallback, useEffect, useState } from 'react';
import type { DefaultValue } from './utils';
import { ProductFE } from ':frontend/types/Product';
import { trpc } from ':frontend/context/TrpcProvider';

export function useProducts(options: { default: DefaultValue<ProductFE[]> }): {
    products: ProductFE[];
    updateProducts: (product: ProductFE, isDeleted?: boolean) => void;
}

export function useProducts(): {
    products: ProductFE[] | undefined;
    updateProducts: (product: ProductFE, isDeleted?: boolean) => void;
};

export function useProducts(options?: { default?: DefaultValue<ProductFE[]> }) {
    const [ products, setProducts ] = useState(options?.default);

    const productQuery = trpc.product.getProducts.useQuery();

    useEffect(() => {
        setProducts(productQuery.data?.map((p) => (ProductFE.fromServer(p)) ?? []));

    }, [ productQuery.data ]);

    const updateProducts = useCallback((product: ProductFE, isDeleted?: boolean) => {
        setProducts(products => {
            if (isDeleted)
                return products?.filter(p => p.id !== product.id);

            if (!products)
                return [ product ];

            const newProducts = [ ...products ];
            const index = newProducts.findIndex(p => p.id === product.id);
            if (index === -1)
                newProducts.push(product);
            else
                newProducts[index] = product;

            return newProducts;
        });
    }, []);

    return {
        products,
        updateProducts,
    };
}
