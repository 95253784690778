import { z } from 'zod';
import { zCountryCode } from ':utils/i18n';
import { zId } from ':utils/id';

export type AddressUpsert = z.infer<typeof zAddressUpsert>;
export const zAddressUpsert = z.object({
    city: z.string().optional(),
    country: zCountryCode.optional(),
    line1: z.string().optional(),
    line2: z.string().optional(),
    postalCode: z.string().optional(),
    state: z.string().optional(),
});

export type AddressOutput = z.infer<typeof zAddressOutput>;
export const zAddressOutput = zAddressUpsert.extend({
    id: zId,
});
