import { zId } from ':utils/id';
import { z } from 'zod';
import { OrderLogType, zOrderLogData } from './order';
import { zDateTime } from ':utils/common';

export type LogData = z.infer<typeof zOrderLogData>;
export type LogType = z.infer<typeof zLogType>;
const zLogType = z.nativeEnum(OrderLogType);

export type LogOutput = z.infer<typeof zLogOutput>;
export const zLogOutput = z.object({
    id: zId,
    createdAt: zDateTime,
    data: zOrderLogData,
});
