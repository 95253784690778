import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { api } from ':frontend/utils/api';
import { useUser } from ':frontend/context/UserProvider';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { ClientContact, ClientInfoFE, computeClientShortcut } from ':frontend/types/Client';
import { useClients } from ':frontend/hooks';
import { ClientIconCustom } from ':frontend/components/client/ClientIconLink';
import { AddButton, DeleteButton } from ':frontend/components/forms/buttons';
import clsx from 'clsx';
import { type Signal, useSignal } from '@preact/signals-react';
import { SpinnerButton } from ':frontend/components/common';
import { clientContactToServer } from ':frontend/types/EventParticipant';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { type EmptyObject, Query } from ':frontend/utils/common';
import { trpc } from ':frontend/context/TrpcProvider';

export default function ImportClients() {
    const { appUser } = useUser();

    return appUser.google.isContacts
        ? <ClientsEnabled />
        : <ClientsNotEnabled />;
}

function ClientsNotEnabled() {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients' });

    return (
        <div className='container-small pb-5'>
            <h1>{t('page-title')}</h1>
            <Card className='text-center fs-4 p-5'>
                <div>
                    <Trans
                        t={t}
                        i18nKey='not-enabled-text'
                        components={{ a: <Link to={routesFE.integrations.path} /> }}
                    />
                </div>
            </Card>
        </div>
    );
}

function ClientsEnabled() {
    const { clients, addClients } = useClients();
    const [ allContacts, setAllContacts ] = useState<ClientContact[]>();
    const checked = useSignal<CheckedContacts>(getDefaultChecked());
    const reset = useSignal<EmptyObject>(getDefaultChecked().contacts);

    async function fetchContacts(signal: AbortSignal) {
        const response = await api.google.getContacts(signal);
        if (!response.status || !response.data.otherContacts)
            return;

        const fetchedContacts = response.data.otherContacts.map(ClientContact.fromServer).filter((contact): contact is ClientContact => !!contact);
        setAllContacts(fetchedContacts);
    }

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchContacts(signal);

        return abort;
    }, []);

    // We need only those contacts that are not already clients.
    const newContacts = useMemo(() => {
        if (!allContacts || !clients)
            return;

        const existingEmails = new Set(clients.map(client => client.email));
        return allContacts.filter(contact => !existingEmails.has(contact.canonicalEmail));
    }, [ allContacts, clients ]);

    const { settings } = useUser();
    const { addAlert } = useNotifications();
    const createClientsMutation = trpc.$client.createClients.useMutation();

    function importClients() {
        const currentChecked = checked.peek();
        const contactsToServer = Object.entries(currentChecked.contacts)
            .filter(([ _, checked ]) => checked)
            .map(([ email ]) => allContacts!.find(contact => contact.canonicalEmail === email)!)
            .map(contact => clientContactToServer(contact, settings));

        createClientsMutation.mutate(contactsToServer, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: response => {
                const importedClients = response.map(ClientInfoFE.fromServer);
                addClients(importedClients);
                resetCheckedSignal(checked, reset);
                addAlert(createTranslatedSuccessAlert('pages:importClients.import-success-alert', { count: importedClients.length }));
            },
        });
    }

    return (
        <div className='h-100 flex-column d-flex'>
            <ImportOverview checked={checked} reset={reset} onImport={importClients} isFetching={createClientsMutation.isPending} />
            {newContacts && (
                <ImportClientsForm contacts={newContacts} checked={checked} reset={reset} />
            )}
        </div>
    );
}

type ImportOverviewProps = Readonly<{
    checked: Signal<CheckedContacts>;
    reset: Signal<EmptyObject>;
    onImport: () => void;
    isFetching?: boolean;
}>;

function ImportOverview({ checked, reset, onImport, isFetching }: ImportOverviewProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients' });
    const count = checked.value.count;
    const isImporting = count !== 0;

    function resetChecked() {
        resetCheckedSignal(checked, reset);
    }

    if (!isImporting) {
        return (
            <div className='container-small py-3'>
                <h1 className='m-0' style={{ height: '42px' }}>{t('page-title')}</h1>
            </div>
        );
    }

    return (
        <div className='container-small py-3'>
            <h1 className='m-0' style={{ height: '0px', opacity: '0' }}>{t('page-title')}</h1>
            <div
                className='ps-3 pe-2 d-flex align-items-center justify-content-between rounded rounded-2 border border-primary bg-white'
                style={{ height: '42px' }}
            >
                <span className='fw-semibold monospace-numbers'>
                    {t('selected-contacts-label', { count })}
                </span>
                <div className='d-flex align-items-center gap-2'>
                    <SpinnerButton
                        onClick={onImport}
                        isFetching={isFetching}
                        disabled={count === 0}
                        className='compact monospace-numbers'
                    >
                        {t('import-contacts-button', { count })}
                    </SpinnerButton>
                    <Button onClick={resetChecked} className='compact' variant='outline-danger' disabled={isFetching}>
                        {t('cancel-button')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

type ImportClientsFormProps = Readonly<{
    contacts: ClientContact[];
    checked: Signal<CheckedContacts>;
    reset: Signal<EmptyObject>;
}>;

function ImportClientsForm({ contacts, checked, reset }: ImportClientsFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients' });
    const [ filter, setFilter ] = useState('');
    const filteredContacts = useMemo(() => {
        if (!filter)
            return contacts;

        const filterQuery = new Query(...filter.split(' '));
        return contacts.filter(contact => contact.query.match(filterQuery));
    }, [ contacts, filter ]);

    return (<>
        <div className='container-small d-flex align-items-center justify-content-between mb-3'>
            <div className='w-50 fl-design'>
                <Form.Control
                    onChange={e => setFilter(e.target.value)}
                    value={filter}
                    placeholder={t('search-placeholder')}
                    style={{ height: '36px', minHeight: '36px' }}
                    className='w-100'
                />
            </div>
            <div className='fw-medium pe-2'>
                {t('contacts-count-label', { count: contacts.length })}
            </div>
        </div>
        <div className='fl-main-scroller pb-5'>
            <div className='container-small d-flex flex-column gap-2'>
                {filteredContacts.map(contact => (
                    <ContactRow key={contact.canonicalEmail} contact={contact} checked={checked} reset={reset} />
                ))}
            </div>
        </div>
    </>);
}

type ContactRowProps = Readonly<{
    contact: ClientContact;
    checked: Signal<CheckedContacts>;
    reset: Signal<EmptyObject>;
}>;

function ContactRow({ contact, checked, reset }: ContactRowProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'importClients' });
    const { email, canonicalEmail } = contact;
    const name = contact.name === email ? '' : contact.name;
    const shortcut = computeClientShortcut(contact.name, email);
    const [ isImported, setIsImported ] = useState(checked.peek().contacts[canonicalEmail] ?? false);

    function inputIsImported(value: boolean) {
        setIsImported(value);
        setCheckedSignal(checked, canonicalEmail, value);
    }

    // TODO search is still rather slow. Maybe we should keep all the rows and just hide them with another signal (containing the query)?
    useEffect(() => {
        return reset.subscribe(() => setIsImported(checked.peek().contacts[canonicalEmail] ?? false));
    }, [ checked, reset, canonicalEmail ]);

    return (
        <div className='d-flex align-items-center gap-3'>
            <ClientIconCustom text={shortcut} size='lg' />
            <div className={clsx('flex-grow-1 align-self-stretch d-flex align-items-center rounded-1 px-3', isImported && 'bg-primary-50')}>
                {name ? (
                    <div>
                        <div className='text-truncate fw-medium'>{name}</div>
                        <div className='text-truncate text-muted'>{email}</div>
                    </div>
                ) : (
                    <div className='text-truncate fw-medium'>{email}</div>
                )}
            </div>
            {isImported ? (
                <DeleteButton aria={t('delete-button-aria')} onClick={() => inputIsImported(false)} />
            ) : (
                <AddButton aria={t('add-button-aria')} className='text-primary' onClick={() => inputIsImported(true)} />
            )}
        </div>
    );
}

type CheckedContacts = {
    contacts: Record<string, boolean | undefined>;
    count: number;
};

// We have to use function here because the default contacts value will be mutated.
const getDefaultChecked = () => ({ contacts: {}, count: 0 });

function setCheckedSignal(checked: Signal<CheckedContacts>, contact: string, newValue: boolean) {
    const oldState = checked.peek();
    const oldValue = oldState.contacts[contact] ?? false;
    // Here we intentionally don't update the contacts object. But it shouldn't be necessary since nothing should depend directly on it.
    // The count count should be used instead.
    oldState.contacts[contact] = newValue;
    const newCount = oldState.count - (oldValue ? 1 : 0) + (newValue ? 1 : 0);

    checked.value = { contacts: oldState.contacts, count: newCount };
}

function resetCheckedSignal(checked: Signal<CheckedContacts>, reset: Signal<EmptyObject>) {
    const newValue = getDefaultChecked();
    checked.value = newValue;
    reset.value = {};
}
