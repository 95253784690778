import { api } from ':frontend/utils/api';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { compareArrays, last } from ':frontend/utils/common';
import { EventFE } from ':frontend/types/Event';
import { Views, type View } from ':frontend/lib/calendar/Views';
import { trpc } from ':frontend/context/TrpcProvider';

type NavigationView = View | 'year';

export type MonthLoaderController<TItem, TMonth> = {
    /** always use dataObject in deps arrays, as dataObject.data get's mutated, not reassigned */
    dataObject: { data: TItem[] };
    setDataObject: Dispatch<SetStateAction<{ data: TItem[] }>>;
    handleNavigate: (newDate: DateTime, view: NavigationView) => Promise<void>;
    visibleMonths: (TMonth | undefined)[];
};

type MonthFetchResult<TItem, TMonth> = {
    stateUpdate: (state?: TMonth) => TMonth;
    result: TItem[];
};

type MonthFunctionResult<TItem, TMonth> = {
    state: TMonth;
    promise: Promise<MonthFetchResult<TItem, TMonth>>;
};

type MonthFunctionParams<TMonth> = {
    state: TMonth | undefined;
    start: DateTime;
    end: DateTime;
} & ApiParams;

type MonthFunction<TItem, TMonth> = (params: MonthFunctionParams<TMonth>) => MonthFunctionResult<TItem, TMonth> | undefined;

export function useMonthLoader<TItem, TMonth>(
    monthFunction: MonthFunction<TItem, TMonth>,
    idFilter: IdFilter<TItem>,
    initialDate: DateTime = DateTime.now(),
    initialView: View = Views.WEEK,
): MonthLoaderController<TItem, TMonth> {
    const [ dataObject, setDataObject ] = useState({ data: [] as TItem[] });
    // const data = useRef([] as TItem[]);
    const monthStates = useRef(new Map as Map<string, TMonth>);
    // const idFilter = useRef(new Set as IdFilter);
    const [ monthIndices, setMonthIndices ] = useState(computeMonthIndices(initialDate, initialView));

    const utils = trpc.useUtils();

    const handleNavigate = useCallback(async (date: DateTime, view: NavigationView) => {
        setMonthIndices(oldValue => updateMonthIndices(oldValue, computeMonthIndices(date, view)));
    }, []);

    const fetchMonth = useCallback(async (index: string, signal?: AbortSignal) => {
        const initialState = monthStates.current.get(index);

        const { start, end } = monthIndexToDateRange(index);
        const middleResult = monthFunction({ state: initialState, start, end, signal, utils });
        if (!middleResult)
            return;

        monthStates.current.set(index, middleResult.state);

        const { stateUpdate, result } = await middleResult.promise;
        monthStates.current.set(index, stateUpdate(monthStates.current.get(index)));

        setDataObject(object => {
            object.data.push(...idFilter.apply(result));
            return { ...object };
        });
    }, [ monthFunction, idFilter, utils ]);

    const fetchMonths = useCallback(async (indices: string[], signal?: AbortSignal) => {
        // We want to fetch the months one by one so that the first request is there as soon as possible
        for (const index of indices)
            await fetchMonth(index, signal);
    }, [ fetchMonth ]);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchMonths(monthIndices.fetchable, signal);

        return abort;
    }, [ monthIndices, fetchMonths ]);

    return {
        dataObject,
        setDataObject,
        handleNavigate,
        visibleMonths: monthIndices.visible.map(index => monthStates.current.get(index)),
    };
}

type MonthIndices = {
    visible: string[];
    fetchable: string[];
}

function updateMonthIndices(oldValue: MonthIndices, newValue: MonthIndices): MonthIndices {
    const nothingChanged = compareArrays(oldValue.fetchable, newValue.fetchable) && compareArrays(oldValue.visible, newValue.visible);
    return nothingChanged
        ? oldValue
        : newValue;
}

function computeMonthIndices(date: DateTime, view: NavigationView): MonthIndices {
    const visible = computeVisibleMonths(date, view);
    const fetchable = [
        visible[0].minus({ month: 1 }),
        ...visible,
        last(visible).plus({ month: 1 }),
    ];

    return {
        visible: visible.map(dateToMonthIndex),
        fetchable: fetchable.map(dateToMonthIndex),
    };
}

function computeVisibleMonths(date: DateTime, view: NavigationView) {
    if (view === Views.MONTH) {
        return [ date.startOf('month') ];
    }
    else if (view === 'year') {
        const yearStart = date.startOf('year');
        return [ ...Array(12) ].map((_, monthIndex) => yearStart.plus({ months: monthIndex }));
    }
    else {  // use week as fallback
        const start = date.startOf('week').startOf('month');
        const end = date.endOf('week').startOf('month');
        return +start === +end ? [ start ] : [ start, end ];
    }
}

function dateToMonthIndex(date: DateTime) {
    return date.toFormat('M-y');
}

function monthIndexToDateRange(index: string) {
    const start = DateTime.fromFormat(index, 'M-y');
    const end = start.plus({ months: 1 });

    return { start, end };
}

// Fetching

type ApiParams = {
    signal?: AbortSignal;
    utils: ReturnType<typeof trpc.useUtils>;
};

type FetchFunction<TItem> = (start: DateTime, end: DateTime, element: string, api: ApiParams) => Promise<TItem[] | undefined>;

export enum LoadingState {
    Default = 'default',
    Loading = 'loading',
    Loaded = 'loaded',
}

export type SimpleParams = MonthFunctionParams<LoadingState>;

export function simpleMonthFunction<TItem>(
    fetchFunction: FetchFunction<TItem>,
    params: SimpleParams,
): MonthFunctionResult<TItem, LoadingState> | undefined {
    const { state, start, end, utils, signal } = params;
    const initialState = state ?? LoadingState.Default;
    if (initialState !== LoadingState.Default)
        return undefined;

    const promise = createSimpleFetchFunction(fetchFunction(start, end, '', { utils, signal }));

    return {
        state: LoadingState.Loading,
        promise,
    };
}

async function createSimpleFetchFunction<TItem>(promise: Promise<TItem[] | undefined>): Promise<MonthFetchResult<TItem, LoadingState>> {
    const response = await promise;
    const stateUpdate = () => response ? LoadingState.Loaded : LoadingState.Default;

    return {
        result: response ?? [],
        stateUpdate,
    };
}

export async function fetchFlowlanceEvents(start: DateTime, end: DateTime, calendarId: string, api: ApiParams): Promise<EventFE[] | undefined> {
    try {
        const eventsOutputs = await api.utils.event.getEvents.fetch({
            start: {
                // UTC ?
                afterInclusive: start.toUTC(),
                before: end.toUTC(),
            },
        });
        // TODO pagination: false,

        return eventsOutputs.items.map(EventFE.fromServer);
    }
    catch (err) {
        return;
    }
}

export type GroupState = Record<string, LoadingState>;

export type GroupParams = MonthFunctionParams<GroupState>;

export function groupMonthFunction<TItem>(
    fetchFunction: FetchFunction<TItem>,
    activeElements: string[],
    params: GroupParams,
): MonthFunctionResult<TItem, GroupState> | undefined {
    const initialState = params.state ?? {};
    const elementsToLoad = getNotLoadedElements(initialState, activeElements);
    const promises = elementsToLoad.map(async element => fetchFunction(params.start, params.end, element, params));
    if (promises.length === 0)
        return undefined;

    const middleState = updateGroupState(initialState, elementsToLoad, LoadingState.Loading);
    const promise = createGroupFetchFunction(elementsToLoad, promises);

    return {
        state: middleState,
        promise,
    };
}

async function createGroupFetchFunction<TItem>(elementsToLoad: string[], promises: Promise<TItem[] | undefined>[]): Promise<MonthFetchResult<TItem, GroupState>> {
    const results = await Promise.all(promises);
    const result = results.filter((r): r is TItem[] => !!r).flatMap(r => r);

    const successfulElements = elementsToLoad.filter((_, index) => !!results[index]);
    const failedElements = elementsToLoad.filter((_, index) => !results[index]);

    const stateUpdate = (currentState?: GroupState) => {
        const s = updateGroupState(currentState ?? {}, successfulElements, LoadingState.Loaded);
        return updateGroupState(s, failedElements, LoadingState.Default);
    };

    return {
        result,
        stateUpdate,
    };
}

function getNotLoadedElements(state: GroupState, activeElements: string[]): string[] {
    return activeElements.filter(e => state[e] === undefined || state[e] === LoadingState.Default);
}

function updateGroupState(state: GroupState, elements: string[], loadingState: LoadingState): GroupState {
    const update = {} as Record<string, LoadingState>;
    elements.forEach(c => update[c] = loadingState);
    return {
        ...state,
        ...update,
    };
}

// This ensures that all events will be displayed only once. The potential problem here is that when loading by months, some events might be in more than one month.
// Also, it's needed to prevent the events to be pushed to the event array multiple times (because of how state updates work).
export class IdFilter<TItem> {
    private readonly filter: Set<string> = new Set;

    constructor(
        private readonly getId: (item: TItem) => string,
    ) {}

    apply(items: TItem[]): TItem[] {
        return items.filter(item => {
            const id = this.getId(item);
            return this.filter.has(id) ? false : this.filter.add(id);
        });
    }
}
