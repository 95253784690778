import { type GoogleUserFromServer } from ':frontend/types/GoogleUser';
import type { ClientContactFromServer } from ':frontend/types/Client';
import type { GoogleCalendarEventsFromServer, GoogleCalendarFromServer } from ':frontend/types/calendar/Google';
import { type DateTime } from 'luxon';
import { type Result, DataResultSuccess } from ':frontend/types/result';
import type { GoogleEventFromServer } from ':utils/lib/google';
import { ApiAuthorizer, createApiObject } from './types';

const authorizer = new ApiAuthorizer();
const apiObject = createApiObject({
    authorizer,
});

function getUserInfo(signal?: AbortSignal) {
    return apiObject.GET<GoogleUserFromServer>('https://www.googleapis.com/oauth2/v1/userinfo', {}, { signal });
}

function getContacts(signal?: AbortSignal) {
    return apiObject.GET<{ otherContacts: ClientContactFromServer[], totalSize: number }>('https://people.googleapis.com/v1/otherContacts', {
        readMask: 'emailAddresses,names',
        pageSize: 1000, // The maximum allowed by the API.
    }, { signal });
}

function searchContacts(query: string, signal?: AbortSignal) {
    return apiObject.GET<{ results?: { person: ClientContactFromServer }[] }>('https://people.googleapis.com/v1/otherContacts:search', {
        query,
        readMask: 'emailAddresses,names',
    }, { signal });
}

function getCalendars(signal?: AbortSignal) {
    return apiObject.GET<{ items: GoogleCalendarFromServer[] }>('https://www.googleapis.com/calendar/v3/users/me/calendarList', {}, { signal });
}

async function getCalendarEvents(start: DateTime, end: DateTime, calendarId: string, signal?: AbortSignal): Promise<Result<GoogleEventFromServer[]>> {
    const output: GoogleEventFromServer[] = [];

    let nextPageToken: string | undefined;
    do {
        const response = await getCalendarEventsPage(start, end, calendarId, signal, nextPageToken);
        if (!response.status)
            return response;

        output.push(...response.data.items);
        nextPageToken = response.data.nextPageToken;
    } while (nextPageToken);

    return DataResultSuccess(output);
}

function getCalendarEventsPage(start: DateTime, end: DateTime, calendarId: string, signal?: AbortSignal, pageToken?: string) {
    const encodedId = encodeURIComponent(calendarId);

    return apiObject.GET<GoogleCalendarEventsFromServer>(`https://www.googleapis.com/calendar/v3/calendars/${encodedId}/events`, {
        timeMin: start.toISO(),
        timeMax: end.toISO(),
        singleEvents: true,
        pageToken,
        maxResults: 2500, // The maximum allowed by the API.
    }, { signal });
}

export const googleApi = {
    authorizer,
    getUserInfo,
    getContacts,
    searchContacts,
    getCalendars,
    getCalendarEvents,
};
