import type { DateTime } from 'luxon';

export type SubscriptionOutput = {
    status: SubscriptionStatus;
    paymentPeriod: SubscriptionTierPaymentPeriod;
    createdAt: DateTime;
    trialDays?: number;
    isCustomLogoEnabled: boolean;
    isBankIntegrationEnabled: boolean;
    tierCode: SubscriptionTierCode;
};

export const isSubscription = {
    active({ status }: { status: SubscriptionStatus }) {
        return status === SubscriptionStatus.active
            || status === SubscriptionStatus.trialing
            || status === SubscriptionStatus.incomplete;
    },
    pending({ status }: { status: SubscriptionStatus }) {
        return status === SubscriptionStatus.pastDue
            || status === SubscriptionStatus.incompleteExpired;
    },
    trial({ status }: { status: SubscriptionStatus }) {
        return status === SubscriptionStatus.trialing;
    },
};

export enum SubscriptionStatus {
    active = 'active',
    canceled = 'canceled',
    pastDue = 'pastDue',
    trialing = 'trialing',
    unpaid = 'unpaid',
    incomplete = 'incomplete',
    incompleteExpired = 'incompleteExpired',
}

export enum SubscriptionTierCode {
    free = 'free',
    paid2 = 'paid2',
}

export enum SubscriptionTierPaymentPeriod {
    monthly = 'monthly',
    yearly = 'yearly',
}

export enum SubscriptionTierAutoCreate {
    onRegister = 'onRegister',
}
