import { type ClientContact, type ClientInfoFE } from ':frontend/types/Client';
import { routesFE } from ':utils/routes';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

export type Size = 'sm' | 'md' | 'lg';

type ClientIconProps = Readonly<{
    client: ClientInfoFE | ClientContact;
    className?: string;
    size?: Size;
}>;

export function ClientIcon({ client, className, size }: ClientIconProps) {
    return (
        <ClientIconCustom text={client.shortcut} className={className} size={size} isDeleted={'id' in client && client.isDeleted} />
    );
}

type ClientIconCustomProps = Readonly<{
    text: string;
    className?: string;
    size?: Size;
    isDeleted?: boolean;
}>;

export function ClientIconCustom({ text, className, size, isDeleted }: ClientIconCustomProps) {
    return (
        <div {...getSettings(className, size, isDeleted)}>
            <span>{text}</span>
        </div>
    );
}

const settingsBySize: Record<Size, { className: string, style: React.CSSProperties }> = {
    sm: {
        className: 'flex-shrink-0 d-flex align-items-center justify-content-center text-white unselectable lha-1 fw-medium',
        style: { width: 20, height: 18, padding: 2, borderRadius: 4, fontSize: 10 },
    },
    md: {
        className: 'flex-shrink-0 d-flex align-items-center justify-content-center text-white unselectable lha-2 fw-medium',
        style: { width: 24, height: 24, padding: 2, borderRadius: 4, fontSize: 10 },
    },
    lg: {
        className: 'flex-shrink-0 d-flex align-items-center justify-content-center text-white unselectable lha-2 fw-medium fs-6',
        style: { width: 40, height: 40, padding: 2, borderRadius: 4, fontSize: 12 },
    },
};

function getSettings(className?: string, size?: Size, isDeleted?: boolean) {
    const settings = settingsBySize[size ?? 'sm'];

    return {
        className: clsx(isDeleted ? 'bg-secondary' : 'bg-dark', settings.className, className),
        style: settings.style,
    };
}

type ClientIconLinkProps = Readonly<{
    client: ClientInfoFE | ClientContact;
    className?: string;
    newTab?: boolean;
}>;

export default function ClientIconLink({ client, className, newTab }: ClientIconLinkProps) {
    if (!('id' in client))
        return <ClientIconBadge client={client} className={className} />;

    if (client.isDeleted) {
        return (
            <div className={clsx('fl-client-icon-badge deleted', className)}>
                <ClientIcon client={client} />
                <span className='lha-2 text-truncate'>{client.name}</span>
            </div>
        );
    }

    return (
        <Link
            to={routesFE.clients.detail.resolve({ id: client.id, key: 'general' })}
            className={clsx('fl-client-icon-badge hoverable-underline', className)}
            target={newTab ? '_blank' : undefined}
            rel={newTab ? '_noreferrer' : undefined}
        >
            <ClientIcon client={client} />
            <span className='lha-2 text-truncate'>{client.name}</span>
        </Link>
    );
}

type ClientIconBadgeProps = Readonly<{
    client: ClientInfoFE | ClientContact;
    className?: string;
}>;

export function ClientIconBadge({ client, className }: ClientIconBadgeProps) {
    return (
        <div className={clsx('fl-client-icon-badge', className)}>
            <ClientIcon client={client} />
            <span className='lha-2 text-truncate'>{client.name}</span>
        </div>
    );
}

export function ClientIconRow({ client, className }: ClientIconBadgeProps) {
    const { email } = client;
    const name = client.name === email ? '' : client.name;

    return (
        <div className={clsx('d-flex align-items-center gap-3', className)}>
            <ClientIconCustom text={client.shortcut} size='md' className='rounded-circle' />
            <div className='flex-grow-1 overflow-hidden'>
                {name ? (
                    <div>
                        <div className='text-truncate fw-medium'>{name}</div>
                        <div className='text-truncate text-muted' style={{ fontSize: '12px' }}>{email}</div>
                    </div>
                ) : (
                    <div className='text-truncate fw-medium'>{email}</div>
                )}
            </div>
        </div>
    );
}
