import { useState } from 'react';
import { type UserFormData, type RegisterError } from ':frontend/components/auth/RegisterUserForm';
import { type PersonFormData } from ':frontend/components/auth/RegisterPersonForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import useAuth from ':frontend/context/AuthProvider';
import { getAppUserLocalization } from ':frontend/types/i18n';

export enum RegisterState {
    UserForm = 'UserForm',
    PersonForm = 'PersonForm',
}

export function useRegisterEmail() {
    const { t } = useTranslation('common', { keyPrefix: 'error' });
    const { auth } = useAuth();
    const navigate = useNavigate();

    const [ isFetching, setIsFetching ] = useState(false);
    const [ userData, setUserData ] = useState<UserFormData>();
    const [ state, setState ] = useState(RegisterState.UserForm);

    // We have to check if the email doesn't exist already. The errors are then passed to the form so it can display them.
    async function submitUserForm(data: UserFormData): Promise<RegisterError[]> {
        if (userData && userData.email === data.email) {
            // We have already checked the email before, so we can skip this step an go directly to the next form.
            setUserData(data);
            setState(RegisterState.PersonForm);
            return [];
        }

        setIsFetching(true);
        const response = await auth.registerValidate({ email: data.email });
        setIsFetching(false);
        if (!response.status)
            // TODO handle error
            return [];

        const errors = response.data.errors?.map(error => ({
            name: error.property,
            error: {
                type: 'custom',
                message: t(error.type),
            },
        }));

        if (errors && errors.length !== 0)
            return errors;

        // Everything should be fine now so we can finally continue with the next step.

        setUserData(data);
        setState(RegisterState.PersonForm);

        return [];
    }

    async function submitPersonForm(personData: PersonFormData) {
        if (!userData)
            return;

        setIsFetching(true);
        const { password, ...rest } = userData;
        const result = await auth.register({
            password,
            ...rest,
            ...personData,
            ...getAppUserLocalization(),
        });
        setIsFetching(false);

        if (!result)
            // TODO handle error
            return;

        navigate(routesFE.root);
    }

    return {
        state,
        userData,
        isFetching,
        submitUserForm,
        submitPersonForm,
        onBack: () => setState(RegisterState.UserForm),
    };
}
