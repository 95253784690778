import { getEnumValues } from ':utils/common';
import { OnboardingFlag } from ':utils/entity/user';
import type { SubscriptionPhase } from ':utils/entity/user';
import { type Id } from ':utils/id';

enum Track {
    Subscription = 'subscription',
}

type OnboardingFlags = { [key in OnboardingFlag]: boolean };

export type OnboardingFromServer = {
    id: Id;
} & {
	[key in Track]: SubscriptionPhase;
} & {
    [key in OnboardingFlag]: boolean;
};

export class OnboardingFE {
    constructor(
        readonly id: Id,
        readonly subscription: SubscriptionPhase,
        readonly flags: OnboardingFlags,
        readonly isFlagsFinished: boolean,
    ) {}

    static fromServer(input: OnboardingFromServer): OnboardingFE {
        const flags: OnboardingFlags = {} as OnboardingFlags;
        let allFlagsFinished = true;
        getEnumValues(OnboardingFlag).forEach(key => {
            flags[key] = input[key];
            if (!flags[key])
                allFlagsFinished = false;
        });

        return new OnboardingFE(
            input.id,
            input.subscription,
            flags,
            allFlagsFinished,
        );
    }
}

export type OnboardingInfo = {
    profession: string;
    clientCount: string;
    weeklySessionCount: string;
}
