import { CurrencyFE, type Money } from ':frontend/modules/money';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getI18nLocale } from ':utils/i18n';

type MoneyDisplayProps = Readonly<{
    money: Money;
    noColor?: boolean;
    className?: string;
}>;

export default function MoneyDisplay({ money, noColor, className }: MoneyDisplayProps) {
    const { i18n } = useTranslation();

    return (
        <span className={clsx(getClass(money.amount, noColor), className)}>
            {money.currency.displayFull(money.amount || 0, getI18nLocale(i18n), true)}
        </span>
    );
}

type MoneyAmountDisplayProps = Readonly<{
    amount: number;
    noColor?: boolean;
    className?: string;
}>;

export function MoneyAmountDisplay({ amount, noColor, className }: MoneyAmountDisplayProps) {
    const { i18n } = useTranslation();

    return (
        <span className={clsx(getClass(amount, noColor), className)}>
            {CurrencyFE.displayAmount(amount || 0, getI18nLocale(i18n), true)}
        </span>
    );
}

function getClass(amount: number, noColor?: boolean) {
    if (noColor)
        return 'fl-money-display';

    if (isNaN(amount))
        return 'fl-money-display fl-money-display--invalid';
    if (amount < 0)
        return 'fl-money-display fl-money-display--negative';

    return 'fl-money-display';
}

type CurrencyDisplayProps = Readonly<{
    currency: CurrencyFE;
    className?: string;
    children: React.ReactNode;
    gap?: 1 | 2 | 3 | 4 | 5 | 6;
}>;

export function CurrencyDisplay({ currency, className, children, gap }: CurrencyDisplayProps) {
    const { i18n } = useTranslation();
    const isSymbolBefore = currency.isSymbolBefore(getI18nLocale(i18n));

    return (
        <div className={clsx('d-flex align-items-baseline', gap && `gap-${gap}`, className)}>
            {!isSymbolBefore && children}
            <span className={'fw-medium'}>
                {currency.displaySymbol(getI18nLocale(i18n))}
            </span>
            {isSymbolBefore && children}
        </div>
    );
}

type PercentDisplayProps = Readonly<{
    amount: number;
    noColor?: boolean;
    className?: string;
    padToAmount?: number;
}>;

export function PercentDisplay({ amount, noColor, className, padToAmount }: PercentDisplayProps) {
    const { i18n } = useTranslation();
    const padding = padToAmount ? ''.padStart(padToAmount.toFixed(0).length - (amount || 0).toFixed(0).length, '0') : undefined;

    return (
        <span className={clsx(getClass(amount, noColor), className)}>
            {padding && (<span aria-hidden style={{ visibility: 'hidden' }}>{padding}</span>)}
            {CurrencyFE.displayAmount(amount || 0, getI18nLocale(i18n), true)} %
        </span>
    );
}
