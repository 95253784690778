import { type ReactNode } from 'react';
import { type AlertContent, Variant, DEFAULT_ERROR_ALERT_TIMEOUT, DEFAULT_SUCCESS_ALERT_TIMEOUT } from ':frontend/types/notifications';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useNotifications from ':frontend/context/NotificationProvider';
import { Alert } from 'react-bootstrap';
import { getErrorTranslation, isTypedError, type TypedError } from ':frontend/types/TypedError';

export type TranslationOptions = {
    components?: { readonly [tagName: string]: React.ReactElement };
    links?: { readonly [tagName: string]: string };
    count?: number;
    values?: Record<string, string>;
};

type TranslatedAlertMessageProps = Readonly<{
    alertId: number;
    i18nKey: string;
    options?: TranslationOptions;
}>;

export default function TranslatedAlertMessage({ alertId, i18nKey, options }: TranslatedAlertMessageProps) {
    const { t } = useTranslation();
    const { components, links, ...rest } = options ?? {};

    return (
        <span>
            <Trans
                i18nKey={i18nKey}
                t={t}
                components={{
                    ...components,
                    ...createLinkComponents(alertId, links ?? {}),
                }}
                {...rest}
            />
        </span>
    );
}

function createLinkComponents(alertId: number, links: { readonly [tagName: string]: string }): { readonly [tagName: string]: React.ReactElement } {
    const output: { [tagName: string]: React.ReactElement } = {};

    for (const [ tagName, to ] of Object.entries(links))
        output[tagName] = <AlertLink alertId={alertId} to={to}/>;

    return output;
}

type AlertLinkProps = Readonly<{
    alertId: number;
    to: string;
    children?: ReactNode;
}>;

function AlertLink({ alertId, to, children }: AlertLinkProps) {
    const navigate = useNavigate();
    const { removeAlert } = useNotifications();

    function onClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        navigate(to);
        removeAlert(alertId);
    }

    return (
        <Alert.Link onClick={onClick}>{children}</Alert.Link>
    );
}

export function createTranslatedSuccessAlert(i18nKey: string, options?: TranslationOptions): AlertContent {
    return {
        message: ({ alertId }) => <TranslatedAlertMessage alertId={alertId} i18nKey={i18nKey} options={options}/>,
        variant: Variant.Success,
        timeout: DEFAULT_SUCCESS_ALERT_TIMEOUT,
    };
}

/** Empty params for generic error alert */
export function createTranslatedErrorAlert(i18nKey?: string, options?: TranslationOptions): AlertContent {
    return {
        message: ({ alertId }) => <TranslatedAlertMessage alertId={alertId} i18nKey={i18nKey ?? 'common:generalErrorAlert'} options={options} />,
        variant: Variant.Error,
        timeout: DEFAULT_ERROR_ALERT_TIMEOUT,
    };
}

function createTypedErrorAlert(error: TypedError): AlertContent {
    const { i18nKey, options } = getErrorTranslation(error);

    return createTranslatedErrorAlert('common:error.' + i18nKey, options);
}

export function createErrorAlert(error: unknown) {
    return isTypedError(error)
        ? createTypedErrorAlert(error)
        : createTranslatedErrorAlert();
}
