import { z } from 'zod';
import { DateTime } from 'luxon';
import type { Entity, Id } from './id';

export type RequiredNonNull<T extends Record<string, unknown>> = {
    [key in keyof T]: NonNullable<T[key]>;
}

export function toUnique<T>(array: T[], toComparable?: (item: T) => string | number): T[] {
    if (!toComparable)
        return Array.from(new Set(array));

    const output: T[] = [];
    const set = new Set<string | number>();
    for (const item of array) {
        const comparable = toComparable(item);
        if (set.has(comparable))
            continue;

        set.add(comparable);
        output.push(item);
    }

    return output;
}

export function toMap<T extends Entity>(array: T[]): Map<Id, T> {
    const map: Map<Id, T> = new Map();
    for (const item of array)
        map.set(item.id, item);

    return map;
}

export function computeIfAbsent<TKey, TValue>(map: Map<TKey, TValue>, key: TKey, computeFunction: (key: TKey) => TValue): TValue;

export function computeIfAbsent<TKey, TValue>(map: Map<TKey, TValue>, key: TKey, computeFunction: (key: TKey) => TValue | undefined): TValue | undefined;

export function computeIfAbsent<TKey, TValue>(map: Map<TKey, TValue>, key: TKey, computeFunction: (key: TKey) => TValue): TValue {
    const currentElement = map.get(key);
    if (currentElement !== undefined)
        return currentElement;

    // Here it's important that the value type of the map shouldn't support undefined. If it could, we could get map like this:
    // { 'key1': 'value1', 'key2': undefined }
    // Everything will work, but the map will contain undefined values. When calling map.values(), we would get:
    // [ 'value1', undefined ]
    const newElement = computeFunction(key);
    if (newElement === undefined)
        return undefined as TValue;

    map.set(key, newElement);

    return newElement;
}

/**
 * Distributive omit - if T = A | B | C, then DOmit<T, K> = DOmit<A, K> | DOmit<B, K> | DOmit<C, K>
 */
export type DOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;

/**
 * Distributive keyof
 */
export type DKeyof<T> = T extends unknown ? keyof T : never;

export type PartialBy<T, K extends keyof T> = Pick<Partial<T>, K> & DOmit<T, K>;

export type RequiredBy<T, K extends keyof T> = Pick<Required<T>, K> & DOmit<T, K>;

export const MILLISECONDS_IN_SECOND = 1000;

export function secondsToMilliseconds(seconds: number): number {
    return seconds * MILLISECONDS_IN_SECOND;
}

export function millisecondsToSeconds(milliseconds: number): number {
    return Math.round(milliseconds / MILLISECONDS_IN_SECOND);
}

export const SECONDS_IN_MINUTE = 60;

export function secondsToMinutes(seconds: number): number {
    // A minute is a basic unit of measurement here.
    return Math.round(seconds / SECONDS_IN_MINUTE);
}

export function minutesToSeconds(minutes: number): number {
    return minutes * SECONDS_IN_MINUTE;
}

export enum SortOrder {
    Ascending = 'asc',
    Descending = 'desc',
}

export function optional<TKey extends string, TValue>(key: TKey, value: TValue | undefined): { [K in TKey]?: TValue } {
    return (value !== undefined ? { [key]: value } : {}) as { [K in TKey]?: TValue };
}

type EnumObject<K = string | number> = { [key: string]: K };
type Enum<K, E extends EnumObject<K>> = E extends { [key: string]: infer T | string } ? T : never;

export function getEnumValues<E extends EnumObject<string>>(enumObject: E): Enum<string, E>[] {
    return Object.keys(enumObject).map(key => enumObject[key] as Enum<string, E>);
}

export function parseEnumValue<E extends EnumObject<string>>(value: string, enumObject: E): Enum<string, E> | undefined {
    return Object.values(enumObject).includes(value) ? value as Enum<string, E> : undefined;
}

export type EnumFilter<E extends string> = {
    [key in E]: boolean;
};

export function enumFilterToArray<E extends string>(filter: EnumFilter<E>): E[] {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(filter).filter(([ _, selected ]) => selected).map(([ key ]) => key as E);
}

export function isArrayOfType<T>(array: unknown[], discriminator: (element: unknown) => element is T): array is T[] {
    for (const element of array) {
        if (!discriminator(element))
            return false;
    }

    return true;
}

export const zDateTime = z.custom<DateTime>(val => DateTime.isDateTime(val));

// TODO This should be unified with the localizer DateRange. Also, maybe we should use (start, end) instead of (from, to), because the latter might be used in functions like "rangeToUpdate" or "startToUtc".
export type DateRange = z.infer<typeof zDateRange>;
export const zDateRange = z.object({
    from: zDateTime,
    to: zDateTime,
});

/** Lowercase hex (6-characters) without the '#' symbol. */
export const zColor = z.string().regex(/^[0-9a-f]{6}$/);

/**
 * Shortens a UUID v4 from 36 characters to 26 Base32hex characters (see RFC 4648).
 * Only lowercase letters are allowed.
 * Important note: Some other UUID representations might expect different Base32 alphabet.
 */
export function uuidToBase32(uuid: string): string {
    const withoutDashes = uuid.replace(/-/g, '');
    const base32 = base16ToBase32(withoutDashes);
    return base32.padStart(26, '0');
}

/**
 * Converts a Base16 string (0 - 9, a - f) to a Base32hex string (0 - 9, a - v). See RFC 4648.
 */
function base16ToBase32(input: string): string {
    // The input string might be just too long, so we have to split it into chunks.
    const chunksLength = Math.ceil(input.length / 5);
    const output = new Array(chunksLength);

    for (let i = 0; i < chunksLength; i++) {
        const end = input.length - i * 5;
        const start = end - 5;
        const fixedStart = start < 0 ? 0 : start;

        const chunk = input.slice(fixedStart, end);
        const base32 = parseInt(chunk, 16).toString(32);
        // The fist chunk is not padded.
        output[chunksLength - i - 1] = i !== chunksLength - 1 ? base32.padStart(4, '0') : base32;
    }

    return output.join('');
}

export function deepEquals<T extends object>(a: T, b: T): boolean {
    for (const key in a) {
        if (typeof a[key] === 'object' && a[key] !== null && b[key] !== null) {
            if (!deepEquals(a[key], b[key] as object))
                return false;
        }
        else if (a[key] !== b[key]) {
            return false;
        }
    }

    return true;
}

export function deepClone<T extends object & { [Symbol.iterator]?: never }>(o: T): T {
    const output: T = {} as T;
    for (const key in o)
        output[key] = deepCloneValue(o[key]);

    return output;
}

function deepCloneValue<T>(a: T): T {
    if (a === null || a === undefined)
        return a;

    if (typeof a === 'object') {
        if (Array.isArray(a))
            return a.map(deepCloneValue) as T;

        return deepClone(a);
    }

    return a;
}
