import { useCallback }  from 'react';
import { Button } from 'react-bootstrap';
import { type UseNewProductOrderDispatch, type UseNewProductOrderState } from ':frontend/components/orders/useNewProductOrder';
import { useTranslation } from 'react-i18next';
import { type ProductFE } from ':frontend/types/Product';
import { type ClientInfoFE } from ':frontend/types/Client';
import { Checkout, type CheckoutOutput } from ':frontend/components/orders/checkout/Checkout';
import { CloseButton } from ':frontend/components/forms/buttons';
import ProductOrderForm from './ProductOrderForm';
import { useNavigate } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { ProductOrderItemFE } from ':frontend/types/orders/ProductOrderItem';

type NewProductOrderInnerProps = Readonly<{
    state: UseNewProductOrderState;
    dispatch: UseNewProductOrderDispatch;
    products: ProductFE[];
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE | ClientInfoFE[]) => void;
}>;

export function NewProductOrderInner({ state, dispatch, products, clients, addClients }: NewProductOrderInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'newProductOrder' });
    const navigate = useNavigate();

    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            dispatch({ type: 'form', operation: 'checkoutBack' });
            break;
        case 'close':
            dispatch({ type: 'closeModal' });
            break;
        case 'finish': {
            const item = action.orders[0].items[0];
            if (item instanceof ProductOrderItemFE && item.guest)
                navigate(routesFE.clients.detail.resolve({ id: item.guest.id, key: 'packages' }));
            break;
        }
        case 'addClients':
            addClients(action.clients);
            break;
        }
    }, [ dispatch, addClients, navigate ]);

    if (state.checkout) {
        return (
            <Checkout input={state.checkout} output={checkoutOutput} showCloseButton />
        );
    }

    return (
        <div style={{ padding: '24px' }}>
            <div className='d-flex justify-content-between align-items-center mb-4'>
                <h2 className='my-0'>{t('modal-title')}</h2>
                <CloseButton onClick={() => dispatch({ type: 'closeModal' })} aria={t('close-modal-aria')} />
            </div>
            <ProductOrderForm products={products} clients={clients} state={state} dispatch={dispatch} />
            <div className='d-flex justify-content-end mt-4'>
                <Button
                    variant='outline-secondary'
                    onClick={() => dispatch({ type: 'closeModal' })}
                    className='px-4 me-2'
                >
                    {t('cancel-button')}
                </Button>
                <Button
                    onClick={() => dispatch({ type: 'form', operation: 'checkoutStart' })}
                    className='px-4'
                >
                    {t('continue-button')}
                </Button>
            </div>
        </div>
    );
}
