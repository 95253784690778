import { secondsToMinutes } from ':utils/common';
import { type Id } from ':utils/id';
import { type ProductOutput } from ':utils/entity/product';
import { moneyFromServer, type Money, type TaxRateFE, getTaxRate } from ':frontend/modules/money';

// TODO type & type-specific properties instead of placeholder values
export class ProductFE {
    private constructor(
        readonly id: Id,
        readonly title: string,
        readonly description: string | undefined,
        readonly sessionsCount: number,
        /** In minutes. */
        readonly sessionsDuration: number,
        /** Total price for all sessions. */
        readonly price: Money,
        readonly vat: TaxRateFE,
        readonly locationId: Id | undefined,
    ) {}

    static fromServer(input: ProductOutput): ProductFE {
        return new ProductFE(
            input.id,
            input.title,
            input.description,
            'sessionsCount' in input ? input.sessionsCount : 1,
            secondsToMinutes('sessionsDuration' in input ? input.sessionsDuration : 3600),
            moneyFromServer('price' in input ? input.price : 10000, 'currency' in input ? input.currency : 'EUR'),
            getTaxRate(('vat' in input && input.vat) ? input.vat : '000000'),
            'location' in input ? input.location?.id : undefined,
        );
    }
}
