import { getLocationDisplayName, LocationType, PlatformType, type LocationOutput, type PhysicalLocationOutput, type VideoLocationOutput } from ':utils/entity/location';
import type { Id } from ':utils/id';
import { AddressFE } from './Address';

export function locationFromServer(input: LocationOutput): BaseLocationFE {
    return input.type === LocationType.physical ?
        PhysicalLocationFE.fromServer(input) :
        VideoLocationFE.fromServer(input);
}

export function getLocationLink(location: BaseLocationFE): string {
    if (location instanceof PhysicalLocationFE)
        return `https://www.google.com/maps/search/?api=1&query=${location.address.city}, ${location.address.line1}`;

    if (location instanceof VideoLocationFE)
        return location.url;

    return '';
}

export class BaseLocationFE {
    protected constructor(
        readonly id: Id,
        readonly title: string,
        readonly platform: string,
        readonly platformType: PlatformType,
    ) {}

    get displayName() {
        return this.title;
    }
}

export class VideoLocationFE extends BaseLocationFE {
    readonly type = LocationType.video;

    private constructor(
        id: Id,
        title: string,
        platformType: PlatformType,
        readonly platform: string,
        readonly url: string,
    ) {
        super(id, title, platform, platformType);
    }

    static fromServer(input: VideoLocationOutput) {
        return new VideoLocationFE(
            input.id,
            input.title,
            getPlatformType(input.platform),
            input.platform,
            input.url,
        );
    }

    get displayName() {
        return getLocationDisplayName(this);
    }
}

function getPlatformType(platform: string): PlatformType {
    return platform in PlatformType ? PlatformType[platform as keyof typeof PlatformType] : PlatformType.custom;
}

export class PhysicalLocationFE extends BaseLocationFE {
    readonly type = LocationType.physical;

    private constructor(
        id: Id,
        title: string,
        readonly address: AddressFE,
    ) {
        super(id, title, PlatformType.physicalLocation, PlatformType.physicalLocation);
    }

    static fromServer(input: PhysicalLocationOutput) {
        return new PhysicalLocationFE(
            input.id,
            input.title,
            AddressFE.fromServer(input.address),
        );
    }

    get displayName() {
        return getLocationDisplayName(this);
    }
}
