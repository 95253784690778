import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type MasterProductItemFromServer, type SchedulerProductItemFromServer, masterProductItemFromServer, schedulerProductItemFromServer } from ':frontend/types/orders/OrderItem';
import { type GenericProductItem } from ':frontend/types/orders/ProductOrderItem';
import { ProductItemTile } from '../product/productDisplay';
import { Modal } from 'react-bootstrap';
import { useCached, useClients, useLocations } from ':frontend/hooks';
import EventForm from '../event/EventForm';
import { useScheduling } from '../event/useEvent';
import { SpinnerButton } from '../common';
import { type BaseLocationFE } from ':frontend/types/location';
import { EventScheduleModal } from '../event/EventUpdateModal';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';

type ClientPackagesProps = Readonly<{
    client: ClientInfoFE;
}>;

export default function ClientPackages({ client }: ClientPackagesProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });

    const { clients, addClients } = useClients();
    const { locations, addLocation } = useLocations();
    const context: ClientsLocationsContext | undefined = useMemo(() => clients && locations && { clients, addClients, locations, addLocation }, [ clients, addClients, locations, addLocation ]);

    const [ productItems, setProductItems ] = useState<GenericProductItem[]>();
    const activeItems = useMemo(() => productItems?.filter(oi => !oi.isCompleted), [ productItems ]);
    const completedItems = useMemo(() => productItems?.filter(oi => oi.isCompleted), [ productItems ]);

    const isMasterOrFreelancer = !!toMaster(useUser());

    const productItemsQuery = trpc.order.getProductItems.useQuery({ guestId: client.id });

    useEffect(() => {
        if (!productItemsQuery.data)
            return;

        const fetchedOrderItems = isMasterOrFreelancer
            ? (productItemsQuery.data as MasterProductItemFromServer[]).map(masterProductItemFromServer)
            : (productItemsQuery.data as SchedulerProductItemFromServer[]).map(schedulerProductItemFromServer);

        setProductItems(fetchedOrderItems);
    }, [ productItemsQuery.data, isMasterOrFreelancer ]);

    const [ schedulingProductItem, setSchedulingProductItem ] = useState<GenericProductItem>();
    const finishScheduling = useCallback((update: GenericProductItem) => {
        setProductItems(oldItems => oldItems?.map(item => item.id === update.id ? update : item));
        setSchedulingProductItem(undefined);
    }, []);
    const closeScheduling = useCallback(() => setSchedulingProductItem(undefined), []);

    if (!activeItems || !completedItems || !context)
        return null;

    return (
        <div>
            <ScheduleProductModal
                productItem={schedulingProductItem}
                client={client}
                onSchedule={finishScheduling}
                onClose={closeScheduling}
                context={context}
            />
            <h2 className='mt-0 mb-4'>{t('active-title')}</h2>
            <div className='d-flex flex-wrap' style={{ gap: '20px' }}>
                {activeItems.map(productItem => (
                    <ProductItemTile
                        key={productItem.id}
                        productItem={productItem}
                        onSchedule={setSchedulingProductItem}
                        isActive={productItem === schedulingProductItem}
                        hideClientId={client.id}
                    />
                ))}
            </div>
            <h2 className='mt-5 mb-4'>{t('completed-title')}</h2>
            <div className='d-flex flex-wrap' style={{ gap: '20px' }}>
                {completedItems.map(productItem => (
                    <ProductItemTile
                        key={productItem.id}
                        productItem={productItem}
                        hideClientId={client.id}
                    />
                ))}
            </div>
        </div>
    );
}

export type ClientsLocationsContext = {
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE[]) => void;
    locations: BaseLocationFE[];
    addLocation: (location: BaseLocationFE) => void;
};

type ScheduleProductModalProps = Readonly<{
    productItem?: GenericProductItem;
    client: ClientInfoFE;
    onSchedule: (productItem: GenericProductItem) => void;
    onClose: () => void;
    context: ClientsLocationsContext;
}>;

export function ScheduleProductModal({ productItem, client, onSchedule, onClose, context }: ScheduleProductModalProps) {
    const cachedProductItem = useCached(productItem);
    if (!cachedProductItem)
        return null;

    return (
        <Modal show={!!productItem} onHide={onClose} className='fl-modal-600' contentClassName='rounded-4'>
            <Modal.Body style={{ padding: '24px' }}>
                <ScheduleProductModalInner
                    productItem={cachedProductItem}
                    client={client}
                    onSchedule={onSchedule}
                    context={context}
                />
            </Modal.Body>
        </Modal>
    );
}

type ScheduleProductModalInnerProps = Readonly<{
    productItem: GenericProductItem;
    client: ClientInfoFE;
    onSchedule: (productItem: GenericProductItem) => void;
    context: ClientsLocationsContext;
}>;

function ScheduleProductModalInner({ productItem, client, onSchedule, context }: ScheduleProductModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });
    const { state, dispatch } = useScheduling(productItem, client, onSchedule, context.addClients);
    const recurrenceCountLimit = productItem.sessionsCount - productItem.scheduledCount;

    return (<>
        <h2 className='mt-0 mb-4'>{t('schedule-product-title')}</h2>
        {/* TODO Move from modal, so that both modals are in the same modal (or that there is just one modal). Either way, we don't want two modals over each other. */}
        {state.sync && <EventScheduleModal state={state.sync} dispatch={dispatch} />}
        <EventForm
            state={state}
            dispatch={dispatch}
            clients={context.clients}
            locations={context.locations}
            onLocationCreated={context.addLocation}
            isForProduct
            recurrenceCountLimit={recurrenceCountLimit}
        />
        <div className='mt-4 d-flex justify-content-end'>
            <SpinnerButton
                onClick={() => dispatch({ type: 'sync', operation: { type: 'start', isBillLater: false }, fid: FID_FINISH })}
                fetching={state.sync?.fetching}
                fid={FID_FINISH}
            >
                {t('finish-scheduling-button')}
            </SpinnerButton>
        </div>
    </>);
}

const FID_FINISH = 'finish';
