import { useCallback, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { addressToUpsert, defaultEditableAddress, type EditableAddress } from ':frontend/types/Address';
import { optionalStringToPut } from ':frontend/utils/common';
import { NewInvoicingProfileFormInner, type NewInvoicingProfileFormData } from './NewInvoicingProfileForm';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { useAres } from './useAres';
import { MapPinIcon, UsersIcon } from ':components/icons';
import { SpinnerButton } from '../common';
import { ControlledCountrySelect } from '../forms';
import { canonizeEmail } from ':utils/forms';
import { InfoTooltip } from '../forms/buttons';
import { ControlledVatSelect } from '../forms/VatSelect';
import { type Id } from ':utils/id';
import type { AresInfo } from ':utils/lib/ares';
import type { InvoicingProfileGeneralUpsert } from ':utils/entity/invoicing';

type InputType = InvoicingProfileFE;
type FormDataType = NewInvoicingProfileFormData & EditableAddress & {
    cin: string;
    legalName: string;
    tin: string;
    vat: Id;
};

export type GeneralSupplierFormOutput = {
    general: InvoicingProfileGeneralUpsert;
};

function inputToForm(input: InputType): FormDataType {
    return {
        ...(input.address?.toEditable() ?? defaultEditableAddress),
        cin: input.cin ?? '',
        tin: input.tin ?? '',
        vat: input.vat.id,
        legalName: input.legalName ?? '',
        title: input.title,
        locale: input.locale,
        dueDays: input.dueDays,
        invoicingEmail: input.email ?? '',
        hideEmailOnInvoice: input.hideEmailOnInvoice,
    };
}

function formToOutput(data: FormDataType): GeneralSupplierFormOutput {
    return {
        general: {
            address: addressToUpsert(data),
            cin: optionalStringToPut(data.cin),
            tin: optionalStringToPut(data.tin),
            vat: data.vat,
            legalName: optionalStringToPut(data.legalName),
            email: optionalStringToPut(canonizeEmail(data.invoicingEmail)),
            hideEmailOnInvoice: data.hideEmailOnInvoice,
            title: data.title,
            locale: data.locale,
            dueDays: data.dueDays,
        },
    };
}

type GeneralSupplierFormProps = Readonly<{
    input: InputType;
    onSubmit: (output: GeneralSupplierFormOutput) => void;
    saveButton?: React.FC<{ onClick: () => void }>;
    onChange?: (isDirty: boolean) => void;
}>;

export function GeneralSupplierForm({ input, onSubmit, saveButton, onChange }: GeneralSupplierFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });
    const { settings, profiles } = useMaster();

    const form = useForm<FormDataType>({
        defaultValues: inputToForm(input),
    });
    const { register, control, formState: { errors, isDirty }, setValue, reset, handleSubmit } = form;

    function onValid(data: FormDataType) {
        onSubmit(formToOutput(data));
    }

    useEffect(() => onChange?.(isDirty), [ isDirty ]);
    useEffect(() => {
        reset(inputToForm(input));
    }, [ input ]);

    const fillFromAres = useCallback((ares: AresInfo) => {
        ares.legalName && setValue('legalName', ares.legalName, { shouldDirty: true });
        // We don't use tin here. You wouldn't know it, it's going to another form.
        setValue('cin', ares.cin, { shouldDirty: true });
        ares.address?.city && setValue('city', ares.address.city, { shouldDirty: true });
        ares.address?.line1 && setValue('line1', ares.address.line1, { shouldDirty: true });
        ares.address?.postalCode && setValue('postalCode', ares.address.postalCode, { shouldDirty: true });
        ares.address?.country && setValue('country', ares.address.country, { shouldDirty: true });
    }, [ setValue ]);

    const { fetchAres, isFetchingAres } = useAres(fillFromAres);

    // The tax rate options and selected value are automatically adjusted based on the country.
    const country = form.watch('country');

    // If there is only one profile, we show only that profile. Therefore, there's no need to show the name field.
    const isOnlyOneProfile = profiles.length === 1;

    return (
        <Form noValidate onSubmit={handleSubmit(onValid)} className='fl-design'>
            <NewInvoicingProfileFormInner
                control={control}
                errors={errors}
                setValue={setValue}
                watch={form.watch}
                hideName={isOnlyOneProfile}
            />
            <div className='mt-4' />
            <h5 className='mb-0 d-flex align-items-center'><UsersIcon size={24} className='me-3' />{t('invoicing-company-title')}</h5>
            <Row className='mt-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('cin-label')}</Form.Label>
                    <Form.Control
                        {...register('cin')}
                        placeholder={t('cin-placeholder')}
                    />
                </Form.Group>
                {settings.country === 'CZ' && (
                    <Col xs={6} className='d-flex align-items-end'>
                        <SpinnerButton isFetching={isFetchingAres} onClick={() => fetchAres(form.getValues('cin'))}>{t('prefill-from-cin')}</SpinnerButton>
                    </Col>
                )}
            </Row>
            <Row className='mt-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('legal-name-label')}</Form.Label>
                    <Form.Control
                        {...register('legalName')}
                        placeholder={t('legal-name-placeholder')}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={3}>
                    <Form.Label>
                        {t('tin-label')}
                        <InfoTooltip text={t('tin-tooltip')} className='ms-2' />
                    </Form.Label>
                    <Form.Control
                        {...register('tin')}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={3}>
                    <Form.Label>
                        {t('vat-label')}
                        <InfoTooltip text={t('vat-tooltip')} className='ms-2' />
                    </Form.Label>
                    <ControlledVatSelect
                        control={control}
                        name='vat'
                        country={country}
                    />
                </Form.Group>
            </Row>
            <h5 className='mb-0 mt-4 d-flex align-items-center'><MapPinIcon size={24} className='me-3' />{t('invoicing-address-title')}</h5>
            <Form.Group className='mt-3'>
                <Form.Label>{t('city-label')}</Form.Label>
                <Form.Control
                    {...register('city')}
                    placeholder={t('city-placeholder')}
                />
            </Form.Group>
            <Form.Group className='mt-3'>
                <Form.Label>{t('line1-label')}</Form.Label>
                <Form.Control
                    {...register('line1')}
                    placeholder={t('line1-placeholder')}
                />
            </Form.Group>
            <Row className='mt-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('postal-code-label')}</Form.Label>
                    <Form.Control
                        {...register('postalCode')}
                        placeholder={t('postal-code-placeholder')}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('country-label')}</Form.Label>
                    <ControlledCountrySelect
                        control={control}
                        name='country'
                    />
                </Form.Group>
            </Row>
            <>{saveButton?.({ onClick: handleSubmit(onValid) })}</>
        </Form>
    );
}
