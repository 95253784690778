import { useCallback, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EyeIcon } from ':components/icons';
import { SpinnerButton } from ':frontend/components/common';
import { useMaster } from ':frontend/context/UserProvider';
import { useToggle } from ':frontend/hooks';
import { GeneralSupplierForm, type GeneralSupplierFormOutput } from ':frontend/components/settings/GeneralSupplierForm';
import { PersonalizationForm, type PersonalizationFormOutput } from ':frontend/components/settings/PersonalizationForm';
import { InvoicingProfileFE } from ':frontend/types/Invoicing';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { InfoTooltip } from ':frontend/components/forms/buttons';
import { AddProfileButton } from './InvoicingProfilesSettings';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { trpc } from ':frontend/context/TrpcProvider';
import { InvoicingProfileExampleModal } from ':frontend/components/orders/InvoicingProfileExampleModal';

export function InvoicingProfileSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });
    const [ showExampleModal, setShowExampleModal ] = useToggle(false);

    const { profiles, setProfiles } = useMaster();
    const profile = profiles[0];

    const setProfile = useCallback((profile: InvoicingProfileFE) => {
        setProfiles(oldProfiles => {
            const index = oldProfiles.findIndex(p => p.id === profile.id);
            const newProfiles = [ ...oldProfiles ];
            newProfiles[index] = profile;

            return newProfiles;
        });
    }, [ setProfiles ]);

    const [ isAdvancedShown, setIsAdvancedShown ] = useState(false);

    return (
        <div>
            <div className='d-flex align-items-top mb-4'>
                <h1 className='m-0'>{t('page-title')}</h1>
                <div className='flex-grow-1' />
                <div style={{ height: '28.8px' }}>
                    <AddProfileButton className='compact' />
                </div>
                <div style={{ position: 'relative', left: '7px' }}>
                    <InfoTooltip text={t('new-profile-tooltip')} />
                </div>
            </div>
            <InfoCard infoKey='invoicingProfiles' />
            <Card className='mt-4 fl-shadow-sm-dark p-3 border-0'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>{t('general-section-title')}</h2>
                    <Button variant='outline-primary' className='compact' onClick={setShowExampleModal.true}>
                        <EyeIcon size={22} className='me-2' />
                        {t('example-invoice-button')}
                    </Button>
                    <InvoicingProfileExampleModal profile={showExampleModal ? profile : undefined} onHide={setShowExampleModal.false} />
                </div>
                <InvoicingProfileFormPart type='general' profile={profile} setProfile={setProfile} />
            </Card>
            <Card className='mt-4 fl-shadow-sm-dark p-3 border-0'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h2 className='m-0'>{t('advanced-section-title')}</h2>
                    <Button variant='outline-primary' className='compact' onClick={() => setIsAdvancedShown(!isAdvancedShown)}>
                        {t(isAdvancedShown ? 'hide-advanced-button' : 'show-advanced-button')}
                    </Button>
                </div>
                {isAdvancedShown && (
                    <div className='mt-3'>
                        <InvoicingProfileFormPart type='advanced' profile={profile} setProfile={setProfile} />
                    </div>
                )}
            </Card>
        </div>
    );
}

const formParts = {
    general: GeneralSupplierForm,
    advanced: PersonalizationForm,
};

type InvoicingProfileFormPartProps = {
    readonly type: keyof typeof formParts;
    readonly profile: InvoicingProfileFE;
    readonly setProfile: (newProfile: InvoicingProfileFE) => void;
};

function InvoicingProfileFormPart({ type, profile, setProfile }: InvoicingProfileFormPartProps) {
    const { addAlert } = useNotifications();
    const [ isDirty, setIsDirty ] = useState(false);

    const updateInvoicingProfileMutation = trpc.invoicing.updateInvoicingProfile.useMutation();

    function onSubmit(edit: GeneralSupplierFormOutput | PersonalizationFormOutput) {
        updateInvoicingProfileMutation.mutate({ id: profile.id, ...edit }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setProfile(InvoicingProfileFE.fromServer(response));
                addAlert(createTranslatedSuccessAlert('pages:invoicing-profile.save-success-alert'));
            },
        });
    }

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => (
        <SaveButton onClick={onClick} isFetching={updateInvoicingProfileMutation.isPending} disabled={!isDirty} />
    ), [ updateInvoicingProfileMutation.isPending, isDirty ]);

    const InnerForm = formParts[type];

    return (
        <InnerForm
            onSubmit={onSubmit}
            input={profile}
            onChange={setIsDirty}
            saveButton={saveButton}
        />
    );
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isFetching: boolean;
    disabled: boolean;
}>;

function SaveButton({ onClick, isFetching, disabled }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'invoicing-profile' });

    return (
        <SpinnerButton
            type='submit'
            isFetching={isFetching}
            variant='primary'
            onClick={onClick}
            disabled={disabled}
            className='mt-4'
        >
            {t('save-button')}
        </SpinnerButton>
    );
}
