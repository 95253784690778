import { type CurrencyFE } from ':frontend/modules/money/types';
import { type Id } from ':utils/id';
import { getCurrency } from ':frontend/modules/money';
import type { AppUserSettingsOutput, TeamSettingsOutput } from ':utils/entity/settings';
import type { CountryCode, LocaleCode, TimezoneCode } from ':utils/i18n';

export class TeamSettingsFE {
    private constructor(
        readonly id: Id,
        readonly currency: CurrencyFE,
        readonly isInvoicingEnabled: boolean,
        readonly paymentEmail: string | undefined,
    ) {}

    static fromServer(input: TeamSettingsOutput) {
        return new TeamSettingsFE(
            input.id,
            getCurrency(input.currencyId),
            input.isInvoicingEnabled,
            input.paymentEmail,
        );
    }
}

/**
 * This type represents settings that are not yet implemented on the backend, so they are hard-coded here.
 */
const NOT_INCLUDED_SETTINGS = {
    dateFormat: 'dd. MM. yyyy',
    dateTimeFormat: 'dd. MM. yyyy HH:mm',
};

export class AppUserSettingsFE {
    private constructor(
        readonly id: Id,
        readonly timezone: TimezoneCode,
        readonly locale: LocaleCode,
        readonly country: CountryCode,
        readonly dateFormat: string,
        readonly dateTimeFormat: string,
    ) {}

    static fromServer(input: AppUserSettingsOutput) {
        return new AppUserSettingsFE(
            input.id,
            input.timezone,
            input.locale,
            input.country,
            NOT_INCLUDED_SETTINGS.dateFormat,
            NOT_INCLUDED_SETTINGS.dateTimeFormat,
        );
    }
}
