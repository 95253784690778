import { deepClone, deepEquals, zColor } from ':utils/common';
import { z } from 'zod';
import { zFileOutput, zFileUpsert } from './file';

export const storeSocialTypes = [ 'facebook', 'twitter', 'instagram', 'linkedin', 'youtube', 'other' ] as const;

export type StoreSocial = z.infer<typeof zStoreSocial>;
const zStoreSocial = z.object({
    type: z.enum(storeSocialTypes),
    url: z.string(),
    title: z.string(),
});

export type StoreBio = z.infer<typeof zStoreBio>;
const zStoreBio = z.object({
    name: z.string(),
    headline: z.string().optional(),
    description: z.string().optional(),
    socials: z.array(zStoreSocial),
});

export type StoreDesign = z.infer<typeof zStoreDesign>;
export const zStoreDesign = z.object({
    background: z.object({
        type: z.enum([ 'solid', 'gradient' ]),
        color: zColor,
        /** For gradients. */
        secondColor: zColor,
    }),
    card: z.object({
        corners: z.enum([ 'square', 'rounded' ]),
        shadow: z.enum([ 'none', 'soft', 'hard' ]),
        button: z.enum([ 'square', 'rounded', 'pill' ]),
        buttonBackground: zColor,
        buttonColor: zColor,
    }),
    font: z.string(),
    other: z.object({
        hideFlowlanceBadge: z.boolean(),
    }),
});

export function cloneStoreDesign(design: StoreDesign): StoreDesign {
    return deepClone(design);
}

export function isStoreDesignEqual(design: StoreDesign, original: StoreDesign): boolean {
    return deepEquals(design, original);
}

export type StoreOutput = z.infer<typeof zStoreOutput>;
export const zStoreOutput = z.object({
    urlName: z.string(),
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileOutput.optional(),
});

/** Like PATCH (then like PUT for nested objects). */
export type StoreEdit = z.infer<typeof zStoreEdit>;
export const zStoreEdit = z.object({
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileUpsert.nullable(),
}).partial();
