import { useCallback, useState } from 'react';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import type { AresInfo } from ':utils/lib/ares';
import { api } from ':frontend/utils/api';

export function useAres(callback: (ares: AresInfo) => void) {
    const [ isFetchingAres, setIsFetchingAres ] = useState(false);
    const { addAlert } = useNotifications();

    const fetchAres = useCallback(async (inputCin?: string) => {
        const cin = inputCin?.replace(/\s/g, '');
        if (!cin)
            return;

        setIsFetchingAres(true);
        const response = await api.other.getAresInfo({ cin });
        setIsFetchingAres(false);

        if (!response.status) {
            addAlert(createErrorAlert(response.error));
            return;
        }

        addAlert(createTranslatedSuccessAlert('components:invoicingForm.ares-success-alert'));
        callback({ ...response.data, cin });
    }, [ callback, addAlert ]);

    return { fetchAres, isFetchingAres };
}
