import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { FlowlanceBanner, QuoteEnd, QuoteStart } from ':components/icons';
import clsx from 'clsx';
import { RegisterState, useRegisterEmail } from ':frontend/components/auth/useRegisterEmail';
import RegisterUserForm, { type RegisterError, type UserFormData } from ':frontend/components/auth/RegisterUserForm';
import RegisterPersonForm, { type PersonFormData } from ':frontend/components/auth/RegisterPersonForm';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';

export default function Register() {
    const { state, userData, isFetching, submitUserForm, submitPersonForm, onBack } = useRegisterEmail();

    return (
        <div className='fl-unauthorized-page'>
            <div className='centering-wrapper'>
                {state === RegisterState.UserForm && (
                    <RegisterLanding userData={userData} isFetching={isFetching} submitUserForm={submitUserForm} />
                )}
                {state === RegisterState.PersonForm && (
                    <RegisterEmail isFetching={isFetching} submitPersonForm={submitPersonForm} onBack={onBack}/>
                )}
            </div>
        </div>
    );
}

type RegisterLandingProps = Readonly<{
    userData?: UserFormData;
    isFetching: boolean;
    submitUserForm: (output: UserFormData) => Promise<RegisterError[]>;
}>;

function RegisterLanding({ userData, isFetching, submitUserForm }: RegisterLandingProps) {
    return (
        <div className='fl-unauthorized-card fl-register-card overflow-hidden d-flex flex-wrap align-items-stretch'>
            <div className='fl-register-panel'>
                <div className='fl-register-panel-content'>
                    <RegisterContent
                        userData={userData}
                        isFetching={isFetching}
                        submitUserForm={submitUserForm}
                    />
                </div>
            </div>
            <div className='fl-register-panel' style={{ backgroundColor: '#F5F5F5' }}>
                <div className='fl-register-panel-content d-flex align-items-center'>
                    <RegisterImage />
                </div>
            </div>
        </div>
    );
}

function RegisterContent({ userData, isFetching, submitUserForm }: RegisterLandingProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register.landing' });

    return (
        <div className='d-flex flex-column align-items-center h-100'>
            <FlowlanceBanner />
            <h1>{t('page-title')}</h1>
            <GoogleOauthButton type='register' />
            <DividerWithLabel label={t('or-label')} className='my-3' />
            <RegisterUserForm
                defaultValues={userData}
                onSubmit={submitUserForm}
                isFetching={isFetching}
            />
            <div className='mt-3'>
                <Trans
                    i18nKey='login-link'
                    t={t}
                    components={{ a: <Link to={routesFE.login.index.path} /> }}
                />
            </div>
            <div className='flex-grow-1' />
            <div className='text-center text-secondary'>
                <Trans
                    i18nKey='terms-gdpr-text'
                    t={t}
                    components={{
                        a1: <Link className='text-secondary text-nowrap' to='/VOP.pdf' target='_blank' rel='noreferrer' />,
                        a2: <Link className='text-secondary text-nowrap' to='/GDPR.pdf' target='_blank' rel='noreferrer' />,
                    }}
                />
            </div>
        </div>
    );
}

export function DividerWithLabel({ label, className }: Readonly<{ label: string, className?: string }>) {
    return (
        <div className={clsx('d-flex align-items-center w-100', className)}>
            <div className='fl-divider-light flex-grow-1' />
            <span className='px-3'>{label}</span>
            <div className='fl-divider-light flex-grow-1' />
        </div>
    );
}

function RegisterImage() {
    const { t } = useTranslation('pages', { keyPrefix: 'register.landing' });

    return (
        <div className='d-flex flex-column gap-2'>
            <QuoteStart size={17} />
            <div className='fw-medium lha-3' style={{ fontSize: '17px' }}>
                {t('testimonial-text')}
            </div>
            <QuoteEnd size={17} className='align-self-end' />
            <div className='d-flex align-items-center' style={{ gap: '20px' }}>
                <img src='/static/images/registration/testimonial-icon.png' className='non-draggable' />
                <div className='pre-line'>
                    {t('testimonial-author')}
                </div>
            </div>
            <div className='d-flex justify-content-between mt-5 flex-wrap gap-row-3'>
                <a href='https://www.producthunt.com/products/flowlance?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-flowlance' target='_blank' rel='noreferrer'>
                    <img src='https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=557444&theme=light' alt='Flowlance - Automatic payments from your calendar | Product Hunt' width='200' height='43' />
                </a>
                <a href='https://www.capterra.com/p/10016027/Flowlance/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'>
                    <img src='https://assets.capterra.com/badge/45f1b90c47068cfe8666487d5d1d8a00.svg?v=2305556&p=357928' height='43px' />
                </a>
                <a href='https://www.getapp.com/finance-accounting-software/a/flowlance/reviews/'>
                    <img src='https://www.getapp.com/ext/reviews_widget/v1/dark/flowlance-application' height='43px' />
                </a>
            </div>
        </div>
    );
}

type RegisterEmailProps = Readonly<{
    isFetching: boolean;
    submitPersonForm: (output: PersonFormData) => Promise<void>;
    onBack: () => void;
}>;

function RegisterEmail({ isFetching, submitPersonForm, onBack }: RegisterEmailProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'register.email' });

    return (
        <div className='fl-unauthorized-card'>
            <div className='fl-unauthorized-content d-flex flex-column align-items-center justify-content-center'>
                <FlowlanceBanner />
                <h1>{t('page-title')}</h1>
                <RegisterPersonForm
                    onSubmit={submitPersonForm}
                    isFetching={isFetching}
                />
                <Link to='#' onClick={onBack} className='link-dark mt-3'>
                    {t('go-back-link')}
                </Link>
            </div>
        </div>
    );
}
