import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import useNotifications from ':frontend/context/NotificationProvider';
import { useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import type { AppUserEdit } from ':utils/entity/user';
import { optionalStringToPut } from ':frontend/utils/common';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { AppUserFE } from ':frontend/types/AppUser';

export type PersonFormData = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
};

export function PersonForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { addAlert } = useNotifications();
    const { appUser, setAppUser } = useUser();

    const { register, handleSubmit } = useForm<PersonFormData>({ defaultValues: {
        firstName: appUser.firstName,
        lastName: appUser.lastName,
        phoneNumber: appUser.phoneNumber,
    } });

    const updateAppUserMutation = trpc.user.updateAppUser.useMutation();

    function onPersonSubmit(data: PersonFormData) {
        const edit: AppUserEdit = {
            ...data,
            phoneNumber: optionalStringToPut(data.phoneNumber),
        };
        updateAppUserMutation.mutate(edit, {
            onError: () => {
                setAppUser({ ...appUser });
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setAppUser(AppUserFE.fromServer(response));
                addAlert(createTranslatedSuccessAlert('pages:settings.general.generic-settings-success'));
            },
        });
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onPersonSubmit)} className='fl-design'>
            <Row className='gap-row-3'>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('first-name-label')}</Form.Label>
                    <Form.Control {...register('firstName')} />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('last-name-label')}</Form.Label>
                    <Form.Control {...register('lastName')} />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('email-label')}</Form.Label>
                    <Form.Control
                        value={appUser.email}
                        disabled
                        readOnly
                    />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                    <Form.Label>{t('phone-label')}</Form.Label>
                    <Form.Control {...register('phoneNumber')} />
                </Form.Group>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <SpinnerButton
                        type='submit'
                        isFetching={updateAppUserMutation.isPending}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
}
