import { PersonForm } from ':frontend/components/settings/PersonForm';
import { PreferencesForm } from ':frontend/components/settings/PreferencesForm';
import { PasswordForm } from ':frontend/components/settings/PasswordForm';
import { useUser } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

export function GeneralSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { appUser } = useUser();

    return (
        <div className='pb-5'>
            {/* TODO public store forms */}
            <h1 className='mt-0'>{t('page-title')}</h1>
            <Card className='mt-4 fl-shadow-sm-dark p-3 border-0'>
                <PersonForm />
            </Card>
            <Card className='mt-4 fl-shadow-sm-dark p-3 border-0'>
                <h2 className='mt-0 mb-3'>{t('preferences-section-title')}</h2>
                <PreferencesForm />
            </Card>
            {!appUser.google.isAuth && (
                <Card className='mt-4 fl-shadow-sm-dark p-3 border-0'>
                    <h2 className='mt-0 mb-3'>{t('password-section-title')}</h2>
                    <PasswordForm />
                </Card>
            )}
        </div>
    );
}
