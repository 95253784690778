import { zCountryCode, type CountryCode } from ':utils/i18n';
import { zId, type CurrencyId, type TaxRateId } from ':utils/id';
import { z } from 'zod';
import { zRawBankAccountData } from './bankAccountData';

export type CurrencyOutput = {
    id: CurrencyId;
    stripeMinimalCharge: number;
    stripeMaximalCharge: number;
};

export type TaxRateOutput = {
    id: TaxRateId;
    name: string;
    value: number;
    inclusive: boolean;
    country?: CountryCode;
    enabled: boolean;
};

export const zCurrencyId = z.string().transform(value => value as CurrencyId);
export const zTaxRateId = z.string().transform(value => value as TaxRateId);

export type BankAccountOutput = z.infer<typeof zBankAccountOutput>;
export const zBankAccountOutput = z.object({
    id: zId,
    label: z.string(),
    iban: z.string().optional(),
    swift: z.string().optional(),
    country: zCountryCode,
    raw: zRawBankAccountData,
    currencies: z.array(zCurrencyId),
});

export type BankAccountUpsert = z.infer<typeof zBankAccountUpsert>;
export const zBankAccountUpsert = z.object({
    raw: zRawBankAccountData,
    currencies: z.array(z.string()),
});
