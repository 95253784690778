import { useCallback } from 'react';
import FormSelect from './FormSelect';
import { timezoneToOption, TIMEZONE_OPTIONS } from ':frontend/types/i18n';
import { useTranslation } from 'react-i18next';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import type { TimezoneCode } from ':utils/i18n';

type TimezoneSelectProps = {
    value?: TimezoneCode;
    onChange: (value?: TimezoneCode) => void;
    disabled?: boolean;
};

//const MIN_LETTERS_TO_OPEN = 2;

export function TimezoneSelect({ value, onChange, disabled }: TimezoneSelectProps) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    //const [ menuIsOpen, setMenuIsOpen ] = useState(false);

    return (
        <FormSelect
            placeholder={t('timezone-placeholder')}
            options={TIMEZONE_OPTIONS}
            value={value ? timezoneToOption(value) : undefined}
            onChange={option => onChange(option?.value)}
            isDisabled={disabled}
            //menuIsOpen={menuIsOpen}
            //onInputChange={newValue => setMenuIsOpen(newValue !== undefined && newValue.length >= MIN_LETTERS_TO_OPEN)}
        />
    );
}

type ControlledTimezoneSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    disabled?: boolean;
};

export function ControlledTimezoneSelect<TFieldValues extends FieldValues>({ control, name, disabled }: ControlledTimezoneSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: TimezoneCode, onChange: (value?: TimezoneCode ) => void } }) => {
        return (
            <TimezoneSelect
                value={field.value}
                onChange={field.onChange}
                disabled={disabled}
            />
        );
    }, []);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}
