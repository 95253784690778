export type GoogleUserFromServer = {
    /** it's just a number (but in a string) */
    id: string;
    email: string;
    verified_email: boolean;
    name: string;
    given_name: string;
    family_name: string;
    /** e.g. https://lh3.googleusercontent.com/a/ACg8ocIQcq_ABUEqOUBfJ77LtwFjAAMC66fdk8UnFv8JRJX5MZc=s96-c */
    picture: string;
    /** e.g. 'en' */
    locale: string;
};

export class GoogleUser {
    private constructor(
        readonly email: string,
    ) {}

    static fromServer(input: GoogleUserFromServer): GoogleUser {
        return new GoogleUser(
            input.email,
        );
    }
}
