import { useEffect } from 'react';
import { useUser } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { OnboardingArrowIcon, SmallCheckIcon } from ':components/icons';
import clsx from 'clsx';
import { routesFE } from ':utils/routes';
import { useNavigate } from 'react-router-dom';
import { trpc } from ':frontend/context/TrpcProvider';
import { OnboardingFlag } from ':utils/entity/user';

type OnboardingJourneyProps = Readonly<{
    onNewProduct: () => void;
}>;

export function OnboardingJourney({ onNewProduct }: OnboardingJourneyProps) {
    const { t } = useTranslation('components', { keyPrefix: 'onboarding.journey' });
    const { onboarding } = useUser();

    const utils = trpc.useUtils();

    useEffect(() => {
        utils.user.getOnboarding.invalidate();
    }, [ utils ]);

    return (
        <div className='d-flex gap-2'>
            <div className='d-flex align-items-center position-relative'>
                <div className='position-absolute' style={{ left: '-102px' }}>
                    <OnboardingArrowIcon variant={0} />
                </div>
                <h2 className='m-0 fs-semibold'>{t('title')}</h2>
            </div>
            {steps.map(step => (
                <OnboardingTile
                    key={step.id}
                    step={step}
                    isCompleted={onboarding.flags[step.id]}
                    onNewProduct={onNewProduct}
                />
            ))}
        </div>
    );
}

type StepDefinition = Readonly<{ id: OnboardingFlag, iconVariant: number, route: string }>;

const steps: StepDefinition[] = [
    { id: OnboardingFlag.ClientsCreated, iconVariant: 1, route: routesFE.clients.list },
    { id: OnboardingFlag.StripeOrBankAccount, iconVariant: 2, route: routesFE.payments.path },
    { id: OnboardingFlag.SettingsOpened, iconVariant: 3, route: routesFE.settings.resolve({ key: 'general' }) },
    { id: OnboardingFlag.ProductCreatedOrUpdated, iconVariant: 4, route: '' },
];

type OnboardingTileProps = Readonly<{
    step: StepDefinition;
    isCompleted: boolean;
    onNewProduct: () => void;
}>;

function OnboardingTile({ step, isCompleted, onNewProduct }: OnboardingTileProps) {
    const { t } = useTranslation('components', { keyPrefix: `onboarding.journey.${step.id}` });
    const navigate = useNavigate();

    function click() {
        if (step.id === OnboardingFlag.ProductCreatedOrUpdated)
            onNewProduct();
        else
            navigate(step.route);
    }

    return (
        <button className={clsx('fl-onboarding-tile border rounded text-start flex-shrink-0', isCompleted && 'completed' )} disabled={isCompleted} onClick={click}>
            <div style={{ height: '17px' }}>
                {isCompleted ? (
                    <div className='rounded-circle bg-black text-white d-flex align-items-center justify-content-center' style={{ width: '16px', height: '16px' }}>
                        <SmallCheckIcon size={10} />
                    </div>
                ) : (
                    <OnboardingArrowIcon variant={step.iconVariant} className='text-primary' />
                )}
            </div>
            <h3 className='step-title fs-base'>{t('title')}</h3>
            <div className='step-description fs-small'>{t('description')}</div>
        </button>
    );
}
