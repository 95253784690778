import { useMemo, useState, type Dispatch, type SetStateAction } from 'react';

export type UseToggleSet = {
    value: Dispatch<SetStateAction<boolean>>;
    true: () => void;
    false: () => void;
    toggle: () => void;
};

export type UseToggleReturn = [ boolean, UseToggleSet ];

export function useToggle(initialValue: boolean | (() => boolean)): UseToggleReturn {
    const [ value, setValue ] = useState(initialValue);
    
    const generalSetValue = useMemo(() => ({
        value: setValue,
        true: () => setValue(true),
        false: () => setValue(false),
        toggle: () => setValue(oldValue => !oldValue),
    }), []);

    return [ value, generalSetValue ];
}
