import { z } from 'zod';
import { zId } from ':utils/id';
import { zFileOutput, zFileUpsert } from './file';
import { zLocationOutput } from './location';


export enum ProductType {
    Session = 'session',
    SessionBundle = 'sessionBundle',
    LeadMagnet = 'leadMagnet',
    Digital = 'digital',
    Custom = 'custom',
}

export const productColors: {[key in ProductType]: string} = {
    [ProductType.Session]: '#FFD700',
    [ProductType.SessionBundle]: '#FFD700',
    [ProductType.LeadMagnet]: '#FFD700',
    [ProductType.Digital]: '#FFD700',
    [ProductType.Custom]: '#FFD700',
};

const zBaseProductInit = z.object({
    title: z.string(),
    thumbnail: zFileUpsert.optional(),
    description: z.string().optional(),
    limit: z.number().optional(),
});

export type PriceProductInit = z.infer<typeof zPriceProductInit>;
const zPriceProductInit = zBaseProductInit.merge(z.object({
    price: z.number(),
    discountPrice: z.number().optional(),
    currency: zId,
    vat: zId,
}));

export const zSessionProductInit = zBaseProductInit.merge(zPriceProductInit).merge(z.object({
    type: z.literal(ProductType.Session),
    sessionsDuration: z.number(),
    locationId: zId.optional(),
}));

export const zSessionBundleProductInit = zBaseProductInit.merge(zPriceProductInit).merge(zSessionProductInit).merge(z.object({
    type: z.literal(ProductType.SessionBundle),
    sessionsCount: z.number(),
}));

export const zLeadMagnetProductInit = zBaseProductInit.merge(z.object({
    type: z.literal(ProductType.LeadMagnet),
}));

export const zDigitalProductInit = zBaseProductInit.merge(zPriceProductInit).merge(z.object({
    type: z.literal(ProductType.Digital),
    url: z.string(),
}));

export const zCustomProductInit = zBaseProductInit.merge(zPriceProductInit).merge(z.object({
    type: z.literal(ProductType.Custom),
}));

export type ProductInit = z.infer<typeof zProductInit>;
export const zProductInit = z.discriminatedUnion('type', [
    zSessionProductInit,
    zSessionBundleProductInit,
    zLeadMagnetProductInit,
    zDigitalProductInit,
    zCustomProductInit,
]);

export const zBaseProductOutput = z.object({
    id: zId,
    title: z.string(),
    thumbnail: zFileOutput.optional(),
    description: z.string().optional(),
    limit: z.number().optional(),
});
export const zPriceProductOutput = z.object({
    price: z.number(),
    discountPrice: z.number().optional(),
    currency: zId,
    vat: zId.optional(),
});

export type SessionProductOutput = z.infer<typeof zSessionProductOutput>;
export const zSessionProductOutput = zBaseProductOutput.merge(zPriceProductOutput).merge(z.object({
    type: z.literal(ProductType.Session),
    sessionsDuration: z.number(),
    location: zLocationOutput.optional(),
}));

export type SessionBundleProductOutput = z.infer<typeof zSessionBundleProductOutput>;
export const zSessionBundleProductOutput = zSessionProductOutput.merge(z.object({
    type: z.literal(ProductType.SessionBundle),
    sessionsCount: z.number(),
}));

export type LeadMagnetProductOutput = z.infer<typeof zLeadMagnetProductOutput>;
export const zLeadMagnetProductOutput = zBaseProductOutput.merge(z.object({
    type: z.literal(ProductType.LeadMagnet),
}));

export type DigitalProductOutput = z.infer<typeof zDigitalProductOutput>;
export const zDigitalProductOutput = zBaseProductOutput.merge(zPriceProductOutput).merge(z.object({
    type: z.literal(ProductType.Digital),
    url: z.string(),
}));

export type CustomProductOutput = z.infer<typeof zCustomProductOutput>;
export const zCustomProductOutput = zBaseProductOutput.merge(zPriceProductOutput).merge(z.object({
    type: z.literal(ProductType.Custom),
}));

export type ProductOutput = z.infer<typeof zProductOutput>;
export const zProductOutput = z.discriminatedUnion('type', [
    zSessionProductOutput,
    zSessionBundleProductOutput,
    zLeadMagnetProductOutput,
    zDigitalProductOutput,
    zCustomProductOutput,
]);

// Edit DTOs like PUT
const zBaseProductEdit = z.object({
    id: zId,
    title: z.string(),
    thumbnail: zFileUpsert.optional(),
    description: z.string().optional(),
    limit: z.number().optional(),
});

const zPriceProductEdit = zBaseProductEdit.merge(z.object({
    price: z.number(),
    discountPrice: z.number().optional(),
    currency: zId,
    vat: zId,
}));

export const zSessionProductEdit = zPriceProductEdit.merge(z.object({
    type: z.literal(ProductType.Session),
    sessionsDuration: z.number(),
    locationId: zId.optional(),
}));

export const zSessionBundleProductEdit = zSessionProductEdit.merge(z.object({
    type: z.literal(ProductType.SessionBundle),
    sessionsCount: z.number(),
}));

export const zLeadMagnetProductEdit = zBaseProductEdit.merge(z.object({
    type: z.literal(ProductType.LeadMagnet),
}));

export const zDigitalProductEdit = zPriceProductEdit.merge(z.object({
    type: z.literal(ProductType.Digital),
    url: z.string(),
}));

export const zCustomProductEdit = zPriceProductEdit.merge(z.object({
    type: z.literal(ProductType.Custom),
}));

export type ProductEdit = z.infer<typeof zProductEdit>;
export const zProductEdit = z.discriminatedUnion('type', [
    zSessionProductEdit,
    zSessionBundleProductEdit,
    zLeadMagnetProductEdit,
    zDigitalProductEdit,
    zCustomProductEdit,
]);