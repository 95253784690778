import { compareStringsAscii } from ':frontend/utils/common';
import type { Id } from ':utils/id';
import type { ClientTagOutput } from ':utils/entity/client';

export class ClientTagFE {
    private constructor(
        readonly id: Id,
        readonly name: string,
        /** Hex code (6 characters). */
        readonly color: string,
    ) {
    }

    static fromServer(input: ClientTagOutput): ClientTagFE {
        return new ClientTagFE(
            input.id,
            input.name,
            input.color,
        );
    }
}

export function sortClientTags(tags: ClientTagFE[]): ClientTagFE[] {
    return tags.sort((a, b) => compareStringsAscii(a.id, b.id));
}
