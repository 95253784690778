import { forwardRef, type ReactNode } from 'react';
import useAuth from ':frontend/context/AuthProvider';
import { Button, Dropdown } from 'react-bootstrap';
import { CircleUserIcon, FlowlanceLogo } from ':components/icons';
import { SubMenuItem, overviewMenuItems, isActive, userMenuItems } from './Menu';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import clsx from 'clsx';
import { SpinnerButton } from '../common';
import { useSubscriptionUpsert } from ':frontend/pages/Subscriptions';
import { getAppUserName, getAppUserShortName } from ':utils/entity/user';
import { TeamMemberRole } from ':utils/entity/team';
import { SubscriptionTierCode, SubscriptionTierPaymentPeriod } from ':utils/entity/subscription';

export default function Topbar() {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const location = useLocation();
    const userContext = useUser();
    const { role, team } = userContext;
    const isMasterOrFreelancer = !!toMaster(userContext);

    return (
        <div className='fl-topbar'>
            <div className='fl-topbar-content'>
                <div className='fl-topbar-left'>
                    <Link to={routesFE.root} className='d-inline-block text-reset text-decoration-none pe-2'>
                        <FlowlanceLogo size={40} /><span className='ms-2 fw-bold align-middle' style={{ fontSize: '18px' }}>Flowlance</span>
                    </Link>
                    {role !== TeamMemberRole.freelancer && (
                        <span className='fw-bold text-primary' style={{ fontSize: '18px' }}>
                            {team.title}
                        </span>
                    )}
                </div>
                <div className='fl-topbar-center'>
                    <div className='fl-tab-buttons'>
                        {overviewMenuItems.filter(item => !item.roles || item.roles.includes(role)).map(item => {
                            const isHere = item.to === location.pathname;
                            const isSelected = isActive(item, location.pathname);

                            return (
                                <Link
                                    key={item.nameTranslationId}
                                    className={clsx('fl-tab-button', isSelected && 'selected', isHere && 'pe-none')}
                                    to={item.to}
                                >
                                    {t(item.nameTranslationId)}
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className='fl-topbar-right'>
                    <div className='flex-shrink-0'>
                        <AppUserIcon />
                    </div>
                    {isMasterOrFreelancer && (<>
                        <Link to={routesFE.orders.newCustom}>
                            <Button variant='outline-primary' className='compact'>{t('custom-order')}</Button>
                        </Link>
                        <Link to={routesFE.orders.newBackpay}>
                            <Button variant='outline-primary' className='compact'>{t('backpay-order')}</Button>
                        </Link>
                    </>)}
                </div>
            </div>
        </div>
    );
}

function AppUserIcon() {
    const userContext = useUser();
    const { subscription, appUser } = userContext;
    const isMasterOrFreelancer = !!toMaster(userContext);
    const { auth } = useAuth();
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });

    const { buySubscription, isFetching } = useSubscriptionUpsert();

    const showUpgradeButton = isMasterOrFreelancer && (!subscription || subscription.code !== SubscriptionTierCode.paid2 || subscription.isTrial);

    return (
        <Dropdown drop='down'>
            <Dropdown.Toggle as={CustomDropdownToggle}>
                <div className='fl-navigation-button rounded-4 py-1 ps-3 pe-3 d-flex align-items-center'>
                    <div className='me-3'>{getAppUserShortName(appUser)}</div>
                    <div><CircleUserIcon size={24} /></div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='fl-topbar-menu' style={{ width: 247, '--bs-dropdown-padding-y': 0 }}>
                <div className='py-3 px-3 border-bottom'>
                    <div className='fw-medium mb-1'>{getAppUserName(appUser)}</div>
                    <div className='text-muted'>{appUser.email}</div>
                </div>
                <div>
                    {userMenuItems.map(item => (
                        <SubMenuItem key={item.to} {...item} />
                    ))}
                </div>
                <div className='d-flex flex-column gap-2 border-top px-3 py-3 bg-border-light rounded-bottom-dropdown'>
                    <Button variant='outline-primary' onClick={() => auth.logout()}>{t('logout')}</Button>
                    {showUpgradeButton && (
                        <SpinnerButton
                            isFetching={isFetching}
                            variant='primary'
                            className='w-100'
                            onClick={() => buySubscription(SubscriptionTierCode.paid2, SubscriptionTierPaymentPeriod.yearly)}
                        >
                            {t('upgrade-to-pro')}
                        </SpinnerButton>
                    )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

type CustomDropdownToggleProps = {
    children: ReactNode;
    onClick: () => void;
};

const CustomDropdownToggle = forwardRef<HTMLDivElement, CustomDropdownToggleProps>(({ children, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
        {children}
    </div>
));
