import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { Modal } from 'react-bootstrap';
import { useClients, useProducts } from ':frontend/hooks';
import { type UseNewProductOrderDispatch, useNewProductOrder } from ':frontend/components/orders/useNewProductOrder';
import ProductModal, { type ProductModalControl, useProductModal } from ':frontend/components/product/ProductModal';
import { NewProductTile, ProductTile, ProductTileSkeletons } from ':frontend/components/product/productDisplay';
import { type ProductFE } from ':frontend/types/Product';
import { NewProductOrderInner } from ':frontend/components/orders/NewProductOrder';
import { OnboardingJourney } from ':frontend/components/onboarding/OnboardingJourney';
import { OrdersStatsDisplay } from ':frontend/components/orders/OrdersStatsDisplay';

export default function MasterDashboard() {
    const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
    const { onboarding } = useMaster();
    const { clients, addClients } = useClients();

    const { state: productModalState, control } = useProductModal();
    const { state: newProductOrderState, dispatch } = useNewProductOrder();
    const isEditOrSellModalOpen = newProductOrderState.isModalOpen || (productModalState.isShow && !!productModalState.product);
    const { products, updateProducts } = useProducts();

    return (
        <div className='fl-main-scroller' style={{ backgroundColor: '#F5F5F5' }}>
            <div className='container-dashboard pb-5'>
                {!onboarding.isFlagsFinished && (
                    <div style={{ padding: '16px 0px' }}>
                        <OnboardingJourney onNewProduct={control.startCreating} />
                    </div>
                )}
                <h2 style={{ margin: '24px 0px' }}>{t('products-title')}</h2>
                {!products && (
                    <div className='d-flex flex-wrap' style={{ gap: '20px' }}>
                        <ProductTileSkeletons variant='product' />
                    </div>
                )}
                {products && (<>
                    <ProductsList
                        products={products}
                        control={control}
                        dispatch={dispatch}
                        isModalOpen={isEditOrSellModalOpen}
                    />
                    <ProductModal state={productModalState} onClose={control.close} onProductUpdate={updateProducts} />
                    <Modal
                        show={newProductOrderState.isModalOpen}
                        className='fl-checkout-modal'
                    >
                        <Modal.Body className='p-0'>
                            <NewProductOrderInner
                                clients={clients ?? []}
                                addClients={addClients}
                                products={ products }
                                state={newProductOrderState}
                                dispatch={dispatch}
                            />
                        </Modal.Body>
                    </Modal>
                </>)}
                <h2 style={{ margin: '48px 0px 24px 0px' }}>{t('stats-title')}</h2>
                <OrdersStatsDisplay />
            </div>
        </div>
    );
}

type ProductsListProps = Readonly<{
    products: ProductFE[];
    control: ProductModalControl;
    dispatch: UseNewProductOrderDispatch;
    isModalOpen: boolean;
}>;

function ProductsList({ products, control, dispatch, isModalOpen }: ProductsListProps) {
    const [ lastClicked, setLastClicked ] = useState<ProductFE>();

    const productAction = useCallback((product: ProductFE, action: 'edit' | 'sell') => {
        setLastClicked(product);

        if (action === 'edit')
            control.startEditing(product);
        else
            dispatch({ type: 'sellProduct', product });
    }, [ control, dispatch ]);

    return (
        <div className='d-flex flex-wrap' style={{ gap: '20px' }}>
            {products.map(product => (
                <ProductTile
                    key={product.id}
                    product={product}
                    on={productAction}
                    isActive={isModalOpen && product === lastClicked}
                />
            ))}
            <NewProductTile onClick={control.startCreating} />
        </div>
    );
}
