// This is kosher because this is the one use case in which the {} type actually means something.
// eslint-disable-next-line @typescript-eslint/ban-types
export type EmptyIntersection = {};
export type EmptyObject = Record<string, never>;

import type { LocaleCode } from ':utils/i18n';
import type { Result } from ':frontend/types/result';
import type { DateTime } from 'luxon';
import type { MouseEvent } from 'react';

type UnionKeys<T> = T extends T ? keyof T : never;

type Expand<T> = T extends T ? { [K in keyof T]: T[K] } : never;

/**
 * Mutually exclusive. OneOf<[A, B, C]> means A xor B xor C. Usable only on objects.
 * @see https://stackoverflow.com/questions/42123407/does-typescript-support-mutually-exclusive-types#comment123255834_53229567
 * You probably want to use discriminated unions unless you're crazy :)
 * @see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#discriminated-unions
 */
export type OneOf<T extends object[]> = {
    [K in keyof T]: Expand<T[K] & Partial<Record<Exclude<UnionKeys<T[number]>, keyof T[K]>, never>>>;
}[number];

export function roundDateToMinutes(inputDate: DateTime, minutes: number): DateTime {
    const rest = inputDate.minute % minutes;
    const minutesToAdd = minutes - rest;
    return inputDate.plus({ minutes: minutesToAdd }).set({ second: 0, millisecond: 0 });
}

export function last<T>(array: T[]): T {
    return array[array.length - 1];
}

export function compareArrays<T>(a: T[], b: T[]): boolean {
    if (a.length !== b.length)
        return false;

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i])
            return false;
    }

    return true;
}

export function compareSets<T>(a: Set<T>, b: Set<T>): boolean {
    if (a.size !== b.size)
        return false;

    for (const value of a.values()) {
        if (!b.has(value))
            return false;
    }

    return true;
}

export function abbreviateStringToLength(originalString: string, length: number): string {
    if (originalString.length <= length)
        return originalString;

    return originalString.slice(0, length - 3) + '...';
}

type SerialFetchResult<T> = {
    lastSuccessfulResult: T | undefined;
    errors: any[];
};

type FetchFunction<T> = () => Promise<Result<T>>;

/**
 * This function does multiple api requests at once (not simultaneously, i.e., one after another). Both the last successful result and the errors are returned.
 *
 * The typical usecase is when we have to call mutliple backend routes to fulfill one user action. In order to avoid race conditions, we have to know the last valid state of the updated resource, hence the function returns the last successful result. We also want to know all errors.
 *
 * @param fetchFunctions Functions that will be called (one after another and in the given order).
 * @param stopOnError If true, the function stops after first unsuccessful request.
 * @returns Last successful result and errors.
 */
export async function serialFetch<T>(fetchFunctions: FetchFunction<T>[], stopOnError = false): Promise<SerialFetchResult<T>> {
    let lastSuccessfulResult: T | undefined = undefined;
    const errors = [];

    for (const fetchFunction of fetchFunctions) {
        const response = await fetchFunction();

        if (response.status) {
            lastSuccessfulResult = 'data' in response ? response.data : undefined;
        }
        else {
            errors.push(response.error);
            if (stopOnError)
                return { lastSuccessfulResult, errors };
        }
    }

    return { lastSuccessfulResult, errors };
}

type ParallelFetchResult<T> = Result<T[]>;

export async function parallelFetch<T>(fetchFunctions: FetchFunction<T>[]): Promise<ParallelFetchResult<T>> {
    const allResponses = await Promise.all(fetchFunctions.map(f => f()));

    const outputData: T[] = [];
    for (const response of allResponses) {
        if (!response.status) {
            return {
                status: false,
                error: response.error,
            };
        }

        if ('data' in response)
            outputData.push(response.data);
    }

    return {
        status: true,
        data: outputData,
    };
}


export function sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function stringToPatch<TType extends string>(oldValue: TType, newValue: TType): TType | undefined {
    const trimmed = newValue.trim() as TType;
    return oldValue === trimmed ? undefined : trimmed;
}

export function optionalStringToPut(string?: string): string | undefined {
    if (string === undefined)
        return undefined;

    return string.trim() || undefined;
}

/**
 * This function is used to create ternary update logic. On FE, the value is either defined or undefined. However, in the update to BE, there are three possibilities:
 * - The value is defined - it should change.
 * - The value is undefined - it should not change.
 * - The value is null - it should change to null, which means `not defined` on FE.
 */
export function optionalStringToPatch<TOutput>(
    oldValue: string | undefined,
    newValue: string | undefined,
    transform?: (input: string) => TOutput,
): TOutput | undefined | null {
    const transformFunction = transform ?? defaultTransform;
    const trimmed = newValue?.trim() || undefined;

    // The old object isn't defined so we return the new object (or undefined, if it's undefined).
    if (oldValue === undefined)
        return trimmed === undefined ? undefined : transformFunction(trimmed);

    // The old object is defined, but the new one isn't which means we have to undefine it on BE.
    if (trimmed === undefined)
        return null;

    // Both objects are defined. If they are equal, no change is required.
    return oldValue === trimmed ? undefined : transformFunction(trimmed);
}

function defaultTransform<Type, TOutput>(input: Type): TOutput {
    return input as unknown as TOutput;
}

// TODO unify this function with the ones above.
export function ifChanged<T, U>(newValue: T, oldValue: U, equals?: (a: T, b: U) => boolean): T | undefined {
    const isEqual = equals ? equals(newValue, oldValue) : (newValue as unknown) === oldValue;
    return isEqual ? undefined : newValue;
}

let lastId = 0;

export function generateId(): number {
    return lastId++;
}

/**
 * Changes the first character of given string to upper case.
 */
export function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function emptyFunction() {
    // This function is intentionally empty.
}

export function normalizeString(input: string): string {
    return input.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
}

function stringToQueryWords(input: string): string[] {
    return normalizeString(input).replace(/[^a-zA-Z0-9]+/gu, ' ').split(' ').filter(word => !!word);
}

/** This function should never fail, whatever the input string is. It should always return a string with length at least one. */
export function getShortcutFromString(input: string): string {
    const split = stringToQueryWords(input);
    return split.length > 1
        ? split[0][0].toUpperCase() + split[1][0].toUpperCase()
        : split.length === 1
            ? split[0].slice(0, 2).toUpperCase()
            // Fallback for whatever reason.
            : '#';
}

export function getNameFromEmail(email: string): string {
    const atIndex = email.indexOf('@');
    return atIndex !== -1 ? email.substring(0, atIndex) : email;
}

/** Comparison of only ascii-like strings. */
export function compareStringsAscii(a: string, b: string): number {
    return a < b ? -1 : (a > b ? 1 : 0);
}

/** Language-independent comparison of rich strings. */
export function compareStringsUniversal(a: string, b: string): number {
    return a.localeCompare(b, 'en');
}

/**
 * Language specific comparison of strings. Use whenever user explicitly wants something alphabetically sorted.
 * Sorts in ascending order (a -> z -> A -> Z).
 */
export function compareStringsLocalized(a: string, b: string, locale: LocaleCode): number {
    return a.localeCompare(b, locale);
}

/**
 * Put `separator` between all elements of `array`.
 * @example
 * intersperse([1, 2, 3], 0)
 *   ==> [ 1, 0, 2, 0, 3 ]
 * intersperse([1, 2, 3].map(n => <span>{n}</span>), ', ')
 *   ==> shows JSX elements (created by mapping an array) separated by a string / another JSX element
 * @see https://gist.github.com/thomasjonas/f99f48e278fd2dfe82edb2c6f7d6c365
 * @see https://stackoverflow.com/questions/23618744/rendering-comma-separated-list-of-links
 */
export function intersperse<Type>(array: Type[], separator: Type): Type[] {
    if (array.length === 0)
        return [];

    return array.slice(1).reduce((ans: Type[], element) => ans.concat([ separator, element ]), [ array[0] ]);
}

export class Query {
    private readonly words;

    constructor(...words: string[]) {
        this.words = words.flatMap(stringToQueryWords).sort(compareStringsUniversal);
    }

    // TODO why not use general needle-in-haystack search? It should be possible to generate the suffix tree or what and then run it on all of the queries (e.g., customers). This way, it should be possible to just join all their strings together (and normalize them).

    /**
     * Returns whether the other Query is included in this Query.
     * This function is not transitive!
     */
    match(other: Query): boolean {
        if (other.words.length === 0)
            return true;

        let j = 0;
        for (const word of this.words) {
            while (other.words[j] <= word) {
                if (word.startsWith(other.words[j]))
                    return true;
                j++;

                if (j >= other.words.length)
                    return false;
            }
        }

        return false;
    }
}

export function isNewTabClick(event: MouseEvent): boolean {
    return event.ctrlKey || event.metaKey || event.button === 1;
}

export function isLeftClick(event: MouseEvent): boolean {
    return event.button === 0;
}

export function navigateNewTab(url: string) {
    window.open(url, '_blank', 'noreferrer');
}

/**
 * This object used as props block multiple event handling of links. Usecase:
 * There is a link inside a clickable element. If the user clicks on the link, he is redirected, but the event is also propagated to the parent element.
 * Even link inside a button with the same redirect onClick handler is problematic, because it will cause double record in history.
 * This object prevents that.
 */
export const stopPropagation = {
    onClick: (e: MouseEvent) => e.stopPropagation(),
    onAuxClick: (e: MouseEvent) => e.stopPropagation(),
};

type Equalable<T> = { equals(other: T): boolean };

export function coalescedEquals<T extends Equalable<T>>(a: T | undefined, b: T | undefined): boolean {
    return a === undefined
        ? b === undefined
        : (b !== undefined && a.equals(b));
}

export type TypedAction<T extends string, P = void> = P & { type: T };

/**
 * Returns true if the color is dark (i.e., the text should be white).
 * @param color Hex code (6 characters).
 */
export function isColorDark(color: string): boolean {
    // Based on https://stackoverflow.com/a/41491220.
    // The threshold was adjusted (from 186 to 142). It looks better this way for most colors.
    // There is also a more complex solution, but this one should be enough for now.
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);

    return r * 0.299 + g * 0.587 + b * 0.114 < 142;
}
