import { GoogleIntegration } from ':frontend/components/integrations/GoogleIntegration';
import { LocationsEditor } from ':frontend/components/settings/LocationsEditor';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function AdvancedSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.advanced' });

    return (
        <div>
            <h1 className='mt-0'>{t('page-title')}</h1>
            <Card className='mt-4 fl-shadow-sm-dark p-3 border-0'>
                <h2 className='mt-0'>{t('locations-section-title')}</h2>
                <p className='fl-description'>{t('locations-section-description')}</p>
                <LocationsEditor />
            </Card>
            <Card className='mt-4 fl-shadow-sm-dark p-3 border-0'>
                <h2 className='mt-0 mb-3'>{t('integrations-section-title')}</h2>
                <GoogleIntegration />
            </Card>
        </div>
    );
}
