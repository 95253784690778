import { useCallback } from 'react';
import { ClientFE, type ClientInfoFE } from ':frontend/types/Client';
import { getEnumValues } from ':utils/common';
import { useToggle, useUpdating } from ':frontend/hooks';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ClientState } from ':utils/entity/client';
import { trpc } from ':frontend/context/TrpcProvider';

type ClientStateBadgeProps = Readonly<{
    state: ClientState;
    className?: string;
}>;

export function ClientStateBadge({ state, className }: ClientStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'client.state' });
    const color = stateColors[state];

    return (
        <span className={clsx(`text-${color} bg-white border border-${color} rounded px-2 py-1`, className)}>{t(state)}</span>
    );
}

const stateColors: Record<ClientState, string> = {
    [ClientState.lead]: 'info',
    [ClientState.active]: 'success',
    [ClientState.inactive]: 'danger',
};

type ClientStateEditProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientFE) => void;
}>;

export function ClientStateEdit({ client, setClient }: ClientStateEditProps) {
    const updateStateMutation = trpc.$client.updateClient.useMutation();

    const syncState = useCallback(async (newValue: ClientState) => {
        if (client.state === newValue)
            return false;

        try {
            const response = await updateStateMutation.mutateAsync({ id: client.id, state: newValue });
            const updatedClient = ClientFE.fromServer(response);
            setClient(updatedClient);
            return true;
        }
        catch {
            return false;
        }
    }, [ client, setClient, updateStateMutation ]);

    const [ state, updateState, isUpdatingState ] = useUpdating(client.state, syncState);
    const [ show, setShow ] = useToggle(false);

    const onOptionClick = useCallback((option: ClientState) => {
        setShow.false();
        updateState(option);
    }, [ setShow, updateState ]);

    return (
        <div>
            <Dropdown autoClose='outside' show={show} onToggle={setShow.toggle} className='fl-dropdown-remove-indicator'>
                <Dropdown.Toggle
                    variant='outline-secondary'
                    onClick={setShow.toggle}
                    className='fl-dropdown-custom-icon compact border-0 p-0 min-h-0'
                    disabled={isUpdatingState}
                >
                    <ClientStateBadge state={state} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-0'>
                    {CLIENT_STATE_VALUES.map(option => (
                        <Dropdown.Item
                            key={option}
                            onClick={() => onOptionClick(option)}
                            className='d-flex py-2'
                        >
                            <ClientStateBadge state={option} />
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export const CLIENT_STATE_VALUES: ClientState[] = getEnumValues(ClientState);
