import { type Result, DataResultSuccess } from ':frontend/types/result';
import { getAresQueryUrl, parseAresInfo, type AresInfo } from ':utils/lib/ares';
import { createApiObject } from './types';

const apiObject = createApiObject();

async function getAresInfo({ cin }: { cin: string }, signal?: AbortSignal): Promise<Result<AresInfo>> {
    const response = await apiObject.GET<unknown>(getAresQueryUrl(cin), {}, { signal });
    if (!response.status)
        return response;

    const parsed = parseAresInfo(response.data, cin);
    return DataResultSuccess(parsed);
}

export const otherApi = {
    getAresInfo,
};
