import { createContext, type ReactNode, useState, useContext, useEffect, useMemo } from 'react';
import { AuthManager, type AuthState, type AuthStateLogin } from ':frontend/utils/AuthManager';
import { api } from ':frontend/utils/api';
import { useAnalytics } from ':frontend/types/analytics';

export type Context = {
    state: AuthState;
    auth: AuthManager;
};

const AuthContext = createContext<(Context) | undefined>(undefined);

export function AuthProvider({ children }: Readonly<{ children: ReactNode }>) {
    const [ state, setState ] = useState<AuthState>({
        isAuthorized: false,
        isFetchingNewToken: true, // TODO this should be loaded from the token from storage ...
    });

    const analytics = useAnalytics();

    const auth = useMemo(() => {
        const newAuth = new AuthManager(api.auth.$object, setState, {
            login: ({ identifier }: AuthStateLogin, isThisTab: boolean) => {
                analytics.start(identifier);

                if (isThisTab)
                    analytics.userLoggedIn();
            },
            logout: (_: unknown, isThisTab) => {
                if (isThisTab)
                    analytics.userLoggedOut();

                analytics.stop();
            },
        }, [ api.backend.authorizer, api.google.authorizer ]);

        api.backend.authorizer.setTokenProvider(() => newAuth.getTokens()?.backend);
        api.google.authorizer.setTokenProvider(() => newAuth.getTokens()?.google);

        return newAuth;
    }, [ analytics ]);

    useEffect(() => {
        auth.onStart();

        return () => auth.cleanup();
    }, [ auth ]);

    const value = useMemo(() => ({ state, auth }), [ state, auth ]);

    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    );
}

export default function useAuth(): Context {
    const context = useContext(AuthContext);
    if (context === undefined)
        throw new Error('useAuth must be used within an AuthProvider');

    return context;
}
