import { Modal, Row, Col } from 'react-bootstrap';
import { SpinnerButton } from ':frontend/components/common';
import { useTranslation } from 'react-i18next';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { type ClientFE } from ':frontend/types/Client';
import { trpc } from ':frontend/context/TrpcProvider';

type DeleteClientModalProps = Readonly<{
    client?: ClientFE;
    onClose(): void;
    onDelete?(deletedClient: ClientFE): void;
}>;

export default function DeleteClientModal({ client, onClose, onDelete }: DeleteClientModalProps) {
    // TODO cache the client

    const { t } = useTranslation('components', { keyPrefix: 'deleteClientModal' });
    const { addAlert } = useNotifications();

    const deleteClientMutation = trpc.$client.deleteClient.useMutation();

    function deleteClient() {
        if (!client)
            return;

        deleteClientMutation.mutate({ id: client.id }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete?.(client);
            },
        });
    }

    return (
        <Modal show={!!client} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-center'>
                    <Col>{t('text')}<br /></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <SpinnerButton
                    variant='danger'
                    isFetching={deleteClientMutation.isPending}
                    onClick={deleteClient}
                    className='mx-auto'
                >
                    {t('confirm-button')}
                </SpinnerButton>
            </Modal.Footer>
        </Modal>
    );
}
