import { type FieldPath, type FieldValues, get, type FieldErrors } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

type FormErrorMessageProps<T extends FieldValues> = {
    errors: FieldErrors<T>;
} & ({
    // Same as names={[ name ]}.
    name: FieldPath<T>;
} | {
    // Show an error for the first field that has the error.
    names: FieldPath<T>[];
});

export default function FormErrorMessage<T extends FieldValues>({ errors, ...data }: FormErrorMessageProps<T>) {
    const message = getFirstErrorMessage(errors, 'name' in data ? [ data.name ] : data.names);
    if (message === undefined)
        return null;

    return <ErrorMessage message={message} />;
}

function getFirstErrorMessage<T extends FieldValues>(errors: FieldErrors<T>, names: FieldPath<T>[]): string | undefined {
    for (const name of names) {
        const error = get(errors, name);
        if (error) {
            const message = error.message || error.root?.message;
            if (message)
                return message;
        }
    }

    return undefined;
}
