import { Modal } from 'react-bootstrap';
import { type BaseLocationFE, locationFromServer } from ':frontend/types/location';
import LocationForm, { type LocationFormData } from './LocationForm';
import { useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';
import { locationFormToUpsert } from './NewLocationModal';

type EditLocationModalProps = Readonly<{
    location?: BaseLocationFE;
    onClose(): void;
    onLocationEdit(updatedLocation: BaseLocationFE): void;
}>;

export default function EditLocationModal({ location, onClose, onLocationEdit }: EditLocationModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations' });

    const updateLocationMutation = trpc.location.updateLocation.useMutation();

    function onSubmit(data: LocationFormData) {
        if (!location)
            return;

        const upsert = locationFormToUpsert(data);
        updateLocationMutation.mutate({ ...upsert, id: location.id }, {
            onError: () => {
                // TODO Do something.
            },
            onSuccess: response => {
                onLocationEdit(locationFromServer(response));
                onClose();
            },
        });
    }

    return (
        <Modal show={!!location} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('edit-location-modal-title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LocationForm
                    defaultValues={location ?? {}}
                    isFetching={updateLocationMutation.isPending}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    typeIsConstant
                />
            </Modal.Body>
        </Modal>
    );
}
