import { useReducer, type Dispatch } from 'react';
import { type MasterContext, useMaster } from ':frontend/context/UserProvider';
import { type TypedAction } from ':frontend/utils/common';
import type { RequiredBy } from ':utils/common';
import { type RulesDefinition, Updator, Validator, type FormErrors, type FormPath } from ':frontend/utils/updator';
import { type ProductFE } from ':frontend/types/Product';
import { type Participant } from ':frontend/types/EventParticipant';
import { type ProductItemInit } from ':frontend/types/orders/Order';
import { type CheckoutInput, CheckoutType } from './checkout/useCheckout';
import { type FormDiscount, formDiscountToDiscount } from './CustomOrderForm';
import { type TeamMemberFE } from ':frontend/types/Team';

export function useNewProductOrder() {
    const masterContext = useMaster();
    const [ state, dispatch ] = useReducer(orderReducer, masterContext, computeInitialState);

    return {
        state,
        dispatch,
    };
}

export type UseNewProductOrderState = {
    masterContext: MasterContext;
    isModalOpen: boolean;
    form: ProductOrderFormState;
    formErrors?: FormErrors;
    wasSubmitted?: boolean;
    checkout?: CheckoutInput;
};

type ProductOrderFormState = {
    guest: Participant | undefined;
    client: Participant | undefined;
    isLinked: boolean;
    scheduler: TeamMemberFE | undefined;
    items: ProductItemInit[];
    discount?: FormDiscount;
};

function computeInitialState(masterContext: MasterContext): UseNewProductOrderState {
    return {
        masterContext,
        isModalOpen: false,
        form: {
            guest: undefined,
            client: undefined,
            isLinked: true,
            scheduler: undefined,
            items: [],
        },
    };
}

export type UseNewProductOrderDispatch = Dispatch<NewProductOrderAction>;

type NewProductOrderAction = FormAction | InputAction | {
    type: 'sellProduct';
    product: ProductFE;
} | {
    type: 'closeModal';
};

function orderReducer(state: UseNewProductOrderState, action: NewProductOrderAction): UseNewProductOrderState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'form': return form(state, action);
    case 'input': return input(state, action);
    case 'sellProduct':
        return {
            masterContext: state.masterContext,
            isModalOpen: true,
            form: {
                guest: undefined,
                client: undefined,
                isLinked: true,
                scheduler: undefined,
                items: [ { product: action.product } ],
            },
        };
    case 'closeModal':
        return { ...state, isModalOpen: false };
    }
}

// Form

type FormAction = TypedAction<'form', {
    operation: 'checkoutStart' | 'checkoutBack';
} | {
    operation: 'newItem';
    product: ProductFE;
} | {
    operation: 'remove';
    index: number;
}>;

function form(state: UseNewProductOrderState, action: FormAction): UseNewProductOrderState {
    if (action.operation === 'checkoutStart') {
        const formErrors = Validator.validate(state.form, rules);
        if (formErrors)
            return { ...state, formErrors, wasSubmitted: true };

        // Validated by the form
        const guest = state.form.guest!;
        const client = state.form.isLinked ? guest : state.form.client!;
        const scheduler = state.form.scheduler;
        const items = state.form.items;
        const discount = formDiscountToDiscount(state.form.discount);

        return { ...state, checkout: { type: CheckoutType.Product, guest, client, items, discount, scheduler } };
    }

    if (action.operation === 'checkoutBack')
        return { ...state, checkout: undefined };

    if (action.operation === 'remove') {
        const newItems = state.form.items.toSpliced(action.index, 1);
        return { ...state, form: { ...state.form, items: newItems } };
    }

    if (action.operation === 'newItem') {
        const newItems = [ ...state.form.items, { product: action.product } ];
        return { ...state, form: { ...state.form, items: newItems } };
    }

    throw new Error(`Unknown operation: ${action.operation}`);
}

// Input

type InputAction = TypedAction<'input', {
    field: FormPath<ProductOrderFormState>;
    value: unknown;
}>;

function input(state: UseNewProductOrderState, action: InputAction): UseNewProductOrderState {
    const { form, formErrors } = Updator.update(state as RequiredBy<UseNewProductOrderState, 'form'>, action.field, action.value, state.wasSubmitted ? rules : undefined);

    return { ...state, form, formErrors };
}

const rules: RulesDefinition<ProductOrderFormState> = {
    guest: (value: unknown) => value !== undefined || 'components:productOrderForm.guest-required',
    client: (value: unknown, form: ProductOrderFormState) => form.isLinked || value !== undefined || 'components:productOrderForm.client-required',
};
