import React from 'react';
import clsx from 'clsx';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import DateContentRow from './DateContentRow';
import Header from './Header';
import { localizer } from '.';
import { rightToLeft } from './utils/common';
import { getNow } from './localizer';

class TimeGridHeader extends React.Component {
    renderHeaderCells(range) {
        const today = getNow();

        return range.map((date, i) => {
            return (
                <div
                    key={i}
                    className={clsx(
                        'rbc-header',
                        localizer.isSameDate(date, today) && 'rbc-today',
                    )}
                >
                    <Header date={date} type='week' />
                </div>
            );
        });
    }

    render() {
        let {
            width,
            range,
            events,
            selectable,
            scrollRef,
            isOverflowing,
        } = this.props;

        let style = {};
        if (isOverflowing)
            style[rightToLeft ? 'marginLeft' : 'marginRight'] = `${scrollbarSize() - 1}px`;

        return (
            <div
                style={style}
                ref={scrollRef}
                className={clsx('rbc-time-header', isOverflowing && 'rbc-overflowing')}
            >
                <div
                    className='rbc-label rbc-time-header-gutter'
                    style={{ width, minWidth: width, maxWidth: width }}
                />
                <div className='rbc-time-header-content'>
                    <div
                        className={clsx('rbc-row rbc-time-header-cell', range.length <= 1 && 'rbc-time-header-cell-single-day')}
                    >
                        {this.renderHeaderCells(range)}
                    </div>
                    <DateContentRow
                        isAllDay
                        minRows={0}
                        range={range}
                        events={events}
                        className='rbc-allday-cell'
                        selectable={selectable}
                        selected={this.props.selected}
                        onSelect={this.props.onSelectEvent}
                        onSelectSlot={this.props.onSelectSlot}
                    />
                </div>
            </div>
        );
    }
}

export default TimeGridHeader;
