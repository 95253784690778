import { zFileType, type FileUpsert } from ':utils/entity/file';

/** There is no difference between 'no file' and 'remove file' in the input. We have to handle this in the form. */
export async function fileToServer(input: FileList | null | undefined): Promise<FileUpsert | undefined> {
    if (input && input.length !== 0) {
        const file = input.item(0)!;
        const type = zFileType.safeParse(file.type);
        // TODO handle this in the form ...
        if (!type.success)
            throw new Error('invalid-file-type');

        const base64 = await fileToBase64(file);
        return {
            data: base64,
            name: file.name,
            type: type.data,
        };
    }

    return undefined;
}

/**
 * Transform a File object as returned by FileList[0] as returned by <input type="file" />
 * Plain base64 without any data: URL stuff, without mimetype.
 * Padded with = to the correct length.
 * @see https://stackoverflow.com/a/57272491
 */
function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (!reader.result) {
                reject(null);
                return;
            }

            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0)
                encoded += '='.repeat(4 - (encoded.length % 4));
            resolve(encoded);
        };

        reader.onerror = error => reject(error);

        reader.readAsDataURL(file);
    });
}
