import { optionalStringToPut } from ':frontend/utils/common';
import { type Id } from ':utils/id';
import type { CountryCode } from ':utils/i18n';
import type { AddressOutput, AddressUpsert } from ':utils/entity/address';

export class AddressFE {
    private constructor(
        readonly id: Id,
        readonly city?: string,
        readonly country?: CountryCode,
        readonly line1?: string,
        readonly line2?: string,
        readonly postalCode?: string,
        readonly state?: string,
    ) {}

    static fromServer(input: AddressOutput): AddressFE {
        return new AddressFE(
            input.id,
            input.city,
            input.country,
            input.line1,
            input.line2,
            input.postalCode,
            input.state,
        );
    }

    toEditable(): EditableAddress {
        return {
            city: this.city ?? '',
            country: this.country ?? '',
            line1: this.line1 ?? '',
            line2: this.line2 ?? '',
            postalCode: this.postalCode ?? '',
            state:this.state ??  '',
        };
    }
}

export type EditableAddress = Required<AddressUpsert>;

export const defaultEditableAddress: EditableAddress = {
    city: '',
    country: '',
    line1: '',
    line2: '',
    postalCode: '',
    state: '',
};

export function addressToUpsert(input: EditableAddress): AddressUpsert {
    return {
        city: optionalStringToPut(input.city),
        country: optionalStringToPut(input.country),
        line1: optionalStringToPut(input.line1),
        line2: optionalStringToPut(input.line2),
        postalCode: optionalStringToPut(input.postalCode),
        state: optionalStringToPut(input.state),
    };
}

export function isAddressEqual(form: EditableAddress, address: AddressFE | EditableAddress): boolean {
    return form.city === (address.city ?? '')
        && form.country === (address.country ?? '')
        && form.line1 === (address.line1 ?? '')
        && form.line2 === (address.line2 ?? '')
        && form.postalCode === (address.postalCode ?? '')
        && form.state === (address.state ?? '');
}
