import { z } from 'zod';

export type AresInfo = {
    legalName?: string;
    cin: string;
    tin?: string;
    address?: {
        city?: string;
        country?: string;
        line1?: string;
        postalCode?: string;
    };
};

const zAresInfoRaw = z.object({
    obchodniJmeno: z.string().optional(),
    dic: z.string().optional(),
    sidlo: z.object({
        kodStatu: z.string().optional(),
        nazevObce: z.string().optional(),
        psc: z.coerce.string().optional(),
        cisloOrientacni: z.coerce.string().optional(),
        cisloDomovni: z.coerce.string().optional(),
        nazeUlice: z.string().optional(),
        textovaAdresa: z.string().optional(),
    }).optional(),
});

export function parseAresInfo(data: unknown, cin: string): AresInfo {
    const { obchodniJmeno, dic, sidlo } = zAresInfoRaw.parse(data);

    const line1 = (sidlo?.cisloOrientacni && sidlo.cisloOrientacni && sidlo.nazeUlice)
        ? `${sidlo.nazeUlice} ${sidlo.cisloDomovni} ${sidlo.cisloOrientacni}`
        : sidlo?.textovaAdresa?.split(', ', 1)[0];

    const address = sidlo ? {
        country: sidlo.kodStatu,
        city: sidlo.nazevObce,
        postalCode: sidlo.psc,
        line1,
    } : undefined;

    return {
        legalName: obchodniJmeno,
        cin,
        tin: dic,
        address,
    };
}

const ARES_BASE_URL = 'https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/ekonomicke-subjekty/';
const CIN_LENGTH = 8;

export function getAresQueryUrl(cin: string): string {
    return `${ARES_BASE_URL}${cin.padStart(CIN_LENGTH, '0')}`;
}
