import type { DateTime } from 'luxon';
import type { Id } from ':utils/id';
import type { TaskOutput } from ':utils/entity/task';

export class TaskFE {
    private constructor(
        readonly id: Id,
        readonly text: string,
        readonly createdAt: DateTime,
        readonly isCompleted: boolean,
    ) {}

    static fromServer(input: TaskOutput): TaskFE {
        return new TaskFE(
            input.id,
            input.text,
            input.createdAt,
            input.completed,
        );
    }
}

export type TaskEditToServer = {
    text?: string;
    completed?: boolean;
};

export type TaskInitToServer = {
    text: string;
};
